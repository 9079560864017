import {Component, OnInit, QueryList, Renderer2, ElementRef, AfterViewInit, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
//import {Location} from '@angular/common';
import { NotificationService } from "src/app/core/services/notification.service";
//import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../dashboard.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-add-employee-wizard',
  templateUrl: './add-employee-wizard.component.html',
  styleUrls: ['./add-employee-wizard.component.scss']
})
export class AddEmployeeWizardComponent implements OnInit, AfterViewInit {
  public personalDetailFromGroup: FormGroup;
  public jobFromGroup: FormGroup;
  public salaryFromGroup: FormGroup;
  public contactDetailsFromGroup: FormGroup;
  public socialMediaDetailsFromGroup: FormGroup;
  public emergencyContactsFromGroup: FormGroup;
  public dependentsFromGroup: FormGroup;
  public immigrationFromGroup: FormGroup;
  public supervisorFromGroup: FormGroup;
  public subOrdinateFromGroup: FormGroup;
  public workExperienceFromGroup: FormGroup;
  public educationFromGroup: FormGroup;
  public skillsFromGroup: FormGroup;
  public languagesFromGroup: FormGroup;
  public licenseFromGroup: FormGroup;
  public membershipsFromGroup: FormGroup;
  public directDepositFromGroup: FormGroup;
  public configModel : FormGroup;
  public personalSelected : boolean = true ;
  public jobSelected : boolean = true ;
  public salarySelected : boolean = true ;
  public contactSelected : boolean = false ;
  public socialSelected : boolean = true ;
  public emergencySelected : boolean = true ;
  public dependentSelected : boolean = true ;
  public immigrationSelected : boolean = true ;
  public superVisiorSelected : boolean = true ;
  public subordinateSelected : boolean = true ;
  public workExperinceSelected : boolean = true ;
  public educationSelected : boolean = true ;
  public skillsSelected : boolean = false ;
  public languageSelected : boolean = false ;
  public licensesSelected : boolean = false ;
  public directSelected : boolean = true ;
  public memberShipSelected : boolean = false ;

  public generalState : boolean = false ;
  public isLodaingRequired : boolean = true ;
  @ViewChildren('bodyContent') bodyContent: QueryList<ElementRef>;

  constructor(private fb: FormBuilder,
              private authService : AuthService,
              private toastr: NotificationService,
              private dashboardService: DashboardService,
              private renderer: Renderer2) {
    this.createForm();

  }

  setPersonalSelected()
  {
    this.personalSelected = true;
  }
  setJobSelected()
  {
    this.jobSelected = true;
  }
  setSalarySelected()
  {
    this.salarySelected = true;
  }

  public permissions : any;
  public read = false;
  public write = false ;
  public update = false;
  public delete = false;
 

  checkPermission(module:any,tabs:any) : void 
  {
      this.permissions = JSON.parse(this.authService.getPermissions());

      this.read = false ;
      this.write = false ;
      this.delete = false ;
      this.update = false;

      if(this.permissions != undefined && this.permissions != null )
      {
          for(let p of this.permissions)
          {
             
              if(p.id === module)
              {
                 
                  if(p.modules != undefined)
                  {
                    
                      for(let tab of p.modules)
                      {
                          if(tab.id === tabs)
                          {
                            
                              for(let move of tab.permits)
                              {
                                  if(move.id === "u" || move.id === "c")
                                  {
                                      this.update = true;
                                     
                                  }
                                  else if(move.id === "r")
                                  {
                                      this.read = true;
                                  }
                                 
                                  else if(move.id === "d")
                                  {
                                      this.delete = true;
                                  }
                              }
                          }
                      }
                  }
                 
              }
             
          }
      }
     

  }
  ngOnInit() {
    this.checkPermission("pdm","pd_cf");
    this.isLodaingRequired = true;
    this.dashboardService.getEnabledRequiredFields().subscribe(
      resp => {
         
          if (resp && resp.data) {
            this.generalState =  resp.data;
          }
         
      }, error => {
        this.isLodaingRequired = false;
          const err = error.error && error.error.msg;
          this.toastr.error(err || 'Error in getting Config');
      }
  );


  
  this.dashboardService.getRequiredFields().subscribe(
    resp => {
       
        if (resp && resp.data) {

          this.workExperienceFromGroup.patchValue(resp.data.workExperienceFromGroup);
          this.skillsFromGroup.patchValue(resp.data.skillsFromGroup);
          this.personalDetailFromGroup.patchValue(resp.data.personalDetailFromGroup);
          this.membershipsFromGroup.patchValue(resp.data.membershipsFromGroup);
          this.licenseFromGroup.patchValue(resp.data.licenseFromGroup);
          this.languagesFromGroup.patchValue(resp.data.languagesFromGroup);
          this.jobFromGroup.patchValue(resp.data.jobFromGroup);
          this.immigrationFromGroup.patchValue(resp.data.immigrationFromGroup);
          this.educationFromGroup.patchValue(resp.data.educationFromGroup);
          this.directDepositFromGroup.patchValue(resp.data.directDepositFromGroup);
          this.contactDetailsFromGroup.patchValue(resp.data.contactDetailsFromGroup);
          this.skillsSelected =  this.skillsFromGroup.value.selected;
          this.memberShipSelected =  this.membershipsFromGroup.value.selected;
          this.licensesSelected =  this.licenseFromGroup.value.selected;
          this.languageSelected = this.languagesFromGroup.value.selected;
          this.immigrationSelected =  this.immigrationFromGroup.value.selected;
          this.contactSelected =  this.contactDetailsFromGroup.value.selected;
          this.isLodaingRequired = false;
        }
       
    }, error => {
      this.isLodaingRequired = false;
        const err = error.error && error.error.msg;
        this.toastr.error(err);
    }
);



  }

  saveState() : void 
  {

    
    this.dashboardService.saveEnabledRequiredFields(this.generalState).subscribe(
      resp => {
         
        
            this.toastr.success('State changed successfully');
            this.dashboardService.getRequiredFields().subscribe(
              resp => {
                 
                  if (resp && resp.data) {
          
                    this.workExperienceFromGroup.patchValue(resp.data.workExperienceFromGroup);
                    this.skillsFromGroup.patchValue(resp.data.skillsFromGroup);
                    this.personalDetailFromGroup.patchValue(resp.data.personalDetailFromGroup);
                    this.membershipsFromGroup.patchValue(resp.data.membershipsFromGroup);
                    this.licenseFromGroup.patchValue(resp.data.licenseFromGroup);
                    this.languagesFromGroup.patchValue(resp.data.languagesFromGroup);
                    this.jobFromGroup.patchValue(resp.data.jobFromGroup);
                    this.immigrationFromGroup.patchValue(resp.data.immigrationFromGroup);
                    this.educationFromGroup.patchValue(resp.data.educationFromGroup);
                    this.directDepositFromGroup.patchValue(resp.data.directDepositFromGroup);
                    this.contactDetailsFromGroup.patchValue(resp.data.contactDetailsFromGroup);
                    this.skillsSelected =  this.skillsFromGroup.value.selected;
                    this.memberShipSelected =  this.membershipsFromGroup.value.selected;
                    this.licensesSelected =  this.licenseFromGroup.value.selected;
                    this.languageSelected = this.languagesFromGroup.value.selected;
                    this.immigrationSelected =  this.immigrationFromGroup.value.selected;
                    this.contactSelected =  this.contactDetailsFromGroup.value.selected;
                    this.isLodaingRequired = false;
                  }
                 
              }, error => {
                this.isLodaingRequired = false;
                  const err = error.error && error.error.msg;
                  this.toastr.error(err);
              }
          );
          
         
      }, error => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || 'Error in changing state');
      }
  );

  }
  ngAfterViewInit() {
    this.bodyContent.forEach(item => 
      this.renderer.removeClass(item.nativeElement.parentElement, 'mat-expansion-panel-body'));
    document.querySelectorAll('.mat-expansion-indicator').forEach(element => {
      element.addEventListener('mouseover', event => {
        const target = event.target as HTMLElement;
        const parent = target.parentElement;
        if(parent.classList.contains('mat-expanded')){
          parent.querySelector('.btn-collapse').classList.remove('hide');
          parent.querySelector('.btn-collapse').classList.add('display');
          parent.querySelector('.btn-expand').classList.remove('display');
          parent.querySelector('.btn-expand').classList.add('hide');
        }else{
          parent.querySelector('.btn-expand').classList.remove('hide');
          parent.querySelector('.btn-expand').classList.add('display');
          parent.querySelector('.btn-collapse').classList.remove('display');
          parent.querySelector('.btn-collapse').classList.add('hide');
        }
      });
      element.addEventListener('mouseleave', event => {
        const target = event.target as HTMLElement;
        const parent = target.parentElement;
        parent.querySelectorAll('button').forEach(element => element.classList.remove('display'));
        parent.querySelectorAll('button').forEach(element => element.classList.add('hide'));
      })
    });
  }


  checkValidaty()
  {
   
    
    if(this.personalDetailFromGroup.value.firstNameRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        firstNameAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }

    if(this.personalDetailFromGroup.value.lastNameRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        lastNameAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.middleNameRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        middleNameAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.dateOfBirthRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        dateOfBirthAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.martialStatusRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        martialStatusAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.genderRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        genderAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.nationalityRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        nationalityAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.driverLicenseNumberRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        driverLicenseNumberAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.licenseExpiryDateRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        licenseExpiryDateAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
 
    if(this.personalDetailFromGroup.value.nickNameRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        nickNameAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.smokerRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        smokerAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(this.personalDetailFromGroup.value.militaryServiceRequired)
    {
      

      this.personalDetailFromGroup.patchValue({
        militaryServiceAvailable: true, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
  }

  checkValidaty2()
  {




    if(!this.personalDetailFromGroup.value.firstNameAvailable)
    {
      this.personalDetailFromGroup.patchValue({
        firstNameRequired: false, 
        // formControlName2: myValue2 (can be omitted)
      });
    }

    if(!this.personalDetailFromGroup.value.lastNameAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        lastNameRequired: false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.middleNameAvailable )
    {
      

      this.personalDetailFromGroup.patchValue({
        middleNameRequired: false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.dateOfBirthAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        dateOfBirthRequired: false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.martialStatusAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        martialStatusRequired : false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.genderAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        genderRequired : false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.nationalityAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        nationalityRequired : false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.driverLicenseNumberAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        driverLicenseNumberRequired : false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.licenseExpiryDateAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        licenseExpiryDateRequired : false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }

    if(!this.personalDetailFromGroup.value.nickNameAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        nickNameRequired : false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.smokerAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        smokerRequired : false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }
    if(!this.personalDetailFromGroup.value.militaryServiceAvailable)
    {
      

      this.personalDetailFromGroup.patchValue({
        militaryServiceRequired : false, 
        // formControlName2: myValue2 (can be omitted)
      });

    }

  }

  checkValidatyJob()
  {
   
    if(this.jobFromGroup.value.joinedDateRequired)
    {
      this.jobFromGroup.patchValue({
        joinedDateAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.probationEndDateRequired)
    {
      this.jobFromGroup.patchValue({
        probationEndDateAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.jobTitleRequired)
    {
      this.jobFromGroup.patchValue({
        jobTitleAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.employmentStatusRequired)
    {
      this.jobFromGroup.patchValue({
        employmentStatusAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.jobCategoryRequired)
    {
      this.jobFromGroup.patchValue({
        jobCategoryAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.subUnitRequired)
    {
      this.jobFromGroup.patchValue({
        subUnitAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.workShiftRequired)
    {
      this.jobFromGroup.patchValue({
        workShiftAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.contractStartDateRequired)
    {
      this.jobFromGroup.patchValue({
        contractStartDateAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.commentRequired)
    {
      this.jobFromGroup.patchValue({
        commentAvailable: true, 
      });

    }
    if(this.jobFromGroup.value.effectiveFromRequired)
    {
      this.jobFromGroup.patchValue({
        effectiveFromAvailable: true, 
      });

    }

    if(this.jobFromGroup.value.contractEndDateRequired)
    {
      this.jobFromGroup.patchValue({
        contractEndDateAvailable: true, 
      });

    }

    if(this.jobFromGroup.value.costCenterRequired)
    {
      this.jobFromGroup.patchValue({
        costCenterAvailable: true, 
      });

    }
  
  
  }

  checkValidatyJob2()
  {
   
    if(!this.jobFromGroup.value.joinedDateAvailable)
    {
      this.jobFromGroup.patchValue({
        joinedDateRequired : false, 
      });

    }
    if(!this.jobFromGroup.value.probationEndDateAvailable)
    {
      this.jobFromGroup.patchValue({
        probationEndDateRequired : false, 
      });

    }
    if(!this.jobFromGroup.value.jobTitleAvailable)
    {
      this.jobFromGroup.patchValue({
        jobTitleRequired : false, 
      });

    }
    if(!this.jobFromGroup.value.employmentStatusAvailable)
    {
      this.jobFromGroup.patchValue({
        employmentStatusRequired : false, 
      });

    }
    if(!this.jobFromGroup.value.jobCategoryAvailable)
    {
      this.jobFromGroup.patchValue({
        jobCategoryRequired: false, 
      });

    }
    if(!this.jobFromGroup.value.subUnitAvailable)
    {
      this.jobFromGroup.patchValue({
        subUnitRequired : false, 
      });

    }
    if(!this.jobFromGroup.value.workShiftAvailable)
    {
      this.jobFromGroup.patchValue({
        workShiftRequired : false, 
      });

    }
    if(!this.jobFromGroup.value.contractStartDateAvailable)
    {
      this.jobFromGroup.patchValue({
        contractStartDateRequired : false, 
      });

    }
    if(!this.jobFromGroup.value.commentAvailable)
    {
      this.jobFromGroup.patchValue({
         commentRequired: false, 
      });

    }
    if(!this.jobFromGroup.value.effectiveFromAvailable)
    {
      this.jobFromGroup.patchValue({
        effectiveFromRequired : false, 
      });

    }

    if(!this.jobFromGroup.value.contractEndDateAvailable)
    {
      this.jobFromGroup.patchValue({
        contractEndDateRequired : false, 
      });

    }

    if(!this.jobFromGroup.value.costCenterAvailable)
    {
      this.jobFromGroup.patchValue({
        costCenterRequired : false, 
      });

    }
  
  
  }

  checkContact()
  {
    
    if(this.contactDetailsFromGroup.value.landMarkRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        landMarkAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.addressStreet1Required)
    {
      this.contactDetailsFromGroup.patchValue({
        addressStreet1Available: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.addressStreet2Required)
    {
      this.contactDetailsFromGroup.patchValue({
        addressStreet2Available: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.cityRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        cityAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.countryRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        countryAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.postalCodeRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        postalCodeAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.homeTelephoneRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        homeTelephoneAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.mobileRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        mobileAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.workTelephoneRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        workTelephoneAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.stateRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        stateAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.workEmailRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        workEmailAvailable: true, 
      });

    }
    if(this.contactDetailsFromGroup.value.otherEmailRequired)
    {
      this.contactDetailsFromGroup.patchValue({
        otherEmailAvailabl: true, 
      });

    }
    this.checkAllContact();
    this.unCheckAllContact();
  }

  checkAllContact()
  {
    if(this.contactDetailsFromGroup.value.landMarkAvailable 
      || this.contactDetailsFromGroup.value.landMarkRequired
      ||this.contactDetailsFromGroup.value.addressStreet1Required
      ||this.contactDetailsFromGroup.value.addressStreet1Available
      ||this.contactDetailsFromGroup.value.addressStreet2Required
      ||this.contactDetailsFromGroup.value.addressStreet2Available
      ||this.contactDetailsFromGroup.value.cityRequired
      ||this.contactDetailsFromGroup.value.cityAvailable
      ||this.contactDetailsFromGroup.value.countryRequired
      ||this.contactDetailsFromGroup.value.countryAvailable
      ||this.contactDetailsFromGroup.value.postalCodeRequired
      ||this.contactDetailsFromGroup.value.postalCodeAvailable
      ||this.contactDetailsFromGroup.value.homeTelephoneRequired
      ||this.contactDetailsFromGroup.value.homeTelephoneAvailable
      ||this.contactDetailsFromGroup.value.mobileRequired
      ||this.contactDetailsFromGroup.value.mobileAvailable
      ||this.contactDetailsFromGroup.value.workTelephoneRequired
      ||this.contactDetailsFromGroup.value.workTelephoneAvailable
      ||this.contactDetailsFromGroup.value.stateRequired
      ||this.contactDetailsFromGroup.value.stateAvailable
      ||this.contactDetailsFromGroup.value.workEmailRequired
      ||this.contactDetailsFromGroup.value.workEmailAvailable
      ||this.contactDetailsFromGroup.value.otherEmailRequired
      ||this.contactDetailsFromGroup.value.otherEmailAvailabl)
    {
      this.contactSelected = true;
      this.contactDetailsFromGroup.value.selected = this.contactSelected;
    }
  }
  unCheckAllContact()
  {
    if(!this.contactDetailsFromGroup.value.landMarkAvailable 
      && !this.contactDetailsFromGroup.value.landMarkRequired
      && !this.contactDetailsFromGroup.value.addressStreet1Required
      && !this.contactDetailsFromGroup.value.addressStreet1Available
      && !this.contactDetailsFromGroup.value.addressStreet2Required
      && !this.contactDetailsFromGroup.value.addressStreet2Available
      && !this.contactDetailsFromGroup.value.cityRequired
      && !this.contactDetailsFromGroup.value.cityAvailable
      && !this.contactDetailsFromGroup.value.countryRequired
      && !this.contactDetailsFromGroup.value.countryAvailable
      && !this.contactDetailsFromGroup.value.postalCodeRequired
      && !this.contactDetailsFromGroup.value.postalCodeAvailable
      && !this.contactDetailsFromGroup.value.homeTelephoneRequired
      && !this.contactDetailsFromGroup.value.homeTelephoneAvailable
      && !this.contactDetailsFromGroup.value.mobileRequired
      && !this.contactDetailsFromGroup.value.mobileAvailable
      && !this.contactDetailsFromGroup.value.workTelephoneRequired
      && !this.contactDetailsFromGroup.value.workTelephoneAvailable
      && !this.contactDetailsFromGroup.value.stateRequired
      && !this.contactDetailsFromGroup.value.stateAvailable
      && !this.contactDetailsFromGroup.value.workEmailRequired
      && !this.contactDetailsFromGroup.value.workEmailAvailable
      && !this.contactDetailsFromGroup.value.otherEmailRequired
      && !this.contactDetailsFromGroup.value.otherEmailAvailabl)
    {
      this.contactSelected = false;
      this.contactDetailsFromGroup.value.selected = this.contactSelected;
    }
  }
  checkContact2()
  {
  

    if(!this.contactDetailsFromGroup.value.landMarkAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        landMarkRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value. addressStreet1Available)
    {
      this.contactDetailsFromGroup.patchValue({
        addressStreet1Required : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.addressStreet2Available)
    {
      this.contactDetailsFromGroup.patchValue({
        addressStreet2Required : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.cityAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        cityRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.countryAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        countryRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.postalCodeAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        postalCodeRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.homeTelephoneAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        homeTelephoneRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.mobileAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        mobileRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.workTelephoneAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        workTelephoneRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.stateAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        stateRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.workEmailAvailable)
    {
      this.contactDetailsFromGroup.patchValue({
        workEmailRequired : false, 
      });

    }
    if(!this.contactDetailsFromGroup.value.otherEmailAvailabl)
    {
      this.contactDetailsFromGroup.patchValue({
        otherEmailRequired : false, 
      });

    }
    this.checkAllContact();
    this.unCheckAllContact();
   
  }


  checkAllImmig()
  {
    if(this.immigrationFromGroup.value.commentRequired 
      || this.immigrationFromGroup.value.commentAvailable
      ||this.immigrationFromGroup.value.documentRequired
      ||this.immigrationFromGroup.value.documentAvailable
      ||this.immigrationFromGroup.value.numberRequired
      ||this.immigrationFromGroup.value.numberAvailable
      ||this.immigrationFromGroup.value.eligibleStatusAvailable
      ||this.immigrationFromGroup.value.eligibleStatusRequired
      ||this.immigrationFromGroup.value.issuedByRequired
      ||this.immigrationFromGroup.value.issuedByAvailable
      ||this.immigrationFromGroup.value.issuedDateRequired
      ||this.immigrationFromGroup.value.issuedDateAvailable
      ||this.immigrationFromGroup.value.expiryDateAvailable
      ||this.immigrationFromGroup.value.expiryDateRequired
      ||this.immigrationFromGroup.value.eligibleReviewDateRequired
      ||this.immigrationFromGroup.value.eligibleReviewDateAvailable
     )
    {
      this.immigrationSelected = true;
      this.immigrationFromGroup.value.selected = this.immigrationSelected;
    }
  }

  unCheckAllImmig()
  {
    if(!this.immigrationFromGroup.value.commentRequired 
      && !this.immigrationFromGroup.value.commentAvailable
      && !this.immigrationFromGroup.value.documentRequired
      && !this.immigrationFromGroup.value.documentAvailable
      && !this.immigrationFromGroup.value.numberRequired
      && !this.immigrationFromGroup.value.numberAvailable
      && !this.immigrationFromGroup.value.eligibleStatusAvailable
      && !this.immigrationFromGroup.value.eligibleStatusRequired
      && !this.immigrationFromGroup.value.issuedByRequired
      && !this.immigrationFromGroup.value.issuedByAvailable
      && !this.immigrationFromGroup.value.issuedDateRequired
      && !this.immigrationFromGroup.value.issuedDateAvailable
      && !this.immigrationFromGroup.value.expiryDateAvailable
      && !this.immigrationFromGroup.value.expiryDateRequired
      && !this.immigrationFromGroup.value.eligibleReviewDateRequired
      && !this.immigrationFromGroup.value.eligibleReviewDateAvailable
     )
    {
      this.immigrationSelected = false;
      this.immigrationFromGroup.value.selected = this.immigrationSelected;
    }
  }
  checkImmigration()
  {
    if(this.immigrationFromGroup.value.commentRequired)
    {
      this.immigrationFromGroup.patchValue({
        commentAvailable : true, 
      });

    }
    if(this.immigrationFromGroup.value.documentRequired)
    {
      this.immigrationFromGroup.patchValue({
        documentAvailable : true, 
      });

    }
    if(this.immigrationFromGroup.value.numberRequired)
    {
      this.immigrationFromGroup.patchValue({
        numberAvailable : true, 
      });

    }
    if(this.immigrationFromGroup.value.eligibleStatusRequired)
    {
      this.immigrationFromGroup.patchValue({
        eligibleStatusAvailable : true, 
      });

    }
    if(this.immigrationFromGroup.value.issuedByRequired)
    {
      this.immigrationFromGroup.patchValue({
        issuedByAvailable : true, 
      });

    }
    if(this.immigrationFromGroup.value.issuedDateRequired)
    {
      this.immigrationFromGroup.patchValue({
        issuedDateAvailable : true, 
      });

    }

    if(this.immigrationFromGroup.value.expiryDateRequired)
    {
      this.immigrationFromGroup.patchValue({
        expiryDateAvailable : true, 
      });

    }
    if(this.immigrationFromGroup.value.eligibleReviewDateRequired)
    {
      this.immigrationFromGroup.patchValue({
        eligibleReviewDateAvailable : true, 
      });

    }

    this.checkAllImmig();
    this.unCheckAllImmig();
  }


  checkImmigration2()
  {
    if(!this.immigrationFromGroup.value.commentAvailable)
    {
      this.immigrationFromGroup.patchValue({
        commentRequired  : false, 
      });

    }
    if(!this.immigrationFromGroup.value.documentAvailable)
    {
      this.immigrationFromGroup.patchValue({
        documentRequired  : false, 
      });

    }
    if(!this.immigrationFromGroup.value.numberAvailable)
    {
      this.immigrationFromGroup.patchValue({
        numberRequired  : false, 
      });

    }
    if(!this.immigrationFromGroup.value.eligibleStatusAvailable)
    {
      this.immigrationFromGroup.patchValue({
        eligibleStatusRequired  : false, 
      });

    }
    if(!this.immigrationFromGroup.value.issuedByAvailable)
    {
      this.immigrationFromGroup.patchValue({
        issuedByRequired  : false, 
      });

    }
    if(!this.immigrationFromGroup.value.issuedDateAvailable)
    {
      this.immigrationFromGroup.patchValue({
        issuedDateRequired  : false, 
      });

    }

    if(!this.immigrationFromGroup.value.expiryDateAvailable)
    {
      this.immigrationFromGroup.patchValue({
        expiryDateRequired  : false, 
      });

    }
    if(!this.immigrationFromGroup.value.eligibleReviewDateAvailable)
    {
      this.immigrationFromGroup.patchValue({
        eligibleReviewDateRequired  : false, 
      });

    }
    this.checkAllImmig();
    this.unCheckAllImmig();
  }

  checkWorkEx()
  {
    if(this.workExperienceFromGroup.value.commentsRequired)
    {
      this.workExperienceFromGroup.patchValue({
        commentsAvailable  : true, 
      });

    }

    if(this.workExperienceFromGroup.value.creditableRequired)
    {
      this.workExperienceFromGroup.patchValue({
        creditableAvailable : true, 
      });

    }
 
  }

  checkWorkEx2()
  {
    if(!this.workExperienceFromGroup.value.commentsAvailable)
    {
      this.workExperienceFromGroup.patchValue({
        commentsRequired : false, 
      });

    }

    if(!this.workExperienceFromGroup.value.creditableAvailable)
    {
      this.workExperienceFromGroup.patchValue({
        creditableRequired : false, 
      });

    }
 
  }

  checkWorkEducation()
  {
   
    if(this.educationFromGroup.value.startDateRequired)
    {
      this.educationFromGroup.patchValue({
        startDateAvailable : true, 
      });

    }

    if(this.educationFromGroup.value.endDateRequired)
    {
      this.educationFromGroup.patchValue({
        endDateAvailable : true, 
      });

    }
 
  }
  checkWorkEducation2()
  {
    if(!this.educationFromGroup.value.startDateAvailable)
    {
      this.educationFromGroup.patchValue({
        startDateRequired  : false, 
      });

    }

    if(!this.educationFromGroup.value.endDateAvailable)
    {
      this.educationFromGroup.patchValue({
        endDateRequired  : false, 
      });

    }
 
  }

  checkSkills()
  {
    
    if(this.skillsFromGroup.value.skillsRequired)
    {
      this.skillsFromGroup.patchValue({
        skillsAvailable  : true, 
      });

    }
    if(this.skillsFromGroup.value.commentsRequired)
    {
      this.skillsFromGroup.patchValue({
        commentsAvailable  : true, 
      });

    }
    if(this.skillsFromGroup.value.yearOfExperienceRequired)
    {
      this.skillsFromGroup.patchValue({
        yearOfExperienceAvailable  : true, 
      });

    }

    this.checkAllSkills();
    this.unCheckAllSkills();
 
  }

  checkSkills2()
  {
    
    if(!this.skillsFromGroup.value.commentsAvailable)
    {
      this.skillsFromGroup.patchValue({
        commentsRequired  : false, 
      });

    }

    if(!this.skillsFromGroup.value.skillsAvailable)
    {
      this.skillsFromGroup.patchValue({
        skillsRequired  : false, 
      });

    }

    if(!this.skillsFromGroup.value.yearOfExperienceAvailable)
    {
      this.skillsFromGroup.patchValue({
        yearOfExperienceRequired  : false, 
      });

    }
    this.checkAllSkills();
    this.unCheckAllSkills();
 
  }

  checkAllSkills()
  {
   
    if(this.skillsFromGroup.value.commentsRequired 
      || this.skillsFromGroup.value.commentsAvailable
      || this.skillsFromGroup.value.skillsRequired
      || this.skillsFromGroup.value.skillsAvailable
      || this.skillsFromGroup.value.yearOfExperienceRequired
      || this.skillsFromGroup.value.yearOfExperienceAvailable
     )
     {
      this.skillsSelected = true;
      this.skillsFromGroup.value.selected = this.skillsSelected;
     }
  }
  unCheckAllSkills()
  {
    if(!this.skillsFromGroup.value.commentsRequired 
      && !this.skillsFromGroup.value.commentsAvailable
      && !this.skillsFromGroup.value.skillsRequired
      && !this.skillsFromGroup.value.skillsAvailable
      && !this.skillsFromGroup.value.yearOfExperienceRequired
      && !this.skillsFromGroup.value.yearOfExperienceAvailable
     )
     {
      this.skillsSelected = false;
      this.skillsFromGroup.value.selected = this.skillsSelected;
     }
  }

  checkLanguage()
  {
  
    if(this.languagesFromGroup.value.commentsRequired)
    {
      this.languagesFromGroup.patchValue({
        commentsAvailable  : true, 
      });

    }
    if(this.languagesFromGroup.value.skillRequired)
    {
      this.languagesFromGroup.patchValue({
        skillAvailable  : true, 
      });

    }
    if(this.languagesFromGroup.value.fluencyLevelRequired)
    {
      this.languagesFromGroup.patchValue({
        fluencyLevelAvailable  : true, 
      });

    }
    if(this.languagesFromGroup.value.languagesRequired)
    {
      this.languagesFromGroup.patchValue({
        languagesAvailable  : true, 
      });

    }
    this.checkAllLanguages();
    this.unCheckAllLanguages();
  }

  checkLanguage2()
  {

    if(!this.languagesFromGroup.value.commentsAvailable)
    {
      this.languagesFromGroup.patchValue({
        commentsRequired : false, 
      });

    }
    if(!this.languagesFromGroup.value.skillAvailable)
    {
      this.languagesFromGroup.patchValue({
        skillRequired : false, 
      });

    }
    if(!this.languagesFromGroup.value.fluencyLevelAvailable)
    {
      this.languagesFromGroup.patchValue({
        fluencyLevelRequired : false, 
      });

    }
    if(!this.languagesFromGroup.value.languagesAvailable)
    {
      this.languagesFromGroup.patchValue({
        languagesRequired   : false, 
      });

    }
    this.checkAllLanguages();
    this.unCheckAllLanguages();
  }


  checkLicenses()
  {
    if(this.licenseFromGroup.value.licenseTypeRequired)
    {
      this.licenseFromGroup.patchValue({
        licenseTypeAvailable : true, 
      });

    }
    if(this.licenseFromGroup.value.licenseNumberRequired)
    {
      this.licenseFromGroup.patchValue({
        licenseNumberAvailable : true, 
      });

    }
    if(this.licenseFromGroup.value.issuedDateRequired)
    {
      this.licenseFromGroup.patchValue({
        issuedDateAvailable : true, 
      });

    }
    if(this.licenseFromGroup.value.expiryDateRequired)
    {
      this.licenseFromGroup.patchValue({
        expiryDateAvailable : true, 
      });

    }

    this.checkAllLicenses();
    this.unCheckAllLicenses();
    
  }

  checkLicenses2()
  {
    
    if(!this.licenseFromGroup.value.licenseTypeAvailable)
    {
      this.licenseFromGroup.patchValue({
        licenseTypeRequired  : false, 
      });

    }
    if(!this.licenseFromGroup.value.licenseNumberAvailable)
    {
      this.licenseFromGroup.patchValue({
        licenseNumberRequired  : false, 
      });

    }
    if(!this.licenseFromGroup.value.issuedDateAvailable)
    {
      this.licenseFromGroup.patchValue({
        issuedDateRequired  : false, 
      });

    }
    if(!this.licenseFromGroup.value.expiryDateAvailable)
    {
      this.licenseFromGroup.patchValue({
        expiryDateRequired  : false, 
      });

    }
    this.checkAllLicenses();
    this.unCheckAllLicenses();
  }


  checkAllLicenses()
  {
    if(this.licenseFromGroup.value.licenseTypeRequired 
      || this.licenseFromGroup.value.licenseTypeAvailable
      || this.licenseFromGroup.value.licenseNumberRequired
      || this.licenseFromGroup.value.licenseNumberAvailable
      || this.licenseFromGroup.value.issuedDateRequired
      || this.licenseFromGroup.value.issuedDateAvailable
      || this.licenseFromGroup.value.expiryDateRequired
      || this.licenseFromGroup.value.expiryDateAvailable
     )
     {
      this.licensesSelected = true;
      this.licenseFromGroup.value.selected = this.licensesSelected;
     }
  }

  unCheckAllLicenses()
  {
    if(!this.licenseFromGroup.value.licenseTypeRequired 
      && !this.licenseFromGroup.value.licenseTypeAvailable
      && !this.licenseFromGroup.value.licenseNumberRequired
      && !this.licenseFromGroup.value.licenseNumberAvailable
      && !this.licenseFromGroup.value.issuedDateRequired
      && !this.licenseFromGroup.value.issuedDateAvailable
      && !this.licenseFromGroup.value.expiryDateRequired
      && !this.licenseFromGroup.value.expiryDateAvailable
     )
     {
      this.licensesSelected = false;
      this.licenseFromGroup.value.selected = this.licensesSelected;
     }
  }

  checkAllLanguages()
  {
    if(this.languagesFromGroup.value.commentsRequired 
      || this.languagesFromGroup.value.commentsAvailable
      || this.languagesFromGroup.value.skillRequired
      || this.languagesFromGroup.value.skillAvailable
      || this.languagesFromGroup.value.fluencyLevelRequired
      || this.languagesFromGroup.value.fluencyLevelAvailable
      || this.languagesFromGroup.value.languagesRequired
      || this.languagesFromGroup.value.languagesAvailable
     )
     {
      this.languageSelected = true;
      this.languagesFromGroup.value.selected = this.languageSelected;
     }
  }

  unCheckAllLanguages()
  {
    if(!this.languagesFromGroup.value.commentsRequired 
      && !this.languagesFromGroup.value.commentsAvailable
      && !this.languagesFromGroup.value.skillRequired
      && !this.languagesFromGroup.value.skillAvailable
      && !this.languagesFromGroup.value.fluencyLevelRequired
      && !this.languagesFromGroup.value.fluencyLevelAvailable
      && !this.languagesFromGroup.value.languagesRequired
      && !this.languagesFromGroup.value.languagesAvailable
     )
     {
      this.languageSelected = false;
      this.languagesFromGroup.value.selected = this.languageSelected;
     }
  }

  checkAllMemberShips()
  {
   
    if(this.membershipsFromGroup.value.memberShipRequired 
      || this.membershipsFromGroup.value.memberShipAvailable
      || this.membershipsFromGroup.value.subscriptionPaidByRequired
      || this.membershipsFromGroup.value.subscriptionPaidByAvailable
      || this.membershipsFromGroup.value.currencyRequired
      || this.membershipsFromGroup.value.currencyAvailable
      || this.membershipsFromGroup.value.subscriptionRenewalDateRequired
      || this.membershipsFromGroup.value.subscriptionRenewalDateAvailable
      || this.membershipsFromGroup.value.subscriptionCommenceDateRequired
      || this.membershipsFromGroup.value.subscriptionCommenceDateAvailable
      || this.membershipsFromGroup.value.subscriptionAmountRequired
      || this.membershipsFromGroup.value.subscriptionAmountAvailable
      
     )
     {
      this.memberShipSelected = true;
      this.membershipsFromGroup.value.selected = this.memberShipSelected;
     }
  }

  unCheckAllMemberShips()
  {
   
    if(!this.membershipsFromGroup.value.memberShipRequired 
      && !this.membershipsFromGroup.value.memberShipAvailable
      && !this.membershipsFromGroup.value.subscriptionPaidByRequired
      && !this.membershipsFromGroup.value.subscriptionPaidByAvailable
      && !this.membershipsFromGroup.value.currencyRequired
      && !this.membershipsFromGroup.value.currencyAvailable
      && !this.membershipsFromGroup.value.subscriptionRenewalDateRequired
      && !this.membershipsFromGroup.value.subscriptionRenewalDateAvailable
      && !this.membershipsFromGroup.value.subscriptionCommenceDateRequired
      && !this.membershipsFromGroup.value.subscriptionCommenceDateAvailable
      && !this.membershipsFromGroup.value.subscriptionAmountRequired
      && !this.membershipsFromGroup.value.subscriptionAmountAvailable
      
     )
     {
      this.memberShipSelected = false;
      this.membershipsFromGroup.value.selected = this.memberShipSelected;
     }
  }
  checkMemberShip()
  {
    
    if(this.membershipsFromGroup.value.memberShipRequired)
    {
      this.membershipsFromGroup.patchValue({
        memberShipAvailable : true, 
      });

    }
    if(this.membershipsFromGroup.value.subscriptionPaidByRequired)
    {
      this.membershipsFromGroup.patchValue({
        subscriptionPaidByAvailable : true, 
      });

    }
    if(this.membershipsFromGroup.value.currencyRequired)
    {
      this.membershipsFromGroup.patchValue({
        currencyAvailable : true, 
      });

    }
    if(this.membershipsFromGroup.value.subscriptionRenewalDateRequired)
    {
      this.membershipsFromGroup.patchValue({
        subscriptionRenewalDateAvailable : true, 
      });

    }
    if(this.membershipsFromGroup.value.subscriptionCommenceDateRequired)
    {
      this.membershipsFromGroup.patchValue({
        subscriptionCommenceDateAvailable : true, 
      });

    }
    if(this.membershipsFromGroup.value.subscriptionAmountRequired)
    {
      this.membershipsFromGroup.patchValue({
        subscriptionAmountAvailable : true, 
      });

    }
    this.checkAllMemberShips();
    this.unCheckAllMemberShips();

  }

  checkMemberShip2()
  {
   
    if(!this.membershipsFromGroup.value.memberShipAvailable)
    {
      this.membershipsFromGroup.patchValue({
        memberShipRequired  : false, 
      });

    }
    if(!this.membershipsFromGroup.value.subscriptionPaidByAvailable)
    {
      this.membershipsFromGroup.patchValue({
        subscriptionPaidByRequired  : false, 
      });

    }
    if(!this.membershipsFromGroup.value.currencyAvailable)
    {
      this.membershipsFromGroup.patchValue({
        currencyRequired  : false, 
      });

    }
    if(!this.membershipsFromGroup.value.subscriptionRenewalDateAvailable)
    {
      this.membershipsFromGroup.patchValue({
        subscriptionRenewalDateRequired  : false, 
      });

    }
    if(!this.membershipsFromGroup.value.subscriptionCommenceDateAvailable)
    {
      this.membershipsFromGroup.patchValue({
        subscriptionCommenceDateRequired  : false, 
      });

    }
    if(!this.membershipsFromGroup.value.subscriptionAmountAvailable)
    {
      this.membershipsFromGroup.patchValue({
        subscriptionAmountRequired  : false, 
      });

    }

    this.checkAllMemberShips();
    this.unCheckAllMemberShips();

  }

  createForm() {
    this.personalDetailFromGroup = this.fb.group({
      firstNameRequired: true,
      firstNameAvailable: true,
      lastNameRequired: true,
      lastNameAvailable: true,
      middleNameRequired: false,
      middleNameAvailable: false,
      employeeIdRequired: false,
      employeeIdAvailable: false,
      otherIDRequired: false,
      otherIdAvailable: false,
      mainIdRequired: false,
      mainIdAvailable: false,
      dateOfBirthRequired: false,
      dateOfBirthAvailable: false,
      martialStatusRequired: false,
      martialStatusAvailable: false,
      genderRequired: false,
      genderAvailable: false,
      nationalityRequired: false,
      nationalityAvailable: true,
      driverLicenseNumberRequired: false,
      driverLicenseNumberAvailable: false,
      licenseExpiryDateRequired: false,
      licenseExpiryDateAvailable: false,
      nickNameAvailable: false,
      nickNameRequired: false,
      smokerAvailable: false,
      smokerRequired: false,
      militaryServiceAvailable: false,
      militaryServiceRequired: false,
      selected: true,
    
    });
    this.jobFromGroup = this.fb.group({
      joinedDateRequired: false,
      joinedDateAvailable: false,
      probationEndDateRequired: false,
      probationEndDateAvailable: false,
      dateOfPermanencyRequired: false,
      dateOfPermanencyAvailable: false,
      jobTitleRequired: false,
      jobTitleAvailable: false,
      employmentStatusRequired: false,
      employmentStatusAvailable: false,
      jobCategoryRequired: false,
      jobCategoryAvailable: false,
      subUnitRequired: true,
      subUnitAvailable: true,
      locationRequired: true,
      locationAvailable: true,
      regionRequired: true,
      regionAvailable: true,
      workShiftRequired: false,
      workShiftAvailable: false,
      contractStartDateRequired: false,
      contractStartDateAvailable: false,
      contractEndDateRequired: false,
      contractEndDateAvailable: false,
      commentRequired: false,
      commentAvailable: false,
      effectiveFromRequired: true,
      effectiveFromAvailable: true,
      jobSpecificationRequired: false,
      jobSpecificationAvailable: false,
      costCenterAvailable: false,
      costCenterRequired: false,
      selected: true,
    });
    this.salaryFromGroup = this.fb.group({
      effectiveFromRequired: false,
      effectiveFromAvailable: false,
      commentsRequired: false,
      commentsAvailable: false,
      payGradeRequired: true,
      payGradeAvailable: true,
      currencyRequired: true,
      currencyAvailable: true,
      annualBasicPaymentRequired: false,
      annualBasicPaymentAvailable: false,
    });
    this.contactDetailsFromGroup = this.fb.group({
     
      landMarkAvailable: false,
      landMarkRequired: false,
      selected: false,
      addressStreet1Required: false,
      addressStreet1Available: false,
      addressStreet2Required: false,
      addressStreet2Available: false,
      cityRequired: false,
      cityAvailable: false,
      countryRequired: false,
      countryAvailable: false,
      postalCodeRequired: false,
      postalCodeAvailable: false,
      homeTelephoneRequired: false,
      homeTelephoneAvailable: false,
      mobileRequired: false,
      mobileAvailable: false,
      workTelephoneRequired: false,
      workTelephoneAvailable: false,
      stateRequired: false,
      stateAvailable: false,
      workEmailRequired: false,
      workEmailAvailable: false,
      otherEmailRequired: false,
      otherEmailAvailabl: false
    });
    this.socialMediaDetailsFromGroup = this.fb.group({
      typeRequired: true,
      typeAvailable: true,
      linkRequired: true,
      linkAvailable: true,
      nameRequired: true,
      nameAvailable: true,
    
    });
    this.emergencyContactsFromGroup = this.fb.group({
      homeTelephoneRequired: true,
      homeTelephoneAvailable: true,
      mobileRequired: true,
      mobileAvailable: true,
      workTelephoneRequired: true,
      workTelephoneAvailable: true,
      nameRequired: true,
      nameAvailable: true,
      relationShipRequired: true,
      relationShipAvailable: true,
    });
    this.dependentsFromGroup = this.fb.group({
      nameRequired: true,
      nameAvailable: true,
      relationShipRequired: true,
      relationShipAvailable: true,
      dateOfBirthRequired: true,
      dateOfBirthAvailable: true,
    });
    this.immigrationFromGroup = this.fb.group({
      commentAvailable: false,
      commentRequired: false,
      selected: false,
      documentRequired: true,
      documentAvailable: true,
      numberRequired: true,
      numberAvailable: true,
      eligibleStatusRequired: false,
      eligibleStatusAvailable: false,
      issuedByRequired: false,
      issuedByAvailable: false,
      issuedDateRequired: false,
      issuedDateAvailable: false,
      expiryDateRequired: false,
      expiryDateAvailable: false,
      eligibleReviewDateRequired: false,
      eligibleReviewDateAvailable: false
    });
    this.supervisorFromGroup = this.fb.group({
      nameRequired: true,
      nameAvailable: true,
      reportingMethodRequired: true,
      reportingMethodAvailable: true,
    });
    this.subOrdinateFromGroup = this.fb.group({
      nameRequired: true,
      nameAvailable: true,
      reportingMethodRequired: true,
      reportingMethodAvailable: true,
    });
    this.workExperienceFromGroup = this.fb.group({
      jobTitleRequired: true,
      jobTitleAvailable: true,
      commentsRequired: false,
      commentsAvailable: false,
      companyRequired: true,
      companyAvailable: true,
      fromRequired: true,
      fromAvailable: true,
      toRequired: true,
      toAvailable: true,
      creditableRequired: false,
      creditableAvailable: false,
      selected : true
    });
    this.educationFromGroup = this.fb.group({
      levelRequired: true,
      levelAvailable: true,
      instituteRequired: true,
      instituteAvailable: true,
      majorRequired: true,
      majorAvailable: true,
      yearRequired: true,
      yearAvailable: true,
      gpaRequired: true,
      gpaAvailable: true,
      startDateRequired: false,
      startDateAvailable: false,
      endDateRequired: false,
      endDateAvailable: false,
      selected : true
    });
    this.skillsFromGroup = this.fb.group({
      selected: false,
      commentsRequired: false,
      commentsAvailable: false,
      skillsRequired: false,
      skillsAvailable: false,
      yearOfExperienceRequired: false,
      yearOfExperienceAvailable: false,
    });
    this.languagesFromGroup = this.fb.group({
      commentsRequired: false,
      commentsAvailable: false,
      skillRequired: false,
      skillAvailable: false,
      fluencyLevelRequired: false,
      fluencyLevelAvailable: false,
      languagesRequired: false,
      languagesAvailable: false,
      selected : false
    });
    this.licenseFromGroup = this.fb.group({
      licenseTypeRequired: false,
      licenseTypeAvailable: false,
      licenseNumberRequired: false,
      licenseNumberAvailable: false,
      issuedDateRequired: false,
      issuedDateAvailable: false,
      expiryDateRequired: false,
      expiryDateAvailable: false,
      selected : false
    });
    this.membershipsFromGroup = this.fb.group({
      memberShipRequired: false,
      memberShipAvailable: false,
      subscriptionPaidByRequired: false,
      subscriptionPaidByAvailable: false,
      currencyRequired: false,
      currencyAvailable: false,
      subscriptionRenewalDateRequired: false,
      subscriptionRenewalDateAvailable: false,
      subscriptionCommenceDateRequired: false,
      subscriptionCommenceDateAvailable: false,
      subscriptionAmountRequired: false,
      subscriptionAmountAvailable: false,
      selected: false
    });
    this.directDepositFromGroup = this.fb.group({
      amountRequired: true,
      amountAvailable: true,
      accountNumberRequired: true,
      accountNumberAvailable: true,
      accountTypeRequired: true,
      accountTypeAvailable: true,
      financialInstituteRequired: true,
      financialInstituteAvailable: true,
      branchLocationRequired: true,
      branchLocationAvailable: true,
      routingNumberRequired: true,
      routingNumberAvailable: true,
    });

    this.configModel = this.fb.group({
      workExperienceFromGroup:  this.workExperienceFromGroup.value ,
      skillsFromGroup :  this.skillsFromGroup.value ,
      personalDetailFromGroup: this.personalDetailFromGroup.value,
      membershipsFromGroup: this.membershipsFromGroup.value ,
      licenseFromGroup:  this.licenseFromGroup.value ,
      languagesFromGroup: this.languagesFromGroup.value ,
      jobFromGroup:  this.jobFromGroup.value , 
      immigrationFromGroup: this.immigrationFromGroup.value, 
      educationFromGroup:this.educationFromGroup.value , 
      directDepositFromGroup: this.directDepositFromGroup.value , 
      contactDetailsFromGroup: this.contactDetailsFromGroup.value, 
    });
    
  }


  saveRequireds() : void 
  {
    if(!this.generalState)
    {
      this.toastr.error('You should enable required fields !');
      return ;
    }

    this.configModel.patchValue({ 
      workExperienceFromGroup:  this.workExperienceFromGroup.value ,
      skillsFromGroup :  this.skillsFromGroup.value ,
      personalDetailFromGroup: this.personalDetailFromGroup.value,
      membershipsFromGroup: this.membershipsFromGroup.value ,
      licenseFromGroup:  this.licenseFromGroup.value ,
      languagesFromGroup: this.languagesFromGroup.value ,
      jobFromGroup:  this.jobFromGroup.value , 
      immigrationFromGroup: this.immigrationFromGroup.value, 
      educationFromGroup:this.educationFromGroup.value , 
      directDepositFromGroup: this.directDepositFromGroup.value , 
      contactDetailsFromGroup: this.contactDetailsFromGroup.value, 
    });


    this.dashboardService.setRequiredFields(this.configModel.value).subscribe(
      resp => {
         
          if (resp && resp.data) {
            this.toastr.success('Fileds changed successfully');
          }
         
      }, error => {
          const err = error.error && error.error.msg;
          this.toastr.error(err || 'Error in changing state');
      }
  );
  }
}

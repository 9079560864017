<app-loading [isLoading]="this.isLoading === true || this.isLoadingRequired === true"></app-loading>
<form [formGroup]="jobForm" [hidden]="this.isLoading || this.isLoadingRequired" class="form-material full-width-inputs">
  <div [@fadeInOutTranslate]>
    <div class="row form-group">
      <div class="col-md-4" [hidden]="!requiredJob.jobCategoryAvailable">
        <mat-form-field class="form-element">
          <mat-select [disabled]="!update" class="form-control" formControlName="jobcategoryid" name="jobCatagory"
            disableOptionCentering required="{{ requiredJob.jobCategoryRequired }}">
            <mat-option value="">--Select--</mat-option>
            <ng-container *ngFor="let w of jobCategoryList">
              <mat-option value="{{ w.id }}">{{ w.name }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-label for="contractenddate">Job Catagory </mat-label>
        </mat-form-field>
      </div>
      <div class="col-md-4" [hidden]="!requiredJob.contractStartDateAvailable">
        <mat-form-field class="form-element">
          <mat-label for="contractstartdate">Contract Start Date </mat-label>
          <input [readonly]="!update" [matDatepicker]="contractstartdate" class="form-control"
            formControlName="contractstartdate" matInput required="{{ requiredJob.contractStartDateRequired }}" />
          <mat-datepicker-toggle [disabled]="!update" [for]="contractstartdate" matSuffix> </mat-datepicker-toggle>
          <mat-datepicker #contractstartdate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4" [hidden]="!requiredJob.contractEndDateAvailable">
        <mat-form-field class="form-element">
          <mat-label for="contractenddate">Contract End Date </mat-label>
          <input [readonly]="!update" [matDatepicker]="contractenddate" class="form-control"
            formControlName="contractenddate" matInput required="{{ requiredJob.contractEndDateRequired }}" />
          <mat-datepicker-toggle [disabled]="!update" [for]="contractenddate" matSuffix> </mat-datepicker-toggle>
          <mat-datepicker #contractenddate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4" [hidden]="!requiredJob.costCenterAvailable">
        <mat-form-field class="form-element">
          <mat-label for="companyid">Cost Center</mat-label>
          <mat-select [disabled]="!update" class="form-control" formControlName="costcenterid" name="companyid"
            disableOptionCentering required="{{ requiredJob.costCenterRequired }}">
            <mat-option value="">--Select--</mat-option>
            <ng-container *ngFor="let e of costCenterList">
              <mat-option value="{{ e.id }}">{{ e.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="form-element">
          <mat-label for="effectivefrom">Effective From </mat-label>
          <input [readonly]="!update" [matDatepicker]="effectivefrom" class="form-control"
            formControlName="effectivefrom" matInput required="" />
          <mat-datepicker-toggle [disabled]="!update" [for]="effectivefrom" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #effectivefrom></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4" [hidden]="!requiredJob.employmentStatusAvailable">
        <mat-form-field class="form-element">
          <mat-select [disabled]="!update" class="form-control" formControlName="employeestatus" name="employeestatusid"
            disableOptionCentering required="{{ requiredJob.employmentStatusRequired }}">
            <mat-option value="">--Select--</mat-option>
            <ng-container *ngFor="let e of employeeStatusList">
              <mat-option value="{{ e.id }}">{{ e.name }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-label for="employeestatusid">Employment Status </mat-label>
        </mat-form-field>
      </div>
      <div class="col-md-4" [hidden]="!requiredJob.jobTitleAvailable">
        <mat-form-field class="form-element">
          <mat-label for="contractenddate">Job Title </mat-label>
          <mat-select [disabled]="!update" class="form-control" formControlName="jobtitelid" name="jobtitelid"
            disableOptionCentering required="{{ requiredJob.jobTitleRequired }}">
            <mat-option value="">--Select--</mat-option>
            <ng-container *ngFor="let j of jobTitleList">
              <mat-option value="{{ j.id }}">{{ j.title }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4" [hidden]="!requiredJob.probationEndDateAvailable">
        <mat-form-field class="form-element">
          <mat-label for="endDate">Probition End date </mat-label>
          <input [readonly]="!update" [matDatepicker]="endDate" class="form-control" formControlName="endDate" matInput
            required="{{ requiredJob.probationEndDateRequired }}" />
          <mat-datepicker-toggle [disabled]="!update" [for]="endDate" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4" [hidden]="!requiredJob.workShiftAvailable">
        <mat-form-field class="form-element">
          <mat-select [disabled]="!update" class="form-control" formControlName="workshiftid" name="workshift"
            disableOptionCentering required="{{ requiredJob.workShiftRequired }}">
            <mat-option value="">--Select--</mat-option>
            <ng-container *ngFor="let w of workShiftList">
              <mat-option value="{{ w.id }}">{{ w.name }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-label for="contractenddate">Work Shift </mat-label>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="form-element">
          <mat-select [disabled]="!update" class="form-control" formControlName="region" required=""
            disableOptionCentering>
            <mat-option value="">--Select--</mat-option>
            <ng-container *ngFor="let r of regionList">
              <mat-option value="{{ r.id }}">{{ r.name }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-label>Region</mat-label>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="form-element">
          <mat-select [disabled]="!update" class="form-control" formControlName="locationid" required=""
            disableOptionCentering>
            <mat-option value="">--Select--</mat-option>
            <ng-container *ngFor="let n of locationList">
              <mat-option value="{{ n.id }}"> {{ n.location }} </mat-option>
            </ng-container>
          </mat-select>
          <mat-label>Location</mat-label>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="form-element" *ngIf="!terminated">
          <mat-select [disabled]="!update" class="form-control" formControlName="temporarydepartment" required=""
            disableOptionCentering>
            <mat-option value="">--Select--</mat-option>
            <ng-container *ngFor="let j of temporaryDepartment">
              <mat-option value="{{ j.id }}">{{ j.name }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-label for="des">Sub Unit</mat-label>
        </mat-form-field>
      </div>
      <div class="col-sm-12" [hidden]="!requiredJob.commentAvailable">
        <div class="card-sub">
          <h4>Comment</h4>
          <ng-container>
            <div class="row form-default">
              <mat-form-field class="form-element" style="padding-left: 18px">
                <textarea [readonly]="!update" formControlName="comment" maxlength="500" matInput
                  required="{{ requiredJob.commentRequired }}"></textarea>
                <mat-label></mat-label>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <!--                    <div class="card-sub">-->
        <!--                        <div class="row">-->
        <!--                            <div class="col-sm-10">-->
        <!--                                <h4>Comment</h4>-->
        <!--                            </div>-->
        <!--                            <div class="col-sm-2">-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="form-default ">-->
        <!--                            <mat-form-field class="form-element">-->
        <!--                            <textarea formControlName="comment" maxlength="500"-->
        <!--                                      matInput></textarea>-->
        <!--                                <mat-label>Leave a comment</mat-label>-->
        <!--                            </mat-form-field>-->
        <!--                        </div>-->
        <!--                    </div>-->
      </div>
    </div>
    <div class="action-buttons multi-btns col-sm-12 text-right" [hidden]="!update">
      <a (click)="getTerminationInfo()" class="check-balance cursor-pointer" *ngIf="futureTermination">Will Terminate on
        : {{ this.dateOfTermination | date : "dd-MM-yyyy" }}</a>
      <a (click)="getTerminationInfo()" class="check-balance cursor-pointer" *ngIf="terminated">Terminated on : {{
        this.dateOfTermination | date : "dd-MM-yyyy" }}</a>
      &nbsp; &nbsp;

      <button *ngIf="futureTermination || terminated" (click)="getAcvtivate()" class="btn-danger" mat-raised-button>
        <i class="flaticon-suitcase-1"></i>Activate Employment
      </button>

      <button *ngIf="terminated && adjustEntitlment" (click)="checkAdjustLeaveEntitlment()" class="btn-danger"
        mat-raised-button>
        <i class="flaticon-salary-1"></i>Adjust Leave Entitlements
      </button>
      <button *ngIf="!futureTermination && !terminated" (click)="OnTerminateEmployment()" class="btn-danger"
        mat-raised-button>
        <i class="flaticon-stop-1"></i>Terminate Employment
      </button>
      <button (click)="updateJobDetail()" class="btn-primary" color="primary" mat-raised-button
        [disabled]="!this.jobForm.valid || submitLoading" [class.spinner]="submitLoading">
        <i class="flaticon-diskette"></i>Save
      </button>
    </div>
    <br />
  </div>
</form>

<div class="form-material full-width-inputs" [hidden]="this.isLoading || isLoadingRequired">
  <div *ngFor="let section of this.sectionsList">
    <div *ngIf="section.customFields.length != 0" class="card-sub">
      <h4>{{ section.name }}</h4>
      <ng-container>
        <div class="row form-group">
          <div class="col-4" *ngFor="let custom of section.customFields; index as i">
            <mat-form-field class="form-element" *ngIf="custom.type === 'dd'">
              <mat-select [disabled]="!update" disableOptionCentering class="form-control" [(ngModel)]="custom.value"
                required="{{ custom.required }}"
                (ngModelChange)="checkRequiredFieldsInCustomFields(section.id, section.customFields)">
                <ng-container>
                  <mat-option value="">--Select--</mat-option>
                  <mat-option *ngFor="let option of custom.options" [value]="option" [(ngModel)]="custom.value">
                    {{ option }}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-label>{{ custom.name }}</mat-label>
            </mat-form-field>

            <mat-form-field class="form-element" *ngIf="custom.type === 'st'">
              <mat-label>{{ custom.name }}</mat-label>
              <input [readonly]="!update" matInput class="form-control" [(ngModel)]="custom.value"
                required="{{ custom.required }}"
                (keyup)="checkRequiredFieldsInCustomFields(section.id, section.customFields)" />
            </mat-form-field>

            <mat-form-field class="form-element" *ngIf="custom.type === 'num'">
              <mat-label>{{ custom.name }}</mat-label>
              <input [readonly]="!update" matInput class="form-control" [(ngModel)]="custom.value" type="number"
                required="{{ custom.required }}"
                (keyup)="checkRequiredFieldsInCustomFields(section.id, section.customFields)" />
            </mat-form-field>
            <mat-form-field class="form-element" *ngIf="custom.type === 'dt'">
              <mat-label>{{ custom.name }}</mat-label>
              <input [readonly]="!update" class="form-control" matInput [matDatepicker]="i" [attr.id]="dtPicker + i"
                [(ngModel)]="custom.value" required="{{ custom.required }}"
                (ngModelChange)="checkRequiredFieldsInCustomFields(section.id, section.customFields)" />
              <mat-datepicker-toggle [disabled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
              <mat-datepicker #i></mat-datepicker>
            </mat-form-field>

            <div *ngIf="custom.type === 'cb'">
              <h5>{{ custom.name }}:</h5>
              <div *ngFor="let option of custom.values">
                <mat-checkbox [disabled]="!update" [(ngModel)]="option.booleanValue">{{ option.option }}</mat-checkbox>
                &nbsp;
              </div>
            </div>
          </div>

          <div class="action-buttons multi-btns col-sm-12 text-right" [hidden]="!update">
            <button [disabled]="section.save" (click)="updateCustomField(section.id, section.customFields)"
              class="btn-primary" color="primary" mat-raised-button>
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="card-sub" [hidden]="this.isLoading || isLoadingRequired">
  <div class="row">
    <div class="col-sm-10">
      <h4>Job Attachment</h4>
    </div>
    <div class="col-sm-2" [hidden]="!update">
      <div class="text-right single-add">
        <span class="mytooltip cus-left tooltip-effect-4">
          <span class="tooltip-item">
            <button (click)="addAttachment()" class="btn btn-primary light add-rounded margin-button-05" mat-mini-fab>
              <i class="fa fa-plus"></i>
            </button>
          </span>
          <span class="tooltip-content clearfix">
            <span class="tooltip-text">New</span>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="table-responsive" [hidden]="this.isLoading || isLoadingRequired">
    <mat-card-content>
      <mat-table #table [dataSource]="jobAttachmentList" matSort>
        <ng-container matColumnDef="fileName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>FileName </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.fileName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="url">
          <mat-header-cell *matHeaderCellDef>File </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mytooltip tooltip-effect-4">
              <span class="tooltip-item"><i (click)="open(row.url)" class="single-icon-click flaticon-attachment"
                  style="color: #666"></i></span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">Attachment</span>
              </span>
            </span>
            <!--                                                                            <a (click)="open(row.url)"-->
            <!--                                                                               data-toggle="lightbox">-->
            <!--                                                                                <div *ngIf="row.url"-->
            <!--                                                                                     [ngStyle]="{'background-image': 'url(' + row.url + ')'}"-->
            <!--                                                                                     class="image blank pointer">-->
            <!--                                                                                </div>-->
            <!--                                                                            </a>-->
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="desc">
          <mat-header-cell *matHeaderCellDef>Comment </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.desc }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="size">
          <mat-header-cell *matHeaderCellDef>Size </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.size }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="added">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date Added </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.added | date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit </mat-header-cell>
          <mat-cell [hidden]="!update" *matCellDef="let row">
            <span class="mytooltip tooltip-effect-4">
              <span class="tooltip-item"><i (click)="editAttachment(row)"
                  class="single-icon-click fa fa-pencil"></i></span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">Edit</span>
              </span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete </mat-header-cell>
          <mat-cell [hidden]="!update" *matCellDef="let row">
            <span class="mytooltip tooltip-effect-4">
              <span class="tooltip-item"><i (click)="deleteAttachmentSelected(row.id)"
                  class="single-icon-click fa fa-trash"></i></span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">Delete</span>
              </span>
            </span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns"> </mat-row>
      </mat-table>
      <mat-progress-bar mode="indeterminate" *ngIf="this.isLoadingAttachment"></mat-progress-bar>
      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
  </div>
</div>

<div class="card-sub" [hidden]="this.isLoading || isLoadingRequired">
  <div class="row">
    <div class="col-sm-10">
      <h4>Job History</h4>
    </div>
  </div>
  <div class="table-responsive">
    <mat-card-content>
      <mat-table #jobHistoryTable [dataSource]="this.jobHistoryList" matSort>
        <ng-container matColumnDef="select">
          <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
            <a [matMenuTriggerFor]="menu" class="check-all">
              <span class="material-icons"> more_vert </span>
            </a>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
              <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                Deselect All
              </button>
              <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                Delete Selected
              </button>
            </mat-menu>
          </mat-header-cell>
          <mat-cell [hidden]="!delete" *matCellDef="let row">
            <mat-checkbox *ngIf="!row.present" #matCheckbox (change)="onCheckBoxChange($event, row.id)"
              value="{{ row.id }}"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="event">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Event </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.event }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="effectiveFrom">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Effective From </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.effectiveFrom | date : "dd-MM-yyyy" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="effectiveTo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Effective To </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.present ? row.effectiveTo : (row.effectiveTo | date : "dd-MM-yyyy") }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="jobTitle">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Job Title </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.jobTitle }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="employementStatus">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Employement Status </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.employementStatus }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="jobCategory">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Job Category </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.jobCategory }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="subUnit">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Sub Unit </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.subUnit }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Location </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.location }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnss"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnss"> </mat-row>
      </mat-table>
      <mat-progress-bar mode="indeterminate" *ngIf="this.isLoading"></mat-progress-bar>
      <mat-paginator #jobPagination [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
  </div>
</div>
<ng-template #terminate let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span>Terminate</span>
        Employeement
      </h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isUserAdded" [isLoading]="isUserAdded"></app-loading>

      <form [formGroup]="terminateForm" action="javascript:" class="form-material full-width-inputs">
        <div class="row form-group">
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-select [disabled]="terminated" disableOptionCentering class="form-control" formControlName="reason"
                required="">
                <ng-container *ngFor="let t of terminationList">
                  <mat-option value="{{ t.id }}">{{ t.name }}</mat-option>
                </ng-container>
              </mat-select>
              <mat-label for="des">Termination Reason</mat-label>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <input [min]="terminationDa" [readonly]="terminated" [matDatepicker]="picker" class="form-control"
                formControlName="terminationDate" matInput required="" />
              <mat-datepicker-toggle [for]="picker" matSuffix> </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-label for="expiry">Date </mat-label>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field class="form-element">
              <input [readonly]="terminated" class="form-control" maxlength="40" formControlName="note" matInput
                type="text" />
              <span class="form-bar"></span>
              <mat-label for="title">Note</mat-label>
            </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-checkbox [disabled]="terminated" class="form-control" color="primary"
              formControlName="adjustLeaveEntitlement">
              Adjust Leave Entitlement
            </mat-checkbox>
          </div>
        </div>
      </form>
      <div class="row my-2 mx-2">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button (click)="hideTerminate()" class="btn-danger" mat-raised-button>
            <i class="flaticon-cancel"></i>Cancel
          </button>
          <button (click)="terminateEmployee()" [disabled]="!terminateForm.valid || terminated" class="btn-primary"
            color="primary" mat-raised-button>
            <i class="flaticon-logout-1"></i>Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #editAttach let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Edit Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <form class="form-material full-width-inputs">
        <div class="row form-group">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>File Name</mat-label>
              <input [(ngModel)]="fileName" [ngModelOptions]="{ standalone: true }" class="form-control" maxlength="40"
                matInput />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <textarea [(ngModel)]="comment" maxlength="50" matInput class="form-control" name="name"></textarea>
              <mat-label>Comment</mat-label>
            </mat-form-field>
          </div>
          <div class="col-sm-12 text-right action-buttons multi-btns">
            <button mat-raised-button color="primary" (click)="updateAttachment()" class="btn-primary" name="button">
              <i class="flaticon-diskette"></i>
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #deleteAttachment let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button (click)="this.dialogRef.close()" class="btn-danger" mat-raised-button>
            <i class="flaticon-cancel"></i>Cancel
          </button>
          <button (click)="deleteAttachmentFrom()" class="btn-primary" color="primary" mat-raised-button>
            <i class="flaticon-trash"></i>Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #attach let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <app-loading *ngIf="isSaveAttachment" [isLoading]="isSaveAttachment"></app-loading>
      <form class="form-material full-width-inputs" *ngIf="!isSaveAttachment">
        <div class="row form-group">
          <div class="col-sm-12">
            <div class="upload-btn-wrapper">
              <button class="upload-btn">Attach</button>
              <input (change)="fileChange($event, 'dd')" [(ngModel)]="fileClean" class="form-control" name="myfile"
                type="file" />
              <div class="fileName">
                {{ this.fileObj && this.fileObj.fileName }}<i *ngIf="fileUrl" class="fa fa-check success"></i>
              </div>
            </div>
            <div class="upload-btn-wrapper"></div>
            <div *ngIf="!fileUrl && fileError" class="file-error">{{ fileError }}</div>
            <div class="help-text">Accept upto 2MB</div>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>File Name</mat-label>
              <input [(ngModel)]="fileName" [ngModelOptions]="{ standalone: true }" class="form-control" maxlength="40"
                matInput />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Comment</mat-label>
              <textarea [(ngModel)]="comment" maxlength="50" class="form-control" matInput name="name"></textarea>
            </mat-form-field>
          </div>
          <div class="action-buttons multi-btns col-sm-12 text-right" *ngIf="!isSaveAttachment">
            <button (click)="saveAttachment()" [disabled]="!this.fileObj.fileName || isSaveAttachment"
              class="btn-primary" color="primary" mat-raised-button name="button">
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #deleteJobHistory let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Job History</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobH()">
            <i class="flaticon-trash"></i>Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #adjusLeaveEntitlment let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Adjust Leave Entitlment</h4>
    </div>
    <div class="mat-modal-body">
      <div class="dt-responsive" *ngIf="adjustLeaveEntitlmentsList">
        <div class="table-responsive">
          <mat-card-content>
            <mat-table [dataSource]="adjustLeaveEntitlmentsList" matSort>
              <ng-container matColumnDef="leaveType">
                <mat-header-cell *matHeaderCellDef>Leave Type</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.leaveType }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="entitlment">
                <mat-header-cell *matHeaderCellDef>Entitlements</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ row.entitlment }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="adjustTo">
                <mat-header-cell *matHeaderCellDef>Adjust To</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <mat-form-field class="form-element">
                    <input (ngModelChange)="adjustToChanged($event, row)" maxlength="40" matInput
                      ngModel="{{ row.adjustTo }}" type="number" />
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="columnAjust"></mat-header-row>
              <mat-row *matRowDef="let row; columns: columnAjust"> </mat-row>
            </mat-table>
          </mat-card-content>
        </div>
      </div>

      <div class="row my-2 mx-2">
        <div class="action-buttons multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button mat-raised-button class="btn-danger" (click)="saveAdjust()">
            <i class="flaticon-diskette"></i>Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
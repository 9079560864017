import { Component, OnInit, ViewChild, Injectable, ElementRef, AfterContentInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { NotificationService } from "src/app/core/services/notification.service";
import * as CryptoJS from "crypto-js";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
@Injectable()
export class LoginComponent implements OnInit {
  public loginDetail = {
    username: "",
    password: "",
    role: "",
    client: "demo",
  };
  public show: boolean;
  public isLoading: boolean;
  public loginError: string;
  public roleListError: string;
  public userRoleList: Array<any>;
  public forgetFlag: boolean;
  public empId: string;
  public passwordHide: boolean = true;
  public alertMessage: string;

  @ViewChild("roleDiv", { static: false }) role;
  @ViewChild("loginBtn") loginBtn: ElementRef;
  @ViewChild("loginform") loginForm!: NgForm; // Access the form
  encryptedData: any;

  constructor(
    private router: Router,
    private readonly authService: AuthService,
    private toastr: NotificationService,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    document.querySelector("body").setAttribute("themebg-pattern", "theme1");
    this.show = false;
    this.getUserRole();

    var domain = document.location.host;
    console.log("domain : " + domain);
    if (domain) {
      this.loginDetail.client = domain.split(".")[0];
      console.log("Client : " + this.loginDetail.client);
    }

    this.route.queryParams.subscribe((params) => {
      this.encryptedData = params["data"];
      this.alertMessage = params["alertMessage"];
    });

    const secretKey = "D9F3C2A1B7E648F98A3B2C3D4E5F6A7G"; // Example 32-character key

    if (this.encryptedData) {
      this.isLoading = true;
      const bytes = CryptoJS.AES.decrypt(this.encryptedData, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      const username = decryptedData.username;
      const password = decryptedData.password;
      if (username && password) {
        this.loginDetail.username = username;
        this.loginDetail.password = password;

        setTimeout(() => {
          this.login();
          this.loginForm.ngSubmit.emit();
        }, 2000); // 2000 milliseconds = 2 seconds
      }
    }
  }

  // listenToMessages(): void {
  //   // Use Renderer2 to listen for the "message" event
  //   this.renderer.listen("window", "message", (event: MessageEvent) => {
  //     // Check the event origin to prevent unauthorized messages
  //     // if (event.origin === "https://ibiss.screenhrm.net") {
  //     console.log("Received data from parent window:", event.data.username);
  //     if (event.data.username) {
  //       this.loginDetail.username = event.data.username;
  //     }
  //     if (event.data.password) {
  //       this.loginDetail.password = event.data.password;
  //     }
  //     // } else {
  //     // console.warn("Message from unauthorized origin:", event.origin);
  //     // }
  //   });
  // }

  public changeFlag(val) {
    this.loginError = "";
    this.forgetFlag = val;
  }

  public login(): void {
    this.isLoading = true;
    this.loginError = "";
    //this.loginDetail.client = "demo";
    this.authService.login(this.loginDetail).subscribe(
      (resp) => {
        this.loginError = "";
        // this.router.navigate(['admin/user-management/users']);
        this.router.navigate(["more/dashboard"]);
        this.authService.postLoginRefresh(resp);
        // sessionStorage.setItem("empId", resp.extras.empId);
        //this.authService.setPersmissions(resp.extras.role.dataGroup);
        //const token = this.authService.getDecodedToken();
        this.isLoading = false;
        this.toastr.success("Login successful");
        //sessionStorage.setItem('username', this.loginDetail.username);
      },
      (error) => {
        this.isLoading = false;
        const err = error.error && error.error.msg;
        this.loginError = "Incorrect email or password.";
        this.toastr.error(err || "Incorrect email or password.");
      }
    );
    // } else {
    //   this.toastr.error('Please select a role type');
    // }
  }

  public getUserRole(): void {
    this.authService.getUserRoleList().subscribe(
      (resp) => {
        this.roleListError = "";
        if (resp && resp.data) {
          this.userRoleList = resp.data;
        }
      },
      (error) => {
        this.roleListError = "Error getting roles";
        console.log("Error getting user list");
      }
    );
  }

  public setUsrRole(role: string): void {
    this.loginDetail.role = role;
    this.role.nativeElement.innerText = role;
    this.show = false;
  }

  public toggleDropdown(): void {
    this.show = !this.show;
  }

  public getEmailLink(): void {
    this.loginError = "";
    this.isLoading = true;
    this.authService.forgotPassword(this.loginDetail).subscribe(
      (data) => {
        this.isLoading = false;
        this.toastr.success(`A password reset link has been sent to your email : ${this.loginDetail.username}.`);
      },
      (error) => {
        this.isLoading = false;
        const msg = error && error.error && error.error.msg;
        this.toastr.error(msg || "Email does not exists.");
        this.loginError = "Email does not exists.";
      }
    );
  }
}

<app-loading *ngIf="isLoading" [isLoading]="this.isLoading === true"></app-loading>
<div class="card cus-card">
  <form class="form-material full-width-inputs" [formGroup]="salaryForm" action="javascript:" *ngIf="!isLoading">
    <div class="row form-group">
      <div class="col-md-4">
        <mat-form-field>
          <mat-label>Pay Grade</mat-label>
          <mat-select [disabled]="!update" (selectionChange)="setVal()" [(ngModel)]="this.currentPaygrade"
            class="form-control" formControlName="paygradeid" required="">
            <mat-option *ngFor="let n of payGradeList" [value]="n.id">
              {{ n.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4" *ngIf="payCurrencyObj">
        <mat-form-field>
          <mat-label>Currency List</mat-label>
          <mat-select [disabled]="!update" (selectionChange)="setPayCurrencyValue()"
            [(ngModel)]="payCurrencyObj.currencyCode" class="form-control" formControlName="currencyCode" required="">
            <mat-option *ngFor="let n of currencyList" [value]="n.currencyCode">
              {{ n.currencyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4 with-margin" *ngIf="payCurrencyObj">
        <div class="row">
          <div class="col-md-6">
            <input [(ngModel)]="payCurrencyObj.min" class="form-control dis-i" maxlength="30" formControlName="min"
              mask="0000000000" type="text" />
            <span class="form-bar"></span>
            <label class="float-label">Minimum</label>
          </div>
          <div class="col-md-6">
            <input [(ngModel)]="payCurrencyObj.max" class="form-control dis-i" maxlength="30" formControlName="max"
              mask="0000000000" type="text" />
            <span class="form-bar"></span>
            <label class="float-label">Maximum</label>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 40px"></div>
    <div class="row">
      <div class="col-sm-6">
        <table class="table1">
          <thead class="font-header">
            <tr>
              <th>Earning</th>
              <th>Amount/Percentage</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let e of earningList">
              <td>{{ e.name }} <span *ngIf="e.taxable">(T)</span><span *ngIf="e.valueType == 'P'">%</span></td>
              <td>
                <input [readonly]="!update" (keyup)="calcTotal()" [(ngModel)]="e.value"
                  [ngClass]="{ 'dis-i-table': !e.status }" [ngModelOptions]="{ standalone: true }" class="form-control"
                  mask="00000000" type="text" />
                <span *ngIf="e.valueType == 'P'" title="Calculated percentage amount">{{
                  e.value * (basicSalary / 100)
                  }}</span>
                <mat-error class="error-messages" *ngIf="e.basic && e.value > payCurrencyObj.max">
                  the Annual Basic Salary must Be under or equal {{ payCurrencyObj.max }}
                </mat-error>
              </td>
              <td style="text-align: end">
                <mat-slide-toggle [disabled]="!update" color="primary" [checked]="e.status"
                  (toggleChange)="e.status = !e.status; disableLine(e); calcTotal()" *ngIf="!e.basic">
                </mat-slide-toggle>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-6">
        <table class="table1">
          <thead class="font-header">
            <tr>
              <th>Deduction</th>
              <th>Amount/Percentage</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let e of deductionList">
              <td>{{ e.name }} <span *ngIf="e.taxable">(N)</span><span *ngIf="e.valueType == 'P'">%</span></td>
              <td>
                <input [readonly]="!update" (keyup)="calcTotal()" [(ngModel)]="e.value"
                  [ngClass]="{ 'dis-i-table': !e.status }" [ngModelOptions]="{ standalone: true }" class="form-control"
                  mask="00000000" type="text" />
                <span *ngIf="e.valueType == 'P'" title="Calculated percentage amount">{{
                  e.value * (basicSalary / 100)
                  }}</span>
              </td>
              <td style="text-align: end">
                <mat-slide-toggle [disabled]="!update" color="primary" [checked]="e.status"
                  (toggleChange)="e.status = !e.status; disableLine(e); calcTotal()" *ngIf="!e.basic">
                </mat-slide-toggle>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-5">
      <div class="my-2 salary-summary">
        <b>Total Taxable: </b> <span class="number">{{ totalTaxable }}</span>
      </div>
      <hr style="margin-left: 0px" width="30%" color="#DDD" />
      <div class="my-2 salary-summary">
        <b>Total Deductions: </b> <span class="number">{{ totalDeductions }}</span>
      </div>
      <hr style="margin-left: 0px" width="30%" color="#DDD" />
      <div class="my-2 salary-summary">
        <b>Total Earnings: </b> <span class="number">{{ grossSalary }}</span>
      </div>
      <hr style="margin-left: 0px" width="30%" color="#DDD" />
    </div>

    <div [hidden]="!update" style="margin-bottom: 40px" class="action-buttons multi-btns col-sm-12 text-right">
      <button (click)="updateSalaryDetail()" class="btn-primary" color="primary" [disabled]="isSaving"
        [class.spinner]="isSaving" mat-raised-button>
        <i class="flaticon-diskette"></i>Save
      </button>
    </div>
  </form>

  <div class="form-material full-width-inputs" [hidden]="this.isLoading">
    <div *ngFor="let section of this.sectionsList">
      <div *ngIf="section.customFields.length != 0" class="card-sub">
        <h4>{{ section.name }}</h4>
        <ng-container>
          <div class="row form-group">
            <div class="col-4" *ngFor="let custom of section.customFields; index as i">
              <mat-form-field class="form-element" *ngIf="custom.type === 'dd'">
                <mat-select [disbaled]="!update" disableOptionCentering class="form-control" [(ngModel)]="custom.value"
                  required="{{ custom.required }}"
                  (ngModelChange)="checkRequiredFieldsInCustomFields(section.id, section.customFields)">
                  <ng-container>
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let option of custom.options" [value]="option" [(ngModel)]="custom.value">
                      {{ option }}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <mat-label>{{ custom.name }}</mat-label>
              </mat-form-field>

              <mat-form-field class="form-element" *ngIf="custom.type === 'st'">
                <mat-label>{{ custom.name }}</mat-label>
                <input [readonly]="!update" matInput class="form-control" [(ngModel)]="custom.value"
                  required="{{ custom.required }}"
                  (keyup)="checkRequiredFieldsInCustomFields(section.id, section.customFields)" />
              </mat-form-field>

              <mat-form-field class="form-element" *ngIf="custom.type === 'num'">
                <mat-label>{{ custom.name }}</mat-label>
                <input [readonly]="!update" matInput class="form-control" [(ngModel)]="custom.value" type="number"
                  required="{{ custom.required }}"
                  (keyup)="checkRequiredFieldsInCustomFields(section.id, section.customFields)" />
              </mat-form-field>
              <mat-form-field class="form-element" *ngIf="custom.type === 'dt'">
                <mat-label>{{ custom.name }}</mat-label>
                <input [readonly]="!update" class="form-control" matInput [matDatepicker]="i" [attr.id]="dtPicker + i"
                  [(ngModel)]="custom.value" required="{{ custom.required }}"
                  (ngModelChange)="checkRequiredFieldsInCustomFields(section.id, section.customFields)" />
                <mat-datepicker-toggle [disbaled]="!update" matSuffix [for]="i"></mat-datepicker-toggle>
                <mat-datepicker #i></mat-datepicker>
              </mat-form-field>

              <div *ngIf="custom.type === 'cb'">
                <h5>{{ custom.name }}:</h5>
                <div *ngFor="let option of custom.values">
                  <mat-checkbox [disabled]="!update" [(ngModel)]="option.booleanValue">{{
                    option.option
                    }}</mat-checkbox>
                  &nbsp;
                </div>
              </div>
            </div>

            <div [hidden]="!update" class="action-buttons multi-btns col-sm-12 text-right">
              <button [disabled]="section.save" (click)="updateCustomField(section.id, section.customFields)"
                class="btn-primary" color="primary" mat-raised-button>
                <i class="flaticon-diskette"></i>Save
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="card-sub" [hidden]="this.isLoading">
    <div class="row">
      <div class="col-sm-10">
        <h4>Salary Attachment</h4>
      </div>
      <div class="col-sm-2" [hidden]="!update">
        <div class="text-right">
          <span class="mytooltip cus-left tooltip-effect-4">
            <span class="tooltip-item">
              <button (click)="addAttachment()" class="btn btn-primary mat-button-base light add-rounded" raised-button>
                <i class="fa fa-plus"></i>
              </button>
            </span>
            <span class="tooltip-content clearfix">
              <span class="tooltip-text">New</span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <mat-card-content>
        <mat-table #table [dataSource]="salaryAttachmentList" matSort>
          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>FileName </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.fileName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="url">
            <mat-header-cell *matHeaderCellDef>File </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mytooltip tooltip-effect-4">
                <span class="tooltip-item"><i (click)="open(row.url)" class="single-icon-click flaticon-attachment"
                    style="color: #666"></i></span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Attachment</span>
                </span>
              </span>
              <!--                                                                            <a (click)="open(row.url)"-->
              <!--                                                                               data-toggle="lightbox">-->
              <!--                                                                                <div *ngIf="row.url"-->
              <!--                                                                                     [ngStyle]="{'background-image': 'url(' + row.url + ')'}"-->
              <!--                                                                                     class="image blank pointer">-->
              <!--                                                                                </div>-->
              <!--                                                                            </a>-->
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="desc">
            <mat-header-cell *matHeaderCellDef>Comment </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.desc }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="size">
            <mat-header-cell *matHeaderCellDef>Size </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.size }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="added">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date Added </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.added | date }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell [hidden]="!update" *matHeaderCellDef>Edit </mat-header-cell>
            <mat-cell [hidden]="!update" *matCellDef="let row">
              <span class="mytooltip tooltip-effect-4">
                <span class="tooltip-item"><i (click)="editAttachment(row)"
                    class="single-icon-click fa fa-pencil"></i></span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Edit</span>
                </span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="delete">
            <mat-header-cell [hidden]="!delete" *matHeaderCellDef>Delete </mat-header-cell>
            <mat-cell [hidden]="!delete" *matCellDef="let row">
              <span class="mytooltip tooltip-effect-4">
                <span class="tooltip-item"><i (click)="deleteAttachmentSelected(row.id)"
                    class="single-icon-click fa fa-trash"></i></span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">Delete</span>
                </span>
              </span>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columns"> </mat-row>
        </mat-table>
        <mat-progress-bar mode="indeterminate" *ngIf="this.isLoading"></mat-progress-bar>
        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </div>
  </div>

  <div class="card-sub" [hidden]="this.isLoading">
    <div class="row">
      <div class="col-sm-10">
        <h4>Salary History</h4>
      </div>
    </div>
    <div class="table-responsive" style="white-space: pre-line">
      <mat-card-content>
        <mat-table #table [dataSource]="this.salaryHistoryList" matSort>
          <ng-container matColumnDef="select">
            <mat-header-cell [hidden]="!delete" *matHeaderCellDef>
              <a [matMenuTriggerFor]="menu" class="check-all">
                <span style="color: #888" class="material-icons"> more_vert </span>
              </a>

              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onSelectAllClicked()">Select All</button>
                <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                  Deselect All
                </button>
                <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                  Delete Selected
                </button>
              </mat-menu>
            </mat-header-cell>
            <mat-cell [hidden]="!delete" *matCellDef="let row">
              <mat-checkbox *ngIf="!row.present" #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                value="{{ row.id }}"></mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="event">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Event </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.event }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="effectiveFrom">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Effective From </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.effectiveFrom | date : "dd-MM-yyyy" }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="effectiveTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Effective To </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.present ? row.effectiveTo : (row.effectiveTo | date : "dd-MM-yyyy") }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="payGrade">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Pay Grade </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.payGrade }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="currency">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Currency </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.currency }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="annualBasicPayment">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Annual Basic Payment </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.annualBasicPayment }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="earnings">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Earnings </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.earnings }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="deductions">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Deductions </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.deductions }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsSalary"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columnsSalary"> </mat-row>
        </mat-table>
        <mat-progress-bar mode="indeterminate" *ngIf="this.isLoading"></mat-progress-bar>
        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
      </mat-card-content>
    </div>
  </div>
</div>

<ng-template #attach let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <!-- <div class="card-content"> -->
      <app-loading *ngIf="isAttachLoad" [isLoading]="isAttachLoad"></app-loading>
      <form class="form-material full-width-inputs" *ngIf="!isAttachLoad">
        <div class="row form-group">
          <div class="col-sm-12">
            <div class="upload-btn-wrapper">
              <button class="upload-btn">Attach</button>
              <input (change)="fileChange($event, 'dd')" [(ngModel)]="fileClean" class="form-control" name="myfile"
                type="file" />
              <div class="fileName">
                {{ this.fileObj && this.fileObj.fileName }}<i *ngIf="fileUrl" class="fa fa-check success"></i>
              </div>
            </div>
            <div class="upload-btn-wrapper"></div>
            <div *ngIf="!fileUrl && fileError" class="file-error">{{ fileError }}</div>
            <div class="help-text">Accept upto 2MB</div>
          </div>

          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>File Name</mat-label>
              <input [(ngModel)]="fileName" [ngModelOptions]="{ standalone: true }" class="form-control" maxlength="30"
                matInput />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>Comment</mat-label>
              <textarea [(ngModel)]="comment" maxlength="50" class="form-control" matInput name="name"></textarea>
            </mat-form-field>
          </div>
          <div class="action-buttons multi-btns col-sm-12 text-right" *ngIf="!isAttachLoad">
            <button (click)="saveAttachment()" [disabled]="!this.fileObj.fileName" class="btn-primary" color="primary"
              mat-raised-button name="button">
              <i class="flaticon-diskette"></i>Save
            </button>
          </div>
        </div>
      </form>
      <!-- </div> -->
    </div>
  </div>
</ng-template>

<ng-template #editAttach let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Edit Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <form class="form-material full-width-inputs">
        <div class="row form-group">
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <mat-label>File Name</mat-label>
              <input [(ngModel)]="fileName" [ngModelOptions]="{ standalone: true }" class="form-control" maxlength="30"
                matInput />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="form-element">
              <textarea [(ngModel)]="comment" maxlength="50" matInput class="form-control" name="name"></textarea>
              <mat-label>Comment</mat-label>
            </mat-form-field>
          </div>
          <div class="col-sm-12 text-right action-buttons multi-btns">
            <button mat-raised-button color="primary" (click)="updateAttachment()" class="btn-primary" name="button">
              <i class="flaticon-diskette"></i>
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #deleteAttachment let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Attachment</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button (click)="this.dialogRef.close()" class="btn-danger" mat-raised-button>
            <i class="flaticon-cancel"></i>Cancel
          </button>
          <button (click)="deleteAttachmentFrom()" class="btn-primary" color="primary" mat-raised-button>
            <i class="flaticon-trash"></i>Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deleteSalaryHistory let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Delete Salary History</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure ?</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="deleteJobS()">
            <i class="flaticon-trash"></i>Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { NotificationService } from "src/app/core/services/notification.service";
import { DashboardService } from '../../../dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ViewReportComponent } from '../view-report/view-report.component';
import { Observable, of } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';



@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss']
})
export class AddReportComponent implements OnInit, AfterViewInit {
  isLinear = true;
  public reportId = '';
  public reportName = '';
  public addReportNameFormGroup: FormGroup;
  public personalSelectionFormGroup: FormGroup;
  public personalDisplayFormGroup: FormGroup;
  public jobSelectionFormGroup: FormGroup;
  public jobDisplayFormGroup: FormGroup;
  public salarySelectionFormGroup: FormGroup;
  public salaryDisplayFormGroup: FormGroup;
  public educationDisplayFormGroup: FormGroup;
  public educationSelectionFormGroup: FormGroup;
  public immigrationDisplayFormGroup: FormGroup;
  public immigrationSelectionFormGroup: FormGroup;
  public directDepositeDisplayFormGroup: FormGroup;
  public directDepositeSelectionFormGroup: FormGroup;
  public licensesDisplayFormGroup: FormGroup;
  public licensesSelectionFormGroup: FormGroup;
  public membershipDisplayFormGroup: FormGroup;
  public membershipSelectionFormGroup: FormGroup;
  public workWeekDisplayFormGroup: FormGroup;
  public workWeekSelectionFormGroup: FormGroup;
  public supervisorsDisplayFormGroup: FormGroup;
  public supervisorsSelectionFormGroup: FormGroup;
  public languageDisplayFormGroup: FormGroup;
  public languagesSelectionFormGroup: FormGroup;
  public skillsDisplayFormGroup: FormGroup;
  public skillsSelectionFormGroup: FormGroup;
  public workExperienceDisplayFormGroup: FormGroup;
  public subordinatesDisplayFormGroup: FormGroup;
  public fullSubUnitHierarchyLevelsDisplayFormGroup: FormGroup;
  public emergencyContactsDisplayFormGroup: FormGroup;
  public contactDetailsDisplayFormGroup: FormGroup;
  public dependentsDisplayFormGroup: FormGroup;
  public awardsDisplayFormGroup: FormGroup;
  public publicationsDisplayFormGroup: FormGroup;
  public trainingDisplayFormGroup: FormGroup;
  // data list for the dropdowns
  public nationalityList: Array<any> = [];
  public countryList: Array<any> = [];
  public payGradeList: Array<any> = [];
  public earningList: Array<any> = [];
  public currencyList: Array<any> = [];
  public salaryList: Array<any> = [];
  public payrollList: Array<any> = [];
  public jobCategoryList: Array<any> = [];
  public costCenterList: Array<any> = [];
  public workShiftList: Array<any> = [];
  public jobTitleList: Array<any> = [];
  public employeeStatusList: Array<any> = [];
  public reportingTypes: Array<any> = [];
  public regionList: Array<any> = [];
  public locationList: Array<any> = [];
  public terminationList: Array<any> = [];
  public employeeList: Array<any> = [];
  public subUnitList: Array<any> = [];
  public educationList: Array<any> = [];
  public licenseList: Array<any> = [];
  public memberShipList: Array<any> = [];
  public languageList: Array<any> = [];
  public skillList: Array<any> = [];
  // for the checkboxes
  public personalDisplayAdded = false;
  public jobDisplayAdded = false;
  public salaryDisplayAdded = false;
  public educationDisplayAdded = false;
  public immigrationDisplayAdded = false;
  public directDepositeAdded = false;
  public licensesDisplayAdded = false;
  public membershipsDisplayAdded = false;
  public supervisorsDisplayAdded = false;
  public workWeekAdded = false;
  public laguageDisplayAdded = false;
  public skillsDisplayAdded = false;
  public workExperienceDisplayAdded = false;
  public subordinatesDisplayAdded = false;
  public fullSubUnitHierarchyLevelsDisplayAdded = false;
  public contactDetailsDisplayAdded = false;
  public emergencyContactsDisplayAdded = false;
  public dependentsDisplayAdded = false;
  public awardsDisplayAdded = false;
  public publicationsDisplayAdded = false;
  public trainingDisplayAdded = false;
  // for the selection precheck
  public directDepositeSelectionAdded = false;
  public educationSelectionAdded = false;
  public immigrationSelectionAdded = false;
  public jobSelectionAdded = false;
  public laguageSelectionAdded = false;
  public licensesSelectionAdded = false;
  public membershipsSelectionAdded = false;
  public personalSelectionAdded = false;
  public salarySelectionAdded = false;
  public skillsSelectionAdded = false;
  public supervisorsSelectionAdded = false;
  public workWeekSelectionAdded = false;

  filteredemployees1: Observable<string[]>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable = true;
  removable = true;
  addOnBlur = true;
  tempEmployees: Array<any> = [];
  employees: Array<any> = [];
  @ViewChild("empInput") empInput: ElementRef;
  employeeArr = new FormControl()
  empCtrl = new FormControl();


  public chipList: any = {
    employees: [],
    nationalityList: [],
    employmentStatus: [],
    jobTitle: [],
    jobCategory: [],
    costCinter: [],
    subUnit: [],
    location: [],
    workShift: [],
    terminationReason: [],
    region: [],
    payGrade: [],
    currency: [],
    education: [],
    licenseType: [],
    currencyM: [],
    membership: [],
    reportingMethod: [],
    supervisorName: [],
    skill: [],
    language: []
  };

  public isLoadingReport = false;


  customSelectionList: Array<any> = [];
  customSelectionDropDown: Array<any> = [];
  customDisplayList: Array<any> = [];
  customDisplayDropDown: Array<any> = [];


  // Define availabel fields
  public contactDetailsFromGroup: any
    =
    {
      addressStreet1Available: true,
      addressStreet1Required: false,
      addressStreet2Available: true,
      addressStreet2Required: false,
      cityAvailable: true,
      cityRequired: false,
      countryAvailable: true,
      countryRequired: false,
      homeTelephoneAvailable: true,
      homeTelephoneRequired: false,
      landMarkAvailable: true,
      landMarkRequired: false,
      mobileAvailable: true,
      mobileRequired: false,
      otherEmailAvailabl: true,
      otherEmailRequired: false,
      postalCodeAvailable: true,
      postalCodeRequired: false,
      selected: true,
      stateAvailable: true,
      stateRequired: false,
      workEmailAvailable: true,
      workEmailRequired: false,
      workTelephoneAvailable: true,
      workTelephoneRequired: false,
    }

  public directDepositFromGroup: any =
    {
      accountNumberAvailable: true,
      accountNumberRequired: true,
      accountTypeAvailable: true,
      accountTypeRequired: true,
      amountAvailable: true,
      amountRequired: true,
      branchLocationAvailable: true,
      branchLocationRequired: true,
      financialInstituteAvailable: true,
      financialInstituteRequired: true,
      routingNumberAvailable: true,
      routingNumberRequired: true

    }

  public educationFromGroup: any =
    {
      endDateAvailable: true,
      endDateRequired: false,
      levelAvailable: true,
      levelRequired: true,
      selected: true,
      startDateAvailable: true,
      startDateRequired: false,
    }

  public immigrationFromGroup: any =
    {
      commentAvailable: true,
      commentRequired: false,
      eligibleReviewDateAvailable: true,
      eligibleReviewDateRequired: false,
      eligibleStatusAvailable: true,
      eligibleStatusRequired: false,
      expiryDateAvailable: true,
      expiryDateRequired: false,
      issuedByAvailable: true,
      issuedByRequired: false,
      issuedDateAvailable: true,
      issuedDateRequired: false

    }

  public jobFromGroup: any =
    {
      commentAvailable: true,
      commentRequired: false,
      contractEndDateAvailable: true,
      contractEndDateRequired: false,
      contractStartDateAvailable: true,
      contractStartDateRequired: false,
      costCenterAvailable: true,
      costCenterRequired: false,
      employmentStatusAvailable: true,
      employmentStatusRequired: false,
      jobCategoryAvailable: true,
      jobCategoryRequired: false,
      jobTitleAvailable: true,
      jobTitleRequired: false,
      probationEndDateAvailable: true,
      probationEndDateRequired: false,
      workShiftAvailable: true,
      workShiftRequired: false
    }

  public languagesFromGroup: any =
    {
      commentsAvailable: true,
      commentsRequired: false,
      fluencyLevelAvailable: true,
      fluencyLevelRequired: false,
      languagesAvailable: true,
      languagesRequired: false,
      skillAvailable: true,
      skillRequired: false
    }

  public licenseFromGroup: any =
    {
      expiryDateAvailable: true,
      expiryDateRequired: false,
      issuedDateAvailable: true,
      issuedDateRequired: false,
      licenseNumberAvailable: true,
      licenseNumberRequired: false,
      licenseTypeAvailable: true,
      licenseTypeRequired: false

    }

  public membershipsFromGroup: any =
    {
      currencyAvailable: true,
      currencyRequired: false,
      memberShipAvailable: true,
      memberShipRequired: false,
      subscriptionAmountAvailable: true,
      subscriptionAmountRequired: false,
      subscriptionCommenceDateAvailable: true,
      subscriptionCommenceDateRequired: false,
      subscriptionPaidByAvailable: true,
      subscriptionPaidByRequired: false,
      subscriptionRenewalDateAvailable: true,
      subscriptionRenewalDateRequired: false,
    }

  public personalDetailFromGroup: any =
    {
      dateOfBirthAvailable: true,
      dateOfBirthRequired: false,
      driverLicenseNumberAvailable: true,
      driverLicenseNumberRequired: false,
      genderAvailable: true,
      genderRequired: false,
      licenseExpiryDateAvailable: true,
      licenseExpiryDateRequired: false,
      martialStatusAvailable: true,
      martialStatusRequired: false,
      middleNameAvailable: true,
      middleNameRequired: false,
      militaryServiceAvailable: true,
      militaryServiceRequired: false,
      nickNameAvailable: true,
      nickNameRequired: false,
      smokerAvailable: true,
      smokerRequired: false
    }

  public skillsFromGroup: any =
    {
      commentsAvailable: true,
      commentsRequired: false,
      skillsAvailable: true,
      skillsRequired: false,
      yearOfExperienceAvailable: true,
      yearOfExperienceRequired: false
    }

  public workExperienceFromGroup: any =
    {
      commentsAvailable: true,
      commentsRequired: false,
      creditableAvailable: true,
      creditableRequired: false
    }


  ngOnDestroy() {

    this.dashboardService = null;
    this.toastr = null;
    this.fb = null;
    this.route = null;
    this.location = null;
    this.router = null;
    this.addReportNameFormGroup = null;
    this.personalSelectionFormGroup = null;
    this.personalDisplayFormGroup = null;
    this.jobSelectionFormGroup = null;
    this.jobDisplayFormGroup = null;
    this.salarySelectionFormGroup = null;
    this.salaryDisplayFormGroup = null;
    this.educationDisplayFormGroup = null;
    this.educationSelectionFormGroup = null;
    this.immigrationDisplayFormGroup = null;
    this.immigrationSelectionFormGroup = null;
    this.directDepositeDisplayFormGroup = null;
    this.directDepositeSelectionFormGroup = null;
    this.licensesDisplayFormGroup = null;
    this.licensesSelectionFormGroup = null;
    this.membershipDisplayFormGroup = null;
    this.membershipSelectionFormGroup = null;
    this.workWeekDisplayFormGroup = null;
    this.workWeekSelectionFormGroup = null;
    this.supervisorsDisplayFormGroup = null;
    this.supervisorsSelectionFormGroup = null;
    this.languageDisplayFormGroup = null;
    this.languagesSelectionFormGroup = null;
    this.skillsDisplayFormGroup = null;
    this.skillsSelectionFormGroup = null;
    this.workExperienceDisplayFormGroup = null;
    this.subordinatesDisplayFormGroup = null;
    this.fullSubUnitHierarchyLevelsDisplayFormGroup = null;
    this.emergencyContactsDisplayFormGroup = null;
    this.contactDetailsDisplayFormGroup = null;
    this.dependentsDisplayFormGroup = null;
    this.awardsDisplayFormGroup = null;
    this.publicationsDisplayFormGroup = null;
    this.trainingDisplayFormGroup = null;

  }

  constructor(private fb: FormBuilder,
    private location: Location,
    private toastr: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
  ) {
    this.getDropDownListDate();
    this.createForm();

  }

  change(op, name) {
    if (name === 'nationalityList') {
      var result = this.nationalityList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'employees' || name === 'supervisorName') {
      var result = this.employeeList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'payGrade') {
      var result = this.payGradeList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'currency' || name === 'currencyM') {
      var result = this.currencyList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'education') {
      var result = this.educationList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'licenseType') {
      var result = this.licenseList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'membership') {
      var result = this.memberShipList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'reportingMethod') {
      var result = this.reportingTypes?.filter(item => op?.value?.includes(item.value));
      this.chipList[name] = result;
    } else if (name === 'skill') {
      var result = this.skillList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'language') {
      var result = this.languageList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    }
  }

  remove(id, name) {
    if (name === 'employees') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.personalSelectionFormGroup.value?.employeeNamesList?.filter(item => item !== id);
      this.personalSelectionFormGroup.patchValue({ employeeNamesList: res });
    } else if (name === 'genderList') {
      let res = this.personalSelectionFormGroup.value?.genderList?.filter(item => item !== id);
      this.personalSelectionFormGroup.patchValue({ genderList: res });
    } else if (name === 'martialStatusList') {
      let res = this.personalSelectionFormGroup.value?.martialStatusList?.filter(item => item !== id);
      this.personalSelectionFormGroup.patchValue({ martialStatusList: res });
    } else if (name === 'nationalityList') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.personalSelectionFormGroup.value?.nationalityList?.filter(item => item !== id);
      this.personalSelectionFormGroup.patchValue({ nationalityList: res });
    } else if (name === 'payGrade') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.salarySelectionFormGroup.value?.payGrade?.filter(item => item !== id);
      this.salarySelectionFormGroup.patchValue({ payGrade: res });
    } else if (name === 'currency') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.salarySelectionFormGroup.value?.currency?.filter(item => item !== id);
      this.salarySelectionFormGroup.patchValue({ currency: res });
    } else if (name === 'education') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.educationSelectionFormGroup.value?.education?.filter(item => item !== id);
      this.educationSelectionFormGroup.patchValue({ education: res });
    } else if (name === 'document') {
      let res = this.immigrationSelectionFormGroup.value?.document?.filter(item => item !== id);
      this.immigrationSelectionFormGroup.patchValue({ document: res });
    } else if (name === 'issuedBy') {
      let res = this.immigrationSelectionFormGroup.value?.issuedBy?.filter(item => item !== id);
      this.immigrationSelectionFormGroup.patchValue({ issuedBy: res });
    } else if (name === 'licenseType') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.licensesSelectionFormGroup.value?.licenseType?.filter(item => item !== id);
      this.licensesSelectionFormGroup.patchValue({ licenseType: res });
    } else if (name === 'currencyM') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.membershipSelectionFormGroup.value?.currency?.filter(item => item !== id);
      this.membershipSelectionFormGroup.patchValue({ currency: res });
    } else if (name === 'membership') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.membershipSelectionFormGroup.value?.membership?.filter(item => item !== id);
      this.membershipSelectionFormGroup.patchValue({ membership: res });
    } else if (name === 'workWeek') {
      let res = this.workWeekSelectionFormGroup.value?.workWeek?.filter(item => item !== id);
      this.workWeekSelectionFormGroup.patchValue({ workWeek: res });
    } else if (name === 'reportingMethod') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.value !== id);
      let res = this.supervisorsSelectionFormGroup.value?.reportingMethod?.filter(item => item !== id);
      this.supervisorsSelectionFormGroup.patchValue({ reportingMethod: res });
    } else if (name === 'supervisorName') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.supervisorsSelectionFormGroup.value?.supervisorName?.filter(item => item !== id);
      this.supervisorsSelectionFormGroup.patchValue({ supervisorName: res });
    } else if (name === 'skill') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.skillsSelectionFormGroup.value?.skill?.filter(item => item !== id);
      this.skillsSelectionFormGroup.patchValue({ skill: res });
    } else if (name === 'language') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.languagesSelectionFormGroup.value?.language?.filter(item => item !== id);
      this.languagesSelectionFormGroup.patchValue({ language: res });
    } else if (name === 'fluencyLevel') {
      let res = this.languagesSelectionFormGroup.value?.fluencyLevel?.filter(item => item !== id);
      this.languagesSelectionFormGroup.patchValue({ fluencyLevel: res });
    } else if (name === 'skillL') {
      let res = this.languagesSelectionFormGroup.value?.skill?.filter(item => item !== id);
      this.languagesSelectionFormGroup.patchValue({ skill: res });
    } else if (name === 'subscriptionPaidBy') {
      let res = this.membershipSelectionFormGroup.value?.subscriptionPaidBy?.filter(item => item !== id);
      this.membershipSelectionFormGroup.patchValue({ subscriptionPaidBy: res });
    }

  }


  changeJob(op, name) {
    if (name === 'employmentStatus') {
      var result = this.employeeStatusList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'jobTitle') {
      var result = this.jobTitleList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'jobCategory') {
      var result = this.jobCategoryList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'costCenter') {
      var result = this.costCenterList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'subUnit') {
      var result = this.subUnitList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'location') {
      var result = this.locationList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'workShift') {
      var result = this.workShiftList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'terminationReason') {
      var result = this.terminationList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    } else if (name === 'region') {
      var result = this.regionList?.filter(item => op?.value?.includes(item.id));
      this.chipList[name] = result;
    }
  }

  removeJob(id, name) {
    if (name === 'employmentStatus') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.employmentStatus?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ employmentStatus: res });
    } else if (name === 'jobTitle') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.jobTitle?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ jobTitle: res });
    } else if (name === 'jobCategory') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.jobCategory?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ jobCategory: res });
    } else if (name === 'costCenter') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.costCenter?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ costCenter: res });
    } else if (name === 'subUnit') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.subUnit?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ subUnit: res });
    } else if (name === 'location') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.location?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ location: res });
    } else if (name === 'country') {
      let res = this.jobSelectionFormGroup.value?.country?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ country: res });
    } else if (name === 'workShift') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.workShift?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ workShift: res });
    } else if (name === 'terminationReason') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.terminationReason?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ terminationReason: res });
    } else if (name === 'region') {
      this.chipList[name] = this.chipList[name]?.filter(item => item?.id !== id);
      let res = this.jobSelectionFormGroup.value?.region?.filter(item => item !== id);
      this.jobSelectionFormGroup.patchValue({ region: res });
    }

  }


  ngOnInit() {


    this.route.params.subscribe(params => {

      if (params.reportId) {
        this.reportId = params.reportId;
        if (this.reportId === 'abc') {
          this.reportId = '';

          this.dashboardService.getRequiredFields().subscribe(
            resp => {

              if (resp && resp.data) {

                this.workExperienceFromGroup = resp.data.workExperienceFromGroup;
                this.skillsFromGroup = resp.data.skillsFromGroup;
                this.personalDetailFromGroup = resp.data.personalDetailFromGroup;
                this.membershipsFromGroup = resp.data.membershipsFromGroup;
                this.licenseFromGroup = resp.data.licenseFromGroup;
                this.languagesFromGroup = resp.data.languagesFromGroup;
                this.jobFromGroup = resp.data.jobFromGroup;
                this.immigrationFromGroup = resp.data.immigrationFromGroup;
                this.educationFromGroup = resp.data.educationFromGroup;
                this.directDepositFromGroup = resp.data.directDepositFromGroup;
                this.contactDetailsFromGroup = resp.data.contactDetailsFromGroup;



              }

            }, error => {

              const err = error.error && error.error.msg;
              this.toastr.error(err);
            }
          );


          this.dashboardService.getCustomFieldForReport(this.reportId).subscribe(
            resp => {

              if (resp && resp.data) {


                if (resp.data != undefined) {
                  if (resp.data.length != 0) {
                    for (let custom of resp.data) {
                      if (custom.header == "pe") {

                        custom.fullName = "Personal" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "jb") {
                        custom.fullName = "Job" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "sa") {
                        custom.fullName = "Salary" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "qu") {
                        custom.fullName = "Qualification" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "cd") {
                        custom.fullName = "Contact Details" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "sm") {
                        custom.fullName = "Social Media" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "ec") {
                        custom.fullName = "Emergency Contacts" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "dp") {
                        custom.fullName = "Dependents" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "im") {
                        custom.fullName = "Immigration" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "rt") {
                        custom.fullName = "Report To" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "bi") {
                        custom.fullName = "Bank Info" + '-' + custom.sectionName;
                      }


                      if (custom.sectionSelected) {
                        custom.marked = false;
                        if (custom.customFields != undefined) {
                          if (custom.customFields.length != 0) {
                            for (let cu of custom.customFields) {
                              if (cu.selected) {
                                custom.marked = true;
                                break;
                              }
                              else {
                                custom.marked = false;
                              }
                            }

                            this.customSelectionList.push(custom);
                          }


                        }

                      }
                      else {
                        custom.marked = false;
                        if (custom.customFields != undefined) {
                          if (custom.customFields.length != 0) {
                            this.customSelectionDropDown.push(custom);
                          }

                        }
                      }





                    }
                  }

                }

              }

            }, error => {

              const err = error.error && error.error.msg;
              this.toastr.error(err || 'Error in geeting Selection Custom Fields !');
            }
          );

          this.dashboardService.getDisplayCustomFieldForReport(this.reportId).subscribe(
            resp => {

              if (resp && resp.data) {

                if (resp.data != undefined) {
                  if (resp.data.length != 0) {
                    for (let custom of resp.data) {
                      if (custom.header == "pe") {

                        custom.fullName = "Personal" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "jb") {
                        custom.fullName = "Job" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "sa") {
                        custom.fullName = "Salary" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "qu") {
                        custom.fullName = "Qualification" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "cd") {
                        custom.fullName = "Contact Details" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "sm") {
                        custom.fullName = "Social Media" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "ec") {
                        custom.fullName = "Emergency Contacts" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "dp") {
                        custom.fullName = "Dependents" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "im") {
                        custom.fullName = "Immigration" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "rt") {
                        custom.fullName = "Report To" + '-' + custom.sectionName;
                      }
                      else if (custom.header == "bi") {
                        custom.fullName = "Bank Info" + '-' + custom.sectionName;
                      }

                      if (custom.sectionSelected) {
                        custom.marked = false;
                        if (custom.customFields != undefined) {
                          if (custom.customFields.length != 0) {
                            for (let cu of custom.customFields) {
                              if (cu.selected) {
                                custom.marked = true;
                                break;
                              }
                              else {
                                custom.marked = false;
                              }
                            }

                            this.customDisplayList.push(custom);
                          }


                        }

                      }
                      else {
                        custom.marked = false;
                        if (custom.customFields != undefined) {
                          if (custom.customFields.length != 0) {
                            this.customDisplayDropDown.push(custom);
                          }

                        }
                      }


                    }
                  }

                }



              }

            }, error => {

              const err = error.error && error.error.msg;
              this.toastr.error(err || 'Error in geeting Display Custom Fields !');
            }
          );
        } else {
          this.isLoadingReport = true;
          this.dashboardService.getRequiredFields().subscribe(
            resp => {

              if (resp && resp.data) {

                this.workExperienceFromGroup = resp.data.workExperienceFromGroup;
                this.skillsFromGroup = resp.data.skillsFromGroup;
                this.personalDetailFromGroup = resp.data.personalDetailFromGroup;
                this.membershipsFromGroup = resp.data.membershipsFromGroup;
                this.licenseFromGroup = resp.data.licenseFromGroup;
                this.languagesFromGroup = resp.data.languagesFromGroup;
                this.jobFromGroup = resp.data.jobFromGroup;
                this.immigrationFromGroup = resp.data.immigrationFromGroup;
                this.educationFromGroup = resp.data.educationFromGroup;
                this.directDepositFromGroup = resp.data.directDepositFromGroup;
                this.contactDetailsFromGroup = resp.data.contactDetailsFromGroup;



              }

            }, error => {

              const err = error.error && error.error.msg;
              this.toastr.error(err);
            }
          );
          this.dashboardService.getReportById(this.reportId).subscribe(res => {
            if (res.data) {
              this.addReportNameFormGroup.patchValue({
                reportName: res.data.reportName
              });
              let result = this.employeeList?.filter(item => res.data.personalSelection.employeeNamesList?.includes(item.id));
              this.chipList['employees'] = result;

              let result1 = this.nationalityList?.filter(item => res.data.personalSelection.nationalityList?.includes(item.id));
              this.chipList['nationalityList'] = result1;


              console.log("personalSelectionFormGroup :", this.personalSelectionFormGroup.value);

              this.personalSelectionFormGroup.patchValue({
                ageCheckBox: res.data.personalSelection.ageCheckBox,
                minAge: res.data.personalSelection.minAge,
                maxAge: res.data.personalSelection.maxAge,
                employeeNameCheckBox: res.data.personalSelection.employeeNameCheckBox,
                // employeeNamesList: res.data.personalSelection.employeeNamesList,
                genderCheckBox: res.data.personalSelection.genderCheckBox,
                genderList: res.data.personalSelection.genderList,
                licenseExpiryDateCheckBox: res.data.personalSelection.licenseExpiryDateCheckBox,
                licenseExpiryDateEndDate: res.data.personalSelection.licenseExpiryDateEndDate,
                licenseExpiryDateFromDate: res.data.personalSelection.licenseExpiryDateFromDate,
                martialStatusCheckBox: res.data.personalSelection.martialStatusCheckBox,
                martialStatusList: res.data.personalSelection.martialStatusList,
                nationalityCheckBox: res.data.personalSelection.nationalityCheckBox,
                nationalityList: res.data.personalSelection.nationalityList
              });
              this.personalSelectionFormGroup.value.participantsIds?.map(value => {
                employeesIds.push(value.value);
              });
              var employeesIds = [];
              this.employees?.map(value => {
                employeesIds.push(value.id);
              });
              console.log("employeesIds :", employeesIds);

              this.personalSelectionFormGroup.patchValue({

                employeeNamesList: employeesIds
              });
              console.log("personalSelectionFormGroupppppp :", this.personalSelectionFormGroup.value);

              this.personalDisplayFormGroup.patchValue({
                selectAll: res.data.personalDisplay.selectAll,
                dateOfBirth: res.data.personalDisplay.dateOfBirth,
                driverLicenseNumber: res.data.personalDisplay.driverLicenseNumber,
                employeeFirstName: res.data.personalDisplay.employeeFirstName,
                employeeId: res.data.personalDisplay.employeeId,
                employeeLastName: res.data.personalDisplay.employeeLastName,
                employeeMiddleName: res.data.personalDisplay.employeeMiddleName,
                employeeName: res.data.personalDisplay.employeeName,
                gender: res.data.personalDisplay.gender,
                licenseExpiryDate: res.data.personalDisplay.licenseExpiryDate,
                mainId: res.data.personalDisplay.mainId,
                martialStatus: res.data.personalDisplay.martialStatus,
                nationality: res.data.personalDisplay.nationality,
                otherId: res.data.personalDisplay.otherId,
                includeHeader: res.data.personalDisplay.includeHeader,
              });


              let result2 = this.employeeStatusList?.filter(item => res.data.jobSelection.employmentStatus?.includes(item.id));
              this.chipList['employmentStatus'] = result2;

              let result3 = this.jobTitleList?.filter(item => res.data.jobSelection.jobTitle?.includes(item.id));
              this.chipList['jobTitle'] = result3;

              let result4 = this.jobCategoryList?.filter(item => res.data.jobSelection.jobCategory?.includes(item.id));
              this.chipList['jobCategory'] = result4;

              let result44 = this.costCenterList?.filter(item => res.data.jobSelection.costCenter?.includes(item.id));
              this.chipList['costCenter'] = result44;

              let result5 = this.subUnitList?.filter(item => res.data.jobSelection.subUnit?.includes(item.id));
              this.chipList['subUnit'] = result5;

              let result6 = this.locationList?.filter(item => res.data.jobSelection.location?.includes(item.id));
              this.chipList['location'] = result6;

              let result7 = this.workShiftList?.filter(item => res.data.jobSelection.workShift?.includes(item.id));
              this.chipList['workShift'] = result7;

              let result8 = this.terminationList?.filter(item => res.data.jobSelection.terminationReason?.includes(item.id));
              this.chipList['terminationReason'] = result8;

              let result9 = this.regionList?.filter(item => res.data.jobSelection.region?.includes(item.id));
              this.chipList['region'] = result9;



              this.jobSelectionFormGroup.patchValue({
                country: res.data.jobSelection.country,
                countryCheckBox: res.data.jobSelection.countryCheckBox,
                dateOfPermanencyCheckBox: false,
                dateOfPermanencyEnd: res.data.jobSelection.dateOfPermanencyEnd,
                dateOfPermanencyStart: res.data.jobSelection.dateOfPermanencyStart,
                employmentStatus: res.data.jobSelection.employmentStatus,
                employmentStatusCheckBox: res.data.jobSelection.employmentStatusCheckBox,
                jobCategory: res.data.jobSelection.jobCategory,
                jobCategoryCheckBox: res.data.jobSelection.jobCategoryCheckBox,
                costCenter: res.data.jobSelection.costCenter,
                costCenterCheckBox: res.data.jobSelection.costCenterkBox,
                jobTitle: res.data.jobSelection.jobTitle,
                jobTitleCheckBox: res.data.jobSelection.jobTitleCheckBox,
                joinedDateCheckBox: res.data.jobSelection.joinedDateCheckBox,
                joinedDateEnd: res.data.jobSelection.joinedDateEnd,
                joinedDateStart: res.data.jobSelection.joinedDateStart,
                location: res.data.jobSelection.location,
                locationCheckBox: res.data.jobSelection.locationCheckBox,
                probationDateCheckBox: res.data.jobSelection.probationDateCheckBox,
                probationDateEnd: res.data.jobSelection.probationDateEnd,
                probationDateStart: res.data.jobSelection.probationDateStart,
                servicePeriodCheckBox: res.data.jobSelection.servicePeriodCheckBox,
                servicePeriodMax: res.data.jobSelection.servicePeriodMax,
                servicePeriodMin: res.data.jobSelection.servicePeriodMin,
                subUnit: res.data.jobSelection.subUnit,
                subUnitCheckBox: res.data.jobSelection.subUnitCheckBox,
                terminationDateCheckBox: res.data.jobSelection.terminationDateCheckBox,
                terminationDateEnd: res.data.jobSelection.terminationDateEnd,
                terminationDateStart: res.data.jobSelection.terminationDateStart,
                terminationReason: res.data.jobSelection.terminationReason,
                terminationReasonCheckBox: res.data.jobSelection.terminationReasonCheckBox,
                workShift: res.data.jobSelection.workShift,
                workShiftCheckBox: res.data.jobSelection.workShiftCheckBox,
                regionCheckBox: res.data.jobSelection.regionCheckBox,
                region: res.data.jobSelection.region,
              });
              this.jobDisplayFormGroup.patchValue({
                selectAll: res.data.jobDisplay.selectAll,
                includeHeader: res.data.jobDisplay.includeHeader,
                comment: res.data.jobDisplay.comment,
                contractEndDate: res.data.jobDisplay.contractEndDate,
                contractStartDate: res.data.jobDisplay.contractStartDate,
                creditableService: res.data.jobDisplay.creditableService,
                dateOfPermanency: res.data.jobDisplay.dateOfPermanency,
                effictiveDate: res.data.jobDisplay.effictiveDate,
                employmentStatus: res.data.jobDisplay.employmentStatus,
                fullSubUnitHierarchy: res.data.jobDisplay.fullSubUnitHierarchy,
                jobCategory: res.data.jobDisplay.jobCategory,
                jobTitle: res.data.jobDisplay.jobTitle,
                joinedDate: false,
                location: res.data.jobDisplay.location,
                probationEndDate: res.data.jobDisplay.probationEndDate,
                subUnit: res.data.jobDisplay.subUnit,
                terminationDate: res.data.jobDisplay.terminationDate,
                terminationNote: res.data.jobDisplay.terminationNote,
                terminationReason: res.data.jobDisplay.terminationReason,
                workShift: res.data.jobDisplay.workShift,
                region: res.data.jobDisplay.region
              });

              let result10 = this.payGradeList?.filter(item => res.data.salarySelection.payGrade?.includes(item.id));
              this.chipList['payGrade'] = result10;

              let result11 = this.currencyList?.filter(item => res.data.salarySelection.currency?.includes(item.id));
              this.chipList['currency'] = result11;

              let result12 = this.educationList?.filter(item => res.data.educationSelection.education?.includes(item.id));
              this.chipList['education'] = result12;




              this.salarySelectionFormGroup.patchValue({

                annualBasicPaymentCheckBox: res.data.salarySelection.annualBasicPaymentCheckBox,
                annualBasicPaymentMax: res.data.salarySelection.annualBasicPaymentMax,
                annualBasicPaymentMin: res.data.salarySelection.annualBasicPaymentMin,
                currency: res.data.salarySelection.currency,
                currencyCheckBox: res.data.salarySelection.currencyCheckBox,
                payGrade: res.data.salarySelection.payGrade,
                payGradeCheckBox: res.data.salarySelection.payGradeCheckBox
              });
              this.salaryDisplayFormGroup.patchValue({
                selectAll: res.data.salaryDisplay.selectAll,
                includeHeader: res.data.salaryDisplay.includeHeader,
                annualBasicPayment: res.data.salaryDisplay.annualBasicPayment,
                comment: false,
                currency: res.data.salaryDisplay.currency,
                effictiveDate: res.data.salaryDisplay.effictiveDate,
                payGrade: res.data.salaryDisplay.payGrade
              });
              this.educationDisplayFormGroup.patchValue({
                selectAll: res.data.educationDisplay.selectAll,
                includeHeader: res.data.educationDisplay.includeHeader,
                endDate: res.data.educationDisplay.endDate,
                institute: res.data.educationDisplay.institute,
                level: res.data.educationDisplay.level,
                major_specialization: res.data.educationDisplay.major_specialization,
                score: res.data.educationDisplay.score,
                startDate: res.data.educationDisplay.startDate,
                year: res.data.educationDisplay.yea
              });
              this.educationSelectionFormGroup.patchValue({

                education: res.data.educationSelection.education,
                educationCheckBox: res.data.educationSelection.educationCheckBox,
                endDateCheckBox: res.data.educationSelection.endDateCheckBox,
                endDateFrom: res.data.educationSelection.endDateFrom,
                endDateTo: res.data.educationSelection.endDateTo,
                startDateCheckBox: res.data.educationSelection.startDateCheckBox,
                startDateFrom: res.data.educationSelection.startDateFrom,
                startDateTo: res.data.educationSelection.startDateTo,
                yearCheckBox: res.data.educationSelection.yearCheckBox,
                yearFrom: res.data.educationSelection.yearFrom,
                yearTo: res.data.educationSelection.yearTo
              });
              this.immigrationDisplayFormGroup.patchValue({
                selectAll: res.data.immigrationDisplay.selectAll,
                includeHeader: res.data.immigrationDisplay.includeHeader,
                comments: res.data.immigrationDisplay.comments,
                documentType: res.data.immigrationDisplay.documentType,
                eligibilityReviewDate: res.data.immigrationDisplay.eligibilityReviewDate,
                eligibilityStatus: res.data.immigrationDisplay.eligibilityStatus,
                expiryDate: res.data.immigrationDisplay.expiryDate,
                issuedBy: res.data.immigrationDisplay.issuedBy,
                issuedDate: res.data.immigrationDisplay.issuedDate,
                number: res.data.immigrationDisplay.number
              });
              this.immigrationSelectionFormGroup.patchValue({
                document: res.data.immigrationSelection.document,
                documentCheckBox: res.data.immigrationSelection.documentCheckBox,
                eligibilityReviewDateCheckBox: res.data.immigrationSelection.eligibilityReviewDateCheckBox,
                eligibilityReviewDateFrom: res.data.immigrationSelection.eligibilityReviewDateFrom,
                eligibilityReviewDateto: res.data.immigrationSelection.eligibilityReviewDateto,
                expiryDateCheckBox: res.data.immigrationSelection.expiryDateCheckBox,
                expiryDateFrom: res.data.immigrationSelection.expiryDateFrom,
                expiryDateto: res.data.immigrationSelection.expiryDateto,
                issuedBy: res.data.immigrationSelection.issuedBy,
                issuedByCheckBox: res.data.immigrationSelection.issuedByCheckBox,
                issuedDateCheckBox: res.data.immigrationSelection.issuedDateCheckBox,
                issuedDateFrom: res.data.immigrationSelection.issuedDateFrom,
                issuedDateto: res.data.immigrationSelection.issuedDateto
              });
              this.directDepositeDisplayFormGroup.patchValue({
                selectAll: res.data.directDepositeDisplay.selectAll,
                includeHeader: res.data.directDepositeDisplay.includeHeader,
                accountNumber: res.data.directDepositeDisplay.accountNumber,
                accountType: res.data.directDepositeDisplay.accountType,
                branchLocation: res.data.directDepositeDisplay.branchLocation,
                directDepositAmount: res.data.directDepositeDisplay.directDepositAmount,
                nameOfFinancialInstitute: res.data.directDepositeDisplay.nameOfFinancialInstitute,
                routingNumber: res.data.directDepositeDisplay.routingNumber
              });
              this.directDepositeSelectionFormGroup.patchValue({
                amountCheckBox: res.data.directDepositeSelection.amountCheckBox,
                amountMax: res.data.directDepositeSelection.amountMax,
                amountMin: res.data.directDepositeSelection.amountMin
              });
              this.licensesDisplayFormGroup.patchValue({
                selectAll: res.data.licensesDisplay.selectAll,
                expiryDate: res.data.licensesDisplay.expiryDate,
                includeHeader: res.data.licensesDisplay.includeHeader,
                issuedDate: res.data.licensesDisplay.issuedDate,
                licenseNumber: res.data.licensesDisplay.licenseNumber,
                licenseType: res.data.licensesDisplay.licenseType
              });


              let result13 = this.licenseList?.filter(item => res.data.licensesSelection.licenseType?.includes(item.id));
              this.chipList['licenseType'] = result13;

              let result14 = this.currencyList?.filter(item => res.data.membershipSelection.currency?.includes(item.id));
              this.chipList['currencyM'] = result14;

              let result15 = this.memberShipList?.filter(item => res.data.membershipSelection.membership?.includes(item.id));
              this.chipList['membership'] = result15;




              this.licensesSelectionFormGroup.patchValue({
                expiryDateCheckBox: res.data.licensesSelection.expiryDateCheckBox,
                expiryDateFrom: res.data.licensesSelection.expiryDateFrom,
                expiryDateTo: res.data.licensesSelection.expiryDateTo,
                issuedDateCheckBox: res.data.licensesSelection.issuedDateCheckBox,
                issuedDateFrom: res.data.licensesSelection.issuedDateFrom,
                issuedDateTo: res.data.licensesSelection.issuedDateTo,
                licenseType: res.data.licensesSelection.licenseType,
                licenseTypeCheckBox: res.data.licensesSelection.licenseTypeCheckBox
              });
              this.membershipDisplayFormGroup.patchValue({
                selectAll: res.data.membershipDisplay.selectAll,
                includeHeader: res.data.membershipDisplay.includeHeader,
                currency: res.data.membershipDisplay.currency,
                membership: res.data.membershipDisplay.membership,
                subscriptionAmount: res.data.membershipDisplay.subscriptionAmount,
                subscriptionCommenceDate: res.data.membershipDisplay.subscriptionCommenceDate,
                subscriptionPaidBy: res.data.membershipDisplay.subscriptionPaidBy,
                subscriptionRenewalDate: res.data.membershipDisplay.subscriptionRenewalDate
              });
              this.membershipSelectionFormGroup.patchValue({
                currency: res.data.membershipSelection.currency,
                currencyCheckBox: res.data.membershipSelection.currencyCheckBox,
                membership: res.data.membershipSelection.membership,
                membershipCheckBox: res.data.membershipSelection.membershipCheckBox,
                subscriptionAmountCheckBox: res.data.membershipSelection.subscriptionAmountCheckBox,
                subscriptionAmountMax: res.data.membershipSelection.subscriptionAmountMax,
                subscriptionAmountMin: res.data.membershipSelection.subscriptionAmountMin,
                subscriptionCommenceDateCheckBox: res.data.membershipSelection.subscriptionCommenceDateCheckBox,
                subscriptionCommenceDateFrom: res.data.membershipSelection.subscriptionCommenceDateFrom,
                subscriptionCommenceDateTo: res.data.membershipSelection.subscriptionCommenceDateTo,
                subscriptionPaidBy: res.data.membershipSelection.subscriptionPaidBy,
                subscriptionPaidByCheckBox: res.data.membershipSelection.subscriptionPaidByCheckBox,
                subscriptionRenewalDateCheckBox: res.data.membershipSelection.subscriptionRenewalDateCheckBox,
                subscriptionRenewalDateFrom: res.data.membershipSelection.subscriptionRenewalDateFrom,
                subscriptionRenewalDateTo: res.data.membershipSelection.subscriptionRenewalDateTo
              });
              this.workWeekDisplayFormGroup.patchValue({
                selectAll: res.data.workWeekDisplay.selectAll,
                includeHeader: res.data.workWeekDisplay.includeHeader,
                day: res.data.workWeekDisplay.day,
                dayType: res.data.workWeekDisplay.dayType,
                duration: res.data.workWeekDisplay.duration,
                lunchIn: res.data.workWeekDisplay.lunchIn,
                lunchOut: res.data.workWeekDisplay.lunchOut,
                shiftIn: res.data.workWeekDisplay.shiftIn,
                shiftOut: res.data.workWeekDisplay.shiftOut
              });
              this.workWeekSelectionFormGroup.patchValue({
                workWeek: res.data.workWeekSelection.workWeek,
                workWeekCheckBox: res.data.workWeekSelection.workWeekCheckBox
              });
              this.supervisorsDisplayFormGroup.patchValue({
                selectAll: res.data.supervisorsDisplay.selectAll,
                includeHeader: res.data.supervisorsDisplay.includeHeader,
                firstName: res.data.supervisorsDisplay.firstName,
                lastName: res.data.supervisorsDisplay.lastName,
                reportingMethod: res.data.supervisorsDisplay.reportingMethod,
                supervisorsEmployeeId: res.data.supervisorsDisplay.supervisorsEmployeeId
              });


              let result16 = this.reportingTypes?.filter(item => res.data.supervisorsSelection.reportingMethod?.includes(item.value));
              this.chipList['reportingMethod'] = result16;

              let result17 = this.employeeList?.filter(item => res.data.supervisorsSelection.supervisorName?.includes(item.id));
              this.chipList['supervisorName'] = result17;

              let result18 = this.skillList?.filter(item => res.data.skillsSelection.skill?.includes(item.id));
              this.chipList['skill'] = result18;

              let result19 = this.languageList?.filter(item => res.data.languagesSelection.language?.includes(item.id));
              this.chipList['language'] = result19;





              this.supervisorsSelectionFormGroup.patchValue({
                reportingMethod: res.data.supervisorsSelection.reportingMethod,
                reportingMethodCheckbox: res.data.supervisorsSelection.reportingMethodCheckbox,
                supervisorName: res.data.supervisorsSelection.supervisorName,
                supervisorNameCheckBox: res.data.supervisorsSelection.supervisorNameCheckBox
              });
              this.languageDisplayFormGroup.patchValue({
                selectAll: res.data.languageDisplay.selectAll,
                includeHeader: res.data.languageDisplay.includeHeader,
                comments: res.data.languageDisplay.comments,
                competency: res.data.languageDisplay.competency,
                fluency: res.data.languageDisplay.fluency,
                language: res.data.languageDisplay.language
              });
              this.languagesSelectionFormGroup.patchValue({
                fluencyLevel: res.data.languagesSelection.fluencyLevel,
                fluencyLevelChcekBox: res.data.languagesSelection.fluencyLevelChcekBox,
                language: res.data.languagesSelection.language,
                languageCheckBox: res.data.languagesSelection.languageCheckBox,
                skillCheckBox: res.data.languagesSelection.skillCheckBox
              });
              this.skillsDisplayFormGroup.patchValue({
                selectAll: res.data.skillsDisplay.selectAll,
                includeHeader: res.data.skillsDisplay.includeHeader,
                comments: res.data.skillsDisplay.comments,
                skill: res.data.skillsDisplay.skill,
                yearsOfExperience: res.data.skillsDisplay.yearsOfExperience
              });
              this.skillsSelectionFormGroup.patchValue({
                skill: res.data.skillsSelection.skill,
                skillCheckBox: res.data.skillsSelection.skillCheckBox,
                yearsOfExperienceCheckBox: res.data.skillsSelection.yearsOfExperienceCheckBox,
                yearsOfExperienceMax: res.data.skillsSelection.yearsOfExperienceMax,
                yearsOfExperienceMin: res.data.skillsSelection.yearsOfExperienceMin,
              });
              this.workExperienceDisplayFormGroup.patchValue({
                selectAll: res.data.workExperienceDisplay.selectAll,
                includeHeader: res.data.workExperienceDisplay.includeHeader,
                comment: res.data.workExperienceDisplay.comment,
                company: res.data.workExperienceDisplay.company,
                creditable: res.data.workExperienceDisplay.creditable,
                duration: res.data.workExperienceDisplay.duration,
                from: res.data.workExperienceDisplay.from,
                jobTitle: res.data.workExperienceDisplay.jobTitle,
                to: res.data.workExperienceDisplay.to
              });
              this.subordinatesDisplayFormGroup.patchValue({
                selectAll: res.data.subordinatesDisplay.selectAll,
                includeHeader: res.data.subordinatesDisplay.includeHeader,
                firstName: res.data.subordinatesDisplay.firstName,
                lastName: res.data.subordinatesDisplay.lastName,
                reportingMethod: res.data.subordinatesDisplay.reportingMethod,
                subordinatesEmployeeId: res.data.subordinatesDisplay.subordinatesEmployeeId
              });
              this.fullSubUnitHierarchyLevelsDisplayFormGroup.patchValue({
                selectAll: res.data.fullSubUnitHierarchyLevelsDisplay.selectAll,
                includeHeader: res.data.fullSubUnitHierarchyLevelsDisplay.includeHeader,
                fullSubUnitHierarchyLevels: res.data.fullSubUnitHierarchyLevelsDisplay.fullSubUnitHierarchyLevels
              });
              this.emergencyContactsDisplayFormGroup.patchValue({
                selectAll: res.data.emergencyContactsDisplay.selectAll,
                includeHeader: res.data.emergencyContactsDisplay.includeHeader,
                homeTelephone: res.data.emergencyContactsDisplay.homeTelephone,
                mobile: res.data.emergencyContactsDisplay.mobile,
                name: res.data.emergencyContactsDisplay.name,
                relationship: res.data.emergencyContactsDisplay.relationship,
                workTelephone: res.data.emergencyContactsDisplay.workTelephone
              });
              this.contactDetailsDisplayFormGroup.patchValue({
                selectAll: res.data.contactDetailsDisplay.selectAll,
                includeHeader: res.data.contactDetailsDisplay.includeHeader,
                address: res.data.contactDetailsDisplay.address,
                city: res.data.contactDetailsDisplay.city,
                country: res.data.contactDetailsDisplay.country,
                homeTelephone: res.data.contactDetailsDisplay.homeTelephone,
                mobile: res.data.contactDetailsDisplay.mobile,
                otherEmail: res.data.contactDetailsDisplay.otherEmail,
                state_province: res.data.contactDetailsDisplay.state_province,
                street1: res.data.contactDetailsDisplay.street1,
                street2: res.data.contactDetailsDisplay.street2,
                workEmail: res.data.contactDetailsDisplay.workEmail,
                workTelephone: res.data.contactDetailsDisplay.workTelephone,
                zip_postalCode: res.data.contactDetailsDisplay.zip_postalCode
              });
              this.dependentsDisplayFormGroup.patchValue({
                selectAll: res.data.dependentsDisplay.selectAll,
                includeHeader: res.data.dependentsDisplay.includeHeader,
                dateOfBirth: res.data.dependentsDisplay.dateOfBirth,
                name: res.data.dependentsDisplay.name,
                relationship: res.data.dependentsDisplay.relationship,
                nationality: res.data.dependentsDisplay.nationality
              });
              this.awardsDisplayFormGroup.patchValue({
                selectAll: res.data.awardsDisplay.selectAll,
                awards: res.data.awardsDisplay.awards,
                date: res.data.awardsDisplay.date,
                donor: res.data.awardsDisplay.donor,
                includeHeader: res.data.awardsDisplay.includeHeader,
                sector: res.data.awardsDisplay.sector,
              });
              this.publicationsDisplayFormGroup.patchValue({
                selectAll: res.data.publicationsDisplay.selectAll,
                publication: res.data.publicationsDisplay.publication,
                date: res.data.publicationsDisplay.date,
                journal: res.data.publicationsDisplay.journal,
                includeHeader: res.data.publicationsDisplay.includeHeader,
                sector: res.data.publicationsDisplay.sector,
              });
              this.trainingDisplayFormGroup.patchValue({
                selectAll: res.data.trainingDisplay.selectAll,
                from: res.data.trainingDisplay.from,
                to: res.data.trainingDisplay.to,
                provider: res.data.trainingDisplay.provider,
                includeHeader: res.data.trainingDisplay.includeHeader,
                training: res.data.trainingDisplay.training,
                sector: res.data.trainingDisplay.sector,
              });
              this.personalDisplayAdded = res.data.personalDisplayAdded;
              this.jobDisplayAdded = res.data.jobDisplayAdded;
              this.salaryDisplayAdded = res.data.salaryDisplayAdded;
              this.educationDisplayAdded = res.data.educationDisplayAdded;
              this.immigrationDisplayAdded = res.data.immigrationDisplayAdded;
              this.directDepositeAdded = res.data.directDepositeAdded;
              this.licensesDisplayAdded = res.data.licensesDisplayAdded;
              this.membershipsDisplayAdded = res.data.membershipsDisplayAdded;
              this.supervisorsDisplayAdded = res.data.supervisorsDisplayAdded;
              this.workWeekAdded = res.data.workWeekAdded;
              this.laguageDisplayAdded = res.data.laguageDisplayAdded;
              this.skillsDisplayAdded = res.data.skillsDisplayAdded;
              this.workExperienceDisplayAdded = res.data.workExperienceDisplayAdded;
              this.subordinatesDisplayAdded = res.data.subordinatesDisplayAdded;
              this.fullSubUnitHierarchyLevelsDisplayAdded = res.data.fullSubUnitHierarchyLevelsDisplayAdded;
              this.contactDetailsDisplayAdded = res.data.contactDetailsDisplayAdded;
              this.emergencyContactsDisplayAdded = res.data.emergencyContactsDisplayAdded;
              this.dependentsDisplayAdded = res.data.dependentsDisplayAdded;
              this.directDepositeSelectionAdded = res.data.directDepositeSelectionAdded;
              this.educationSelectionAdded = res.data.educationSelectionAdded;
              this.immigrationSelectionAdded = res.data.immigrationSelectionAdded;
              this.jobSelectionAdded = res.data.jobSelectionAdded;
              this.laguageSelectionAdded = res.data.laguageSelectionAdded;
              this.licensesSelectionAdded = res.data.licensesSelectionAdded;
              this.membershipsSelectionAdded = res.data.membershipsSelectionAdded;
              this.personalSelectionAdded = res.data.personalSelectionAdded;
              this.salarySelectionAdded = res.data.salarySelectionAdded;
              this.skillsSelectionAdded = res.data.skillsSelectionAdded;
              this.supervisorsSelectionAdded = res.data.supervisorsSelectionAdded;
              this.workWeekSelectionAdded = res.data.workWeekSelectionAdded;
              this.awardsDisplayAdded = res.data.awardsDisplayAdded;
              this.publicationsDisplayAdded = res.data.publicationsDisplayAdded;
              this.trainingDisplayAdded = res.data.trainingDisplayAdded;



              this.dashboardService.getCustomFieldForReport(this.reportId).subscribe(
                resp => {

                  if (resp && resp.data) {


                    if (resp.data != undefined) {
                      if (resp.data.length != 0) {
                        for (let custom of resp.data) {
                          if (custom.header == "pe") {

                            custom.fullName = "Personal" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "jb") {
                            custom.fullName = "Job" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "sa") {
                            custom.fullName = "Salary" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "qu") {
                            custom.fullName = "Qualification" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "cd") {
                            custom.fullName = "Contact Details" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "sm") {
                            custom.fullName = "Social Media" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "ec") {
                            custom.fullName = "Emergency Contacts" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "dp") {
                            custom.fullName = "Dependents" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "im") {
                            custom.fullName = "Immigration" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "rt") {
                            custom.fullName = "Report To" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "bi") {
                            custom.fullName = "Bank Info" + '-' + custom.sectionName;
                          }


                          if (custom.sectionSelected) {
                            custom.marked = false;
                            if (custom.customFields != undefined) {
                              if (custom.customFields.length != 0) {
                                for (let cu of custom.customFields) {
                                  if (cu.selected) {
                                    custom.marked = true;
                                    break;
                                  }
                                  else {
                                    custom.marked = false;
                                  }
                                }

                                this.customSelectionList.push(custom);
                              }


                            }

                          }
                          else {
                            custom.marked = false;
                            if (custom.customFields != undefined) {
                              if (custom.customFields.length != 0) {


                                this.customSelectionDropDown.push(custom);
                              }

                            }
                          }


                        }
                      }

                    }

                  }

                }, error => {

                  const err = error.error && error.error.msg;
                  this.toastr.error(err || 'Error in geeting Custom Fields !');
                }
              );

              this.dashboardService.getDisplayCustomFieldForReport(this.reportId).subscribe(
                resp => {

                  if (resp && resp.data) {

                    if (resp.data != undefined) {
                      if (resp.data.length != 0) {
                        for (let custom of resp.data) {
                          if (custom.header == "pe") {

                            custom.fullName = "Personal" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "jb") {
                            custom.fullName = "Job" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "sa") {
                            custom.fullName = "Salary" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "qu") {
                            custom.fullName = "Qualification" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "cd") {
                            custom.fullName = "Contact Details" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "sm") {
                            custom.fullName = "Social Media" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "ec") {
                            custom.fullName = "Emergency Contacts" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "dp") {
                            custom.fullName = "Dependents" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "im") {
                            custom.fullName = "Immigration" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "rt") {
                            custom.fullName = "Report To" + '-' + custom.sectionName;
                          }
                          else if (custom.header == "bi") {
                            custom.fullName = "Bank Info" + '-' + custom.sectionName;
                          }



                          if (custom.sectionSelected) {
                            custom.marked = false;
                            if (custom.customFields != undefined) {
                              if (custom.customFields.length != 0) {
                                for (let cu of custom.customFields) {
                                  if (cu.selected) {
                                    custom.marked = true;
                                    break;
                                  }
                                  else {
                                    custom.marked = false;
                                  }
                                }

                                this.customDisplayList.push(custom);
                              }


                            }

                          }
                          else {
                            custom.marked = false;
                            if (custom.customFields != undefined) {
                              if (custom.customFields.length != 0) {
                                this.customDisplayDropDown.push(custom);
                              }

                            }
                          }


                        }
                      }

                    }



                  }

                }, error => {

                  const err = error.error && error.error.msg;
                  this.toastr.error(err || 'Error in geeting Display Custom Fields !');
                }
              );
              this.isLoadingReport = false;
            }

          }, error => {
            this.toastr.error('Error in getting the Report');
            this.isLoadingReport = false;
          });
        }
      }


    });

  }

  ngAfterViewInit() {


    document.querySelectorAll('.mat-expansion-indicator').forEach(element => {
      element.addEventListener('mouseover', event => {
        const target = event.target as HTMLElement;
        const parent = target.parentElement;
        if (parent.classList.contains('mat-expanded')) {
          parent.querySelector('.btn-collapse').classList.remove('hide');
          parent.querySelector('.btn-collapse').classList.add('display');
          parent.querySelector('.btn-expand').classList.remove('display');
          parent.querySelector('.btn-expand').classList.add('hide');
        } else {
          parent.querySelector('.btn-expand').classList.remove('hide');
          parent.querySelector('.btn-expand').classList.add('display');
          parent.querySelector('.btn-collapse').classList.remove('display');
          parent.querySelector('.btn-collapse').classList.add('hide');
        }
      });
      element.addEventListener('mouseleave', event => {
        const target = event.target as HTMLElement;
        const parent = target.parentElement;
        parent.querySelectorAll('button').forEach(element => element.classList.remove('display'));
        parent.querySelectorAll('button').forEach(element => element.classList.add('hide'));
      })
    });
  }

  createForm() {
    this.addReportNameFormGroup = this.fb.group({
      reportName: ['', Validators.required]
    });
    this.personalSelectionFormGroup = this.fb.group({
      employeeArr: [],
      ageCheckBox: [false],
      minAge: [0],
      maxAge: [100],
      employeeNameCheckBox: [false],
      employeeNamesList: [],
      genderCheckBox: [false],
      genderList: [[]],
      licenseExpiryDateCheckBox: [false],
      licenseExpiryDateEndDate: [],
      licenseExpiryDateFromDate: [],
      martialStatusCheckBox: [false],
      martialStatusList: [[]],
      nationalityCheckBox: [false],
      nationalityList: [[]]
    });
    this.personalDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      dateOfBirth: [false],
      driverLicenseNumber: [false],
      employeeFirstName: [false],
      employeeId: [false],
      employeeLastName: [false],
      employeeMiddleName: [false],
      employeeName: [false],
      gender: [false],
      licenseExpiryDate: [false],
      mainId: [false],
      martialStatus: [false],
      nationality: [false],
      otherId: [false],
    });
    this.jobSelectionFormGroup = this.fb.group({
      country: [[]],
      countryCheckBox: [false],
      dateOfPermanencyCheckBox: [false],
      dateOfPermanencyEnd: [],
      dateOfPermanencyStart: [],
      employmentStatus: [[]],
      employmentStatusCheckBox: [false],
      jobCategory: [[]],
      jobCategoryCheckBox: [false],
      jobTitle: [[]],
      jobTitleCheckBox: [false],
      joinedDateCheckBox: [false],
      joinedDateEnd: [],
      joinedDateStart: [],
      location: [[]],
      locationCheckBox: [false],
      probationDateCheckBox: [false],
      probationDateEnd: [],
      probationDateStart: [],
      servicePeriodCheckBox: [false],
      servicePeriodMax: [100],
      servicePeriodMin: [0],
      subUnit: [[]],
      subUnitCheckBox: [false],
      terminationDateCheckBox: [false],
      terminationDateEnd: [],
      terminationDateStart: [],
      terminationReason: [[]],
      terminationReasonCheckBox: [false],
      workShift: [[]],
      workShiftCheckBox: [false],
      regionCheckBox: [false],
      region: [[]]
    });
    this.jobDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      comment: [false],
      contractEndDate: [false],
      contractStartDate: [false],
      creditableService: [false],
      dateOfPermanency: [false],
      effictiveDate: [false],
      employmentStatus: [false],
      fullSubUnitHierarchy: [false],
      jobCategory: [false],
      jobTitle: [false],
      joinedDate: [false],
      location: [false],
      probationEndDate: [false],
      subUnit: [false],
      terminationDate: [false],
      terminationNote: [false],
      terminationReason: [false],
      workShift: [false],
      region: [false]
    });
    this.salarySelectionFormGroup = this.fb.group({
      annualBasicPaymentCheckBox: [false],
      annualBasicPaymentMax: [100],
      annualBasicPaymentMin: [0],
      currency: [[]],
      currencyCheckBox: [false],
      payGrade: [[]],
      payGradeCheckBox: [false]
    });
    this.salaryDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      annualBasicPayment: [false],
      comment: [false],
      currency: [false],
      effictiveDate: [false],
      payGrade: [false]
    });
    this.educationDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      endDate: [false],
      institute: [false],
      level: [false],
      major_specialization: [false],
      score: [false],
      startDate: [false],
      year: [false]
    });
    this.educationSelectionFormGroup = this.fb.group({
      education: [[]],
      educationCheckBox: [false],
      endDateCheckBox: [false],
      endDateFrom: [],
      endDateTo: [],
      startDateCheckBox: [false],
      startDateFrom: [],
      startDateTo: [],
      yearCheckBox: [false],
      yearFrom: [0],
      yearTo: [0]
    });
    this.immigrationSelectionFormGroup = this.fb.group({
      document: [[]],
      documentCheckBox: [false],
      eligibilityReviewDateCheckBox: [false],
      eligibilityReviewDateFrom: [],
      eligibilityReviewDateto: [],
      expiryDateCheckBox: [false],
      expiryDateFrom: [],
      expiryDateto: [],
      issuedBy: [[]],
      issuedByCheckBox: [false],
      issuedDateCheckBox: [false],
      issuedDateFrom: [],
      issuedDateto: []
    });
    this.immigrationDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      comments: [false],
      documentType: [false],
      eligibilityReviewDate: [false],
      eligibilityStatus: [false],
      expiryDate: [false],
      issuedBy: [false],
      issuedDate: [false],
      number: [false]
    });
    this.directDepositeDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      accountNumber: [false],
      accountType: [false],
      branchLocation: [false],
      directDepositAmount: [false],
      nameOfFinancialInstitute: [false],
      routingNumber: [false]
    });
    this.directDepositeSelectionFormGroup = this.fb.group({
      amountCheckBox: [false],
      amountMax: [100],
      amountMin: [0]
    });
    this.licensesDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      expiryDate: [false],
      issuedDate: [false],
      licenseNumber: [false],
      licenseType: [false]
    });
    this.licensesSelectionFormGroup = this.fb.group({
      expiryDateCheckBox: [false],
      expiryDateFrom: [],
      expiryDateTo: [],
      issuedDateCheckBox: [false],
      issuedDateFrom: [],
      issuedDateTo: [],
      licenseType: [[]],
      licenseTypeCheckBox: [false]
    });
    this.membershipSelectionFormGroup = this.fb.group({
      currency: [[]],
      currencyCheckBox: [false],
      membership: [[]],
      membershipCheckBox: [false],
      subscriptionAmountCheckBox: [false],
      subscriptionAmountMax: [100],
      subscriptionAmountMin: [0],
      subscriptionCommenceDateCheckBox: [false],
      subscriptionCommenceDateFrom: [],
      subscriptionCommenceDateTo: [],
      subscriptionPaidBy: [[]],
      subscriptionPaidByCheckBox: [false],
      subscriptionRenewalDateCheckBox: [false],
      subscriptionRenewalDateFrom: [],
      subscriptionRenewalDateTo: []
    });
    this.membershipDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      currency: [false],
      membership: [false],
      subscriptionAmount: [false],
      subscriptionCommenceDate: [false],
      subscriptionPaidBy: [false],
      subscriptionRenewalDate: [false]
    });
    this.workWeekDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      day: [false],
      dayType: [false],
      duration: [false],
      lunchIn: [false],
      lunchOut: [false],
      shiftIn: [false],
      shiftOut: [false]
    });
    this.workWeekSelectionFormGroup = this.fb.group({
      workWeek: [[]],
      workWeekCheckBox: [false]
    });
    this.supervisorsDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      firstName: [false],
      lastName: [false],
      reportingMethod: [false],
      supervisorsEmployeeId: [false]
    });
    this.supervisorsSelectionFormGroup = this.fb.group({
      reportingMethod: [[]],
      reportingMethodCheckbox: [false],
      supervisorName: [[]],
      supervisorNameCheckBox: [false]
    });
    this.languageDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      comments: [false],
      competency: [false],
      fluency: [false],
      language: [false]
    });
    this.languagesSelectionFormGroup = this.fb.group({
      fluencyLevel: [[]],
      fluencyLevelChcekBox: [false],
      language: [[]],
      languageCheckBox: [false],
      skill: [[]],
      skillCheckBox: [false]
    });
    this.skillsDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      comments: [false],
      skill: [false],
      yearsOfExperience: [false]
    });
    this.skillsSelectionFormGroup = this.fb.group({
      skill: [[]],
      skillCheckBox: [false],
      yearsOfExperienceCheckBox: [false],
      yearsOfExperienceMax: [100],
      yearsOfExperienceMin: [0]
    });
    this.workExperienceDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      comment: [false],
      company: [false],
      creditable: [false],
      duration: [false],
      from: [false],
      jobTitle: [false],
      to: [false]
    });
    this.subordinatesDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      firstName: [false],
      lastName: [false],
      reportingMethod: [false],
      subordinatesEmployeeId: [false]
    });
    this.fullSubUnitHierarchyLevelsDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      fullSubUnitHierarchyLevels: [false]
    });
    this.emergencyContactsDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      homeTelephone: [false],
      mobile: [false],
      name: [false],
      relationship: [false],
      workTelephone: [false]
    });
    this.contactDetailsDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      address: [false],
      city: [false],
      country: [false],
      homeTelephone: [false],
      mobile: [false],
      otherEmail: [false],
      state_province: [false],
      street1: [false],
      street2: [false],
      workEmail: [false],
      workTelephone: [false],
      zip_postalCode: [false]
    });
    this.dependentsDisplayFormGroup = this.fb.group({
      selectAll: [false],
      includeHeader: [false],
      dateOfBirth: [false],
      name: [false],
      relationship: [false],
      nationality: [false]
    });
    this.awardsDisplayFormGroup = this.fb.group({
      selectAll: [false],
      awards: [false],
      date: [false],
      donor: [false],
      includeHeader: [false],
      sector: [false]
    });
    this.publicationsDisplayFormGroup = this.fb.group({
      selectAll: [false],
      date: [false],
      includeHeader: [false],
      sector: [false],
      publication: [false],
      journal: [false]
    });
    this.trainingDisplayFormGroup = this.fb.group({
      selectAll: [false],
      from: [false],
      includeHeader: [false],
      sector: [false],
      to: [false],
      provider: [false],
      training: [false]
    });

    this.personalDisplayAdded = false;
    this.jobDisplayAdded = false;
    this.salaryDisplayAdded = false;
    this.educationDisplayAdded = false;
    this.immigrationDisplayAdded = false;
    this.directDepositeAdded = false;
    this.licensesDisplayAdded = false;
    this.membershipsDisplayAdded = false;
    this.supervisorsDisplayAdded = false;
    this.workWeekAdded = false;
    this.laguageDisplayAdded = false;
    this.skillsDisplayAdded = false;
    this.workExperienceDisplayAdded = false;
    this.subordinatesDisplayAdded = false;
    this.fullSubUnitHierarchyLevelsDisplayAdded = false;
    this.contactDetailsDisplayAdded = false;
    this.emergencyContactsDisplayAdded = false;
    this.dependentsDisplayAdded = false;
    this.awardsDisplayAdded = false;
    this.publicationsDisplayAdded = false;
    this.trainingDisplayAdded = false;
    // for the selection precheck
    this.directDepositeSelectionAdded = false;
    this.educationSelectionAdded = false;
    this.immigrationSelectionAdded = false;
    this.jobSelectionAdded = false;
    this.laguageSelectionAdded = false;
    this.licensesSelectionAdded = false;
    this.membershipsSelectionAdded = false;
    this.personalSelectionAdded = false;
    this.salarySelectionAdded = false;
    this.skillsSelectionAdded = false;
    this.supervisorsSelectionAdded = false;
    this.workWeekSelectionAdded = false;
  }

  getDropDownListDate() {
    this.dashboardService.getEmpQualificationFormList().subscribe(resp => {
      this.skillList = resp && resp[2].data;
      this.languageList = resp && resp[3].data;
    });
    this.dashboardService.getMembershipsList().subscribe(resp => {
      if (resp && resp.data) {
        this.memberShipList = resp.data;
      }
    });
    this.dashboardService.getLicenseList().subscribe(user => {
      if (user) {
        this.licenseList = user.data;
      }
    });
    this.dashboardService.getEducationList().subscribe(res => {
      if (res) {
        this.educationList = res.data;
      }
    });
    this.dashboardService.getPayGradeCurrencyList().subscribe(user => {
      if (user && user.data && user.data.currency) {

        this.currencyList = user.data.currency;
      }
    });
    this.dashboardService.getStructureList().subscribe(resp => {
      if (resp && resp.data) {
        this.subUnitList = resp.data;
      }
    });
    this.dashboardService.getNationalityList(1, 200).subscribe(resp => {
      if (resp && resp.data) {
        this.nationalityList = resp.data;
      }
    });
    this.dashboardService.getEmployeeDetailList().subscribe(res => {
      if (res && res.data) {
        res.data.map(resp => {
          this.employeeList.push({ id: resp.id, name: resp.name });
        });
        this.empCtrl.valueChanges.subscribe(search => {
          startWith(''),
            this.filteredemployees1 = of(this.employeeList.filter(item =>
              item.name.toLowerCase().includes(search)
            ));
        });
      }
    });
    this.dashboardService.getEmployeeDropdown().subscribe(resp => {
      if (resp) {
        this.jobCategoryList = resp[0].data;
        this.locationList = resp[1].data;
        this.costCenterList = resp[2].data;
        this.workShiftList = resp[3].data;
        this.jobTitleList = resp[4].data;
        this.employeeStatusList = resp[5].data;
        this.countryList = resp[6].data;
        this.regionList = resp[7].data;
        this.payrollList = resp[8].data;
        this.payGradeList = resp[9] && resp[9].data;
      }
    });
    this.dashboardService.getEmployeeDropdown2().subscribe(resp => {
      if (resp) {

        this.salaryList = resp[0] && resp[0].data && resp[0].data;
        this.terminationList = resp[1].data;

      }
    });
    this.dashboardService.getReportingList().subscribe(res => {
      if (res && res.data) {
        this.reportingTypes = res && res.data && res.data.map(item => {
          return { label: item.name, value: item.id, item: item };
        });
      }
    });
  }
  onCancelClicked() {
    this.location.back();
  }

  onValueChange() {
    this.personalDisplayAdded = false;
    this.jobDisplayAdded = false;
    this.salaryDisplayAdded = false;
    this.educationDisplayAdded = false;
    this.immigrationDisplayAdded = false;
    this.directDepositeAdded = false;
    this.licensesDisplayAdded = false;
    this.membershipsDisplayAdded = false;
    this.supervisorsDisplayAdded = false;
    this.workWeekAdded = false;
    this.laguageDisplayAdded = false;
    this.skillsDisplayAdded = false;
    this.workExperienceDisplayAdded = false;
    this.subordinatesDisplayAdded = false;
    this.fullSubUnitHierarchyLevelsDisplayAdded = false;
    this.contactDetailsDisplayAdded = false;
    this.emergencyContactsDisplayAdded = false;
    this.dependentsDisplayAdded = false;
    this.awardsDisplayAdded = false;
    this.publicationsDisplayAdded = false;
    this.trainingDisplayAdded = false;
    // for the selection precheck
    this.directDepositeSelectionAdded = false;
    this.educationSelectionAdded = false;
    this.immigrationSelectionAdded = false;
    this.jobSelectionAdded = false;
    this.laguageSelectionAdded = false;
    this.licensesSelectionAdded = false;
    this.membershipsSelectionAdded = false;
    this.personalSelectionAdded = false;
    this.salarySelectionAdded = false;
    this.skillsSelectionAdded = false;
    this.supervisorsSelectionAdded = false;
    this.workWeekSelectionAdded = false;
    if (this.personalDisplayFormGroup.value.dateOfBirth ||
      this.personalDisplayFormGroup.value.driverLicenseNumber ||
      this.personalDisplayFormGroup.value.employeeFirstName ||
      this.personalDisplayFormGroup.value.employeeId ||
      this.personalDisplayFormGroup.value.employeeLastName ||
      this.personalDisplayFormGroup.value.employeeMiddleName ||
      this.personalDisplayFormGroup.value.employeeName ||
      this.personalDisplayFormGroup.value.gender ||
      this.personalDisplayFormGroup.value.licenseExpiryDate ||
      this.personalDisplayFormGroup.value.martialStatus ||
      this.personalDisplayFormGroup.value.nationality ||
      this.personalDisplayFormGroup.value.includeHeader

    ) {
      this.personalDisplayAdded = true;
    }

    if (this.personalSelectionFormGroup.value.ageCheckBox ||
      this.personalSelectionFormGroup.value.employeeNameCheckBox ||
      this.personalSelectionFormGroup.value.genderCheckBox ||
      this.personalSelectionFormGroup.value.licenseExpiryDateCheckBox ||
      this.personalSelectionFormGroup.value.martialStatusCheckBox ||
      this.personalSelectionFormGroup.value.nationalityCheckBox
    ) {
      this.personalSelectionAdded = true;
    }
    if (this.jobDisplayFormGroup.value.comment ||
      this.jobDisplayFormGroup.value.contractEndDate ||
      this.jobDisplayFormGroup.value.contractStartDate ||
      this.jobDisplayFormGroup.value.creditableService ||
      this.jobDisplayFormGroup.value.dateOfPermanency ||
      this.jobDisplayFormGroup.value.effictiveDate ||
      this.jobDisplayFormGroup.value.employmentStatus ||
      this.jobDisplayFormGroup.value.fullSubUnitHierarchy ||
      this.jobDisplayFormGroup.value.jobCategory ||
      this.jobDisplayFormGroup.value.jobTitle ||
      this.jobDisplayFormGroup.value.location ||
      this.jobDisplayFormGroup.value.probationEndDate ||
      this.jobDisplayFormGroup.value.subUnit ||
      this.jobDisplayFormGroup.value.terminationDate ||
      this.jobDisplayFormGroup.value.terminationNote ||
      this.jobDisplayFormGroup.value.terminationReason ||
      this.jobDisplayFormGroup.value.region ||
      this.jobDisplayFormGroup.value.includeHeader ||
      this.jobDisplayFormGroup.value.workShift
    ) {
      this.jobDisplayAdded = true;
    }
    if (this.jobSelectionFormGroup.value.workShiftCheckBox ||
      this.jobSelectionFormGroup.value.terminationReasonCheckBox ||
      this.jobSelectionFormGroup.value.terminationDateCheckBox ||
      this.jobSelectionFormGroup.value.subUnitCheckBox ||
      this.jobSelectionFormGroup.value.servicePeriodCheckBox ||
      this.jobSelectionFormGroup.value.probationDateCheckBox ||
      this.jobSelectionFormGroup.value.jobTitleCheckBox ||
      this.jobSelectionFormGroup.value.joinedDateCheckBox ||
      this.jobSelectionFormGroup.value.jobCategoryCheckBox ||
      this.jobSelectionFormGroup.value.employmentStatusCheckBox ||
      this.jobSelectionFormGroup.value.locationCheckBox ||
      this.jobSelectionFormGroup.value.regionCheckBox ||
      this.jobSelectionFormGroup.value.countryCheckBox
    ) {
      this.jobSelectionAdded = true;
    }
    if (this.salaryDisplayFormGroup.value.annualBasicPayment ||
      this.salaryDisplayFormGroup.value.currency ||
      this.salaryDisplayFormGroup.value.effictiveDate ||
      this.salaryDisplayFormGroup.value.includeHeader ||
      this.salaryDisplayFormGroup.value.payGrade
    ) {
      this.salaryDisplayAdded = true;
    }
    if (this.salarySelectionFormGroup.value.annualBasicPaymentCheckBox ||
      this.salarySelectionFormGroup.value.currencyCheckBox ||
      this.salarySelectionFormGroup.value.payGradeCheckBox
    ) {
      this.salarySelectionAdded = true;
    }
    if (this.educationDisplayFormGroup.value.endDate ||
      this.educationDisplayFormGroup.value.institute ||
      this.educationDisplayFormGroup.value.major_specialization ||
      this.educationDisplayFormGroup.value.score ||
      this.educationDisplayFormGroup.value.startDate ||
      this.educationDisplayFormGroup.value.year ||
      this.educationDisplayFormGroup.value.includeHeader ||
      this.educationDisplayFormGroup.value.level
    ) {
      this.educationDisplayAdded = true;
    }
    if (this.educationSelectionFormGroup.value.educationCheckBox ||
      this.educationSelectionFormGroup.value.endDateCheckBox ||
      this.educationSelectionFormGroup.value.startDateCheckBox ||
      this.educationSelectionFormGroup.value.yearCheckBox
    ) {
      this.educationSelectionAdded = true;
    }
    if (this.immigrationDisplayFormGroup.value.comments ||
      this.immigrationDisplayFormGroup.value.documentType ||
      this.immigrationDisplayFormGroup.value.eligibilityReviewDate ||
      this.immigrationDisplayFormGroup.value.eligibilityStatus ||
      this.immigrationDisplayFormGroup.value.expiryDate ||
      this.immigrationDisplayFormGroup.value.issuedBy ||
      this.immigrationDisplayFormGroup.value.issuedDate ||
      this.immigrationDisplayFormGroup.value.includeHeader ||
      this.immigrationDisplayFormGroup.value.number
    ) {
      this.immigrationDisplayAdded = true;
    }
    if (this.immigrationSelectionFormGroup.value.documentCheckBox ||
      this.immigrationSelectionFormGroup.value.eligibilityReviewDateCheckBox ||
      this.immigrationSelectionFormGroup.value.expiryDateCheckBox ||
      this.immigrationSelectionFormGroup.value.issuedByCheckBox ||
      this.immigrationSelectionFormGroup.value.issuedDateCheckBox
    ) {
      this.immigrationSelectionAdded = true;
    }
    if (this.directDepositeDisplayFormGroup.value.accountNumber ||
      this.directDepositeDisplayFormGroup.value.accountType ||
      this.directDepositeDisplayFormGroup.value.branchLocation ||
      this.directDepositeDisplayFormGroup.value.directDepositAmount ||
      this.directDepositeDisplayFormGroup.value.nameOfFinancialInstitute ||
      this.directDepositeDisplayFormGroup.value.includeHeader ||
      this.directDepositeDisplayFormGroup.value.routingNumber
    ) {
      this.directDepositeAdded = true;
    }
    if (this.directDepositeSelectionFormGroup.value.amountCheckBox) {
      this.directDepositeSelectionAdded = true;
    }
    if (this.licensesDisplayFormGroup.value.expiryDate ||
      this.licensesDisplayFormGroup.value.issuedDate ||
      this.licensesDisplayFormGroup.value.licenseNumber ||
      this.licensesDisplayFormGroup.value.includeHeader ||
      this.licensesDisplayFormGroup.value.licenseType
    ) {
      this.licensesDisplayAdded = true;
    }
    if (this.licensesSelectionFormGroup.value.expiryDateCheckBox ||
      this.licensesSelectionFormGroup.value.issuedDateCheckBox ||
      this.licensesSelectionFormGroup.value.licenseTypeCheckBox
    ) {
      this.licensesSelectionAdded = true;
    }
    if (this.membershipDisplayFormGroup.value.currency ||
      this.membershipDisplayFormGroup.value.membership ||
      this.membershipDisplayFormGroup.value.subscriptionAmount ||
      this.membershipDisplayFormGroup.value.subscriptionCommenceDate ||
      this.membershipDisplayFormGroup.value.includeHeader ||
      this.membershipDisplayFormGroup.value.subscriptionPaidBy ||
      this.membershipDisplayFormGroup.value.subscriptionRenewalDate
    ) {
      this.membershipsDisplayAdded = true;
    }
    if (this.membershipSelectionFormGroup.value.currencyCheckBox ||
      this.membershipSelectionFormGroup.value.membershipCheckBox ||
      this.membershipSelectionFormGroup.value.subscriptionAmountCheckBox ||
      this.membershipSelectionFormGroup.value.subscriptionCommenceDateCheckBox ||
      this.membershipSelectionFormGroup.value.subscriptionPaidByCheckBox ||
      this.membershipSelectionFormGroup.value.subscriptionRenewalDateCheckBox
    ) {
      this.membershipsSelectionAdded = true;
    }
    if (this.workWeekDisplayFormGroup.value.day ||
      this.workWeekDisplayFormGroup.value.dayType ||
      this.workWeekDisplayFormGroup.value.duration ||
      this.workWeekDisplayFormGroup.value.lunchIn ||
      this.workWeekDisplayFormGroup.value.lunchOut ||
      this.workWeekDisplayFormGroup.value.includeHeader ||
      this.workWeekDisplayFormGroup.value.shiftIn ||
      this.workWeekDisplayFormGroup.value.shiftOut
    ) {
      this.workWeekAdded = true;
    }
    if (this.workWeekSelectionFormGroup.value.workWeekCheckBox) {
      this.workWeekSelectionAdded = true;
    }
    if (this.supervisorsDisplayFormGroup.value.includeHeader ||
      this.supervisorsDisplayFormGroup.value.firstName ||
      this.supervisorsDisplayFormGroup.value.lastName ||
      this.supervisorsDisplayFormGroup.value.reportingMethod ||
      this.supervisorsDisplayFormGroup.value.supervisorsEmployeeId
    ) {
      this.supervisorsDisplayAdded = true;
    }
    if (this.supervisorsSelectionFormGroup.value.reportingMethodCheckbox ||
      this.supervisorsSelectionFormGroup.value.supervisorNameCheckBox
    ) {
      this.supervisorsSelectionAdded = true;
    }
    if (this.languageDisplayFormGroup.value.comments ||
      this.languageDisplayFormGroup.value.competency ||
      this.languageDisplayFormGroup.value.includeHeader ||
      this.languageDisplayFormGroup.value.fluency ||
      this.languageDisplayFormGroup.value.language
    ) {
      this.laguageDisplayAdded = true;
    }
    if (this.languagesSelectionFormGroup.value.fluencyLevelChcekBox ||
      this.languagesSelectionFormGroup.value.languageCheckBox ||
      this.languagesSelectionFormGroup.value.skillCheckBox
    ) {
      this.laguageSelectionAdded = true;
    }
    if (this.skillsDisplayFormGroup.value.comments ||
      this.skillsDisplayFormGroup.value.skill ||
      this.skillsDisplayFormGroup.value.includeHeader ||
      this.skillsDisplayFormGroup.value.yearsOfExperience
    ) {
      this.skillsDisplayAdded = true;
    }
    if (this.skillsSelectionFormGroup.value.skillCheckBox ||
      this.skillsSelectionFormGroup.value.yearsOfExperienceCheckBox
    ) {
      this.skillsSelectionAdded = true;
    }
    if (this.workExperienceDisplayFormGroup.value.comment ||
      this.workExperienceDisplayFormGroup.value.company ||
      this.workExperienceDisplayFormGroup.value.creditable ||
      this.workExperienceDisplayFormGroup.value.duration ||
      this.workExperienceDisplayFormGroup.value.includeHeader ||
      this.workExperienceDisplayFormGroup.value.from ||
      this.workExperienceDisplayFormGroup.value.jobTitle ||
      this.workExperienceDisplayFormGroup.value.to
    ) {
      this.workExperienceDisplayAdded = true;
    }
    if (this.subordinatesDisplayFormGroup.value.firstName ||
      this.subordinatesDisplayFormGroup.value.lastName ||
      this.subordinatesDisplayFormGroup.value.reportingMethod ||
      this.subordinatesDisplayFormGroup.value.subordinatesEmployeeId ||
      this.subordinatesDisplayFormGroup.value.includeHeader
    ) {
      this.subordinatesDisplayAdded = true;
    }
    if (this.fullSubUnitHierarchyLevelsDisplayFormGroup.value.fullSubUnitHierarchyLevels ||
      this.fullSubUnitHierarchyLevelsDisplayFormGroup.value.includeHeader
    ) {
      this.fullSubUnitHierarchyLevelsDisplayAdded = true;
    }
    if (this.emergencyContactsDisplayFormGroup.value.homeTelephone ||
      this.emergencyContactsDisplayFormGroup.value.mobile ||
      this.emergencyContactsDisplayFormGroup.value.includeHeader ||
      this.emergencyContactsDisplayFormGroup.value.name ||
      this.emergencyContactsDisplayFormGroup.value.relationship ||
      this.emergencyContactsDisplayFormGroup.value.workTelephone
    ) {
      this.emergencyContactsDisplayAdded = true;
    }
    if (this.contactDetailsDisplayFormGroup.value.address ||
      this.contactDetailsDisplayFormGroup.value.city ||
      this.contactDetailsDisplayFormGroup.value.country ||
      this.contactDetailsDisplayFormGroup.value.homeTelephone ||
      this.contactDetailsDisplayFormGroup.value.mobile ||
      this.contactDetailsDisplayFormGroup.value.otherEmail ||
      this.contactDetailsDisplayFormGroup.value.state_province ||
      this.contactDetailsDisplayFormGroup.value.street1 ||
      this.contactDetailsDisplayFormGroup.value.street2 ||
      this.contactDetailsDisplayFormGroup.value.includeHeader ||
      this.contactDetailsDisplayFormGroup.value.workEmail ||
      this.contactDetailsDisplayFormGroup.value.workTelephone ||
      this.contactDetailsDisplayFormGroup.value.zip_postalCode
    ) {
      this.contactDetailsDisplayAdded = true;
    }
    if (this.dependentsDisplayFormGroup.value.dateOfBirth ||
      this.dependentsDisplayFormGroup.value.name ||
      this.dependentsDisplayFormGroup.value.includeHeader ||
      this.dependentsDisplayFormGroup.value.nationality ||
      this.dependentsDisplayFormGroup.value.relationship
    ) {
      this.dependentsDisplayAdded = true;
    }
    if (this.awardsDisplayFormGroup.value.awards ||
      this.awardsDisplayFormGroup.value.date ||
      this.awardsDisplayFormGroup.value.donor ||
      this.awardsDisplayFormGroup.value.includeHeader ||
      this.awardsDisplayFormGroup.value.sector
    ) {
      this.awardsDisplayAdded = true;
    }
    if (this.publicationsDisplayFormGroup.value.publication ||
      this.publicationsDisplayFormGroup.value.date ||
      this.publicationsDisplayFormGroup.value.journal ||
      this.publicationsDisplayFormGroup.value.includeHeader ||
      this.publicationsDisplayFormGroup.value.sector
    ) {
      this.publicationsDisplayAdded = true;
    }
    if (this.trainingDisplayFormGroup.value.from ||
      this.trainingDisplayFormGroup.value.to ||
      this.trainingDisplayFormGroup.value.provider ||
      this.trainingDisplayFormGroup.value.includeHeader ||
      this.trainingDisplayFormGroup.value.training ||
      this.trainingDisplayFormGroup.value.sector
    ) {
      this.trainingDisplayAdded = true;
    }
  }
  Save() {
    this.isLoadingReport = true;
    this.onValueChange();
    this.personalSelectionFormGroup.value.participantsIds?.map(value => {
      employeesIds.push(value.value);
    });
    var employeesIds = [];
    this.employees?.map(value => {
      employeesIds.push(value.id);
    });
    console.log("employeesIds :", employeesIds);

    this.personalSelectionFormGroup.patchValue({

      employeeNamesList: employeesIds
    });
    console.log("personalSelectionFormGroupppppp :", this.personalSelectionFormGroup.value);

    this.reportName = this.addReportNameFormGroup.value.reportName;
    let displayList = [];
    var displayObject = {
      displayFieldsIds: [],
      includeHeader: false,
      selectAll: false,
      sectionId: ""
    };
    let selectionLIst = [];
    var selectionObjet = {
      sectionId: "",
      selectionPairs: []

    };
    var filedObject = {
      fieldId: "",
      selectionValues: []
    }
    console.log(this.customSelectionList);

    if (this.customSelectionList.length != 0) {
      for (let section of this.customSelectionList) {
        selectionObjet = {
          sectionId: section.sectionId,
          selectionPairs: []

        };



        if (section.customFields != undefined) {
          if (section.customFields.length != 0) {
            for (let custom of section.customFields) {
              if (custom.selected) {
                filedObject = {
                  fieldId: "",
                  selectionValues: []
                }
                if (custom.field != undefined) {
                  filedObject.fieldId = custom.field.id;
                  filedObject.selectionValues = custom.selectedOptions;
                  selectionObjet.selectionPairs.push(filedObject);
                }

              }

            }
          }

        }

        if (selectionObjet.selectionPairs.length != 0) {
          selectionLIst.push(selectionObjet);
        }

      }
    }

    if (this.customDisplayList.length != 0) {
      for (let section of this.customDisplayList) {
        let sectionId = section.sectionId;
        displayObject = {
          displayFieldsIds: [],
          includeHeader: false,
          selectAll: false,
          sectionId: ""
        }
        if (section.selectAll) {
          displayObject.selectAll = true;
        }
        if (section.includeHeader) {
          displayObject.includeHeader = true;
        }
        if (section.customFields != undefined) {
          if (section.customFields.length != 0) {
            for (let custom of section.customFields) {
              if (custom.selected) {
                if (custom.field != undefined) {
                  displayObject.displayFieldsIds.push(custom.field.id);
                  displayObject.sectionId = sectionId;


                }

              }

            }
          }

        }
        if (displayObject.displayFieldsIds.length != 0) {

          displayList.push(displayObject);
        }



      }
    }

    const body = {
      deleted: true,
      id: this.reportId,
      reportName: this.addReportNameFormGroup.value.reportName,
      personalDisplay: this.personalDisplayFormGroup.value,
      personalDisplayAdded: this.personalDisplayAdded,
      personalSelectionAdded: this.personalSelectionAdded,
      personalSelection: this.personalSelectionFormGroup.value,
      jobDisplay: this.jobDisplayFormGroup.value,
      jobDisplayAdded: this.jobDisplayAdded,
      jobSelectionAdded: this.jobSelectionAdded,
      jobSelection: this.jobSelectionFormGroup.value,
      salaryDisplay: this.salaryDisplayFormGroup.value,
      salaryDisplayAdded: this.salaryDisplayAdded,
      salarySelectionAdded: this.salarySelectionAdded,
      salarySelection: this.salarySelectionFormGroup.value,
      educationSelection: this.educationSelectionFormGroup.value,
      educationDisplayAdded: this.educationDisplayAdded,
      educationSelectionAdded: this.educationSelectionAdded,
      educationDisplay: this.educationDisplayFormGroup.value,
      immigrationSelection: this.immigrationSelectionFormGroup.value,
      immigrationDisplayAdded: this.immigrationDisplayAdded,
      immigrationSelectionAdded: this.immigrationSelectionAdded,
      immigrationDisplay: this.immigrationDisplayFormGroup.value,
      directDepositeSelection: this.directDepositeSelectionFormGroup.value,
      directDepositeAdded: this.directDepositeAdded,
      directDepositeSelectionAdded: this.directDepositeSelectionAdded,
      directDepositeDisplay: this.directDepositeDisplayFormGroup.value,
      licensesSelection: this.licensesSelectionFormGroup.value,
      licensesDisplayAdded: this.licensesDisplayAdded,
      licensesSelectionAdded: this.licensesSelectionAdded,
      licensesDisplay: this.licensesDisplayFormGroup.value,
      membershipDisplay: this.membershipDisplayFormGroup.value,
      membershipsDisplayAdded: this.membershipsDisplayAdded,
      membershipsSelectionAdded: this.membershipsSelectionAdded,
      membershipSelection: this.membershipSelectionFormGroup.value,
      workWeekDisplay: this.workWeekDisplayFormGroup.value,
      workWeekAdded: this.workWeekAdded,
      workWeekSelectionAdded: this.workWeekSelectionAdded,
      workWeekSelection: this.workWeekSelectionFormGroup.value,
      supervisorsDisplay: this.supervisorsDisplayFormGroup.value,
      supervisorsDisplayAdded: this.supervisorsDisplayAdded,
      supervisorsSelectionAdded: this.supervisorsSelectionAdded,
      supervisorsSelection: this.supervisorsSelectionFormGroup.value,
      languageDisplay: this.languageDisplayFormGroup.value,
      laguageDisplayAdded: this.laguageDisplayAdded,
      laguageSelectionAdded: this.laguageSelectionAdded,
      languagesSelection: this.languagesSelectionFormGroup.value,
      skillsDisplay: this.skillsDisplayFormGroup.value,
      skillsDisplayAdded: this.skillsDisplayAdded,
      skillsSelectionAdded: this.skillsSelectionAdded,
      skillsSelection: this.skillsSelectionFormGroup.value,
      workExperienceDisplay: this.workExperienceDisplayFormGroup.value,
      workExperienceDisplayAdded: this.workExperienceDisplayAdded,
      subordinatesDisplay: this.subordinatesDisplayFormGroup.value,
      subordinatesDisplayAdded: this.subordinatesDisplayAdded,
      fullSubUnitHierarchyLevelsDisplay: this.fullSubUnitHierarchyLevelsDisplayFormGroup.value,
      fullSubUnitHierarchyLevelsDisplayAdded: this.fullSubUnitHierarchyLevelsDisplayAdded,
      emergencyContactsDisplay: this.emergencyContactsDisplayFormGroup.value,
      emergencyContactsDisplayAdded: this.emergencyContactsDisplayAdded,
      contactDetailsDisplay: this.contactDetailsDisplayFormGroup.value,
      contactDetailsDisplayAdded: this.contactDetailsDisplayAdded,
      dependentsDisplay: this.dependentsDisplayFormGroup.value,
      dependentsDisplayAdded: this.dependentsDisplayAdded,
      awardsDisplayAdded: this.awardsDisplayAdded,
      awardsDisplay: this.awardsDisplayFormGroup.value,
      publicationsDisplayAdded: this.publicationsDisplayAdded,
      publicationsDisplay: this.publicationsDisplayFormGroup.value,
      trainingDisplayAdded: this.trainingDisplayAdded,
      trainingDisplay: this.trainingDisplayFormGroup.value,
      customDisplay: displayList,
      customSelection: selectionLIst
    };
    this.jobDisplayFormGroup.value.dateOfPermanency = false;
    if (!body?.personalDisplayAdded && !body?.jobDisplayAdded && !body?.salaryDisplayAdded && !body?.educationDisplayAdded && !body?.immigrationDisplayAdded && !body?.directDepositeAdded && !body?.licensesDisplayAdded && !body?.membershipsDisplayAdded && !body?.workWeekAdded && !body?.supervisorsDisplayAdded && !body?.laguageDisplayAdded && !body?.skillsDisplayAdded && !body?.workExperienceDisplayAdded && !body?.subordinatesDisplayAdded && !body?.fullSubUnitHierarchyLevelsDisplayAdded && !body?.emergencyContactsDisplayAdded && !body?.contactDetailsDisplayAdded && !body?.dependentsDisplayAdded && !body?.awardsDisplayAdded && !body?.publicationsDisplayAdded && !body?.trainingDisplayAdded) {
      this.isLoadingReport = false;
      this.toastr.error('At least one display field should be selected');
    } else {
      var obj =
      {
        report: body,
        reportId: this.reportId
      }
      this.dashboardService.saveReport(obj).subscribe(res => {
        if (res) {

          ViewReportComponent.res = res.data;
          ViewReportComponent.source = "edit";
          this.reportId = res.data.id;
          this.toastr.success('Report Saved Successfully');
          this.router.navigate([`/pim/reports/` + ViewReportComponent.res.reportId + '/' + this.reportName]);
          this.isLoadingReport = false;
          this.reportId = '';
        }
      }, error => {

        this.isLoadingReport = false;
        this.toastr.error(error.error.msg || 'Error in saving the reports');
      });
    }
  }

  SelectALlChanged($event: MatCheckboxChange, display) {
    if (display === 'personal') {
      this.personalDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        dateOfBirth: $event.checked,
        driverLicenseNumber: $event.checked,
        employeeFirstName: $event.checked,
        employeeId: $event.checked,
        employeeLastName: $event.checked,
        employeeMiddleName: $event.checked,
        employeeName: $event.checked,
        gender: $event.checked,
        licenseExpiryDate: $event.checked,
        mainId: false,
        martialStatus: $event.checked,
        nationality: $event.checked,
        otherId: false,
      });
      this.personalDisplayAdded = $event.checked;
    }
    if (display === 'job') {
      this.jobDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        comment: $event.checked,
        contractEndDate: $event.checked,
        contractStartDate: $event.checked,
        creditableService: $event.checked,
        dateOfPermanency: $event.checked,
        effictiveDate: $event.checked,
        employmentStatus: $event.checked,
        fullSubUnitHierarchy: $event.checked,
        jobCategory: $event.checked,
        jobTitle: $event.checked,
        costCenter: $event.checked,
        location: $event.checked,
        probationEndDate: $event.checked,
        subUnit: $event.checked,
        terminationDate: $event.checked,
        terminationNote: $event.checked,
        terminationReason: $event.checked,
        workShift: $event.checked,
        region: $event.checked
      });
      this.jobDisplayAdded = $event.checked;
    }
    if (display === 'salary') {
      this.salaryDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        annualBasicPayment: $event.checked,
        currency: $event.checked,
        effictiveDate: $event.checked,
        payGrade: $event.checked
      });
      this.salaryDisplayAdded = $event.checked;
    }
    if (display === 'education') {
      this.educationDisplayFormGroup.patchValue({
        selectAll: $event.checked,
        includeHeader: $event.checked,
        endDate: $event.checked,
        institute: $event.checked,
        level: $event.checked,
        major_specialization: $event.checked,
        score: $event.checked,
        startDate: $event.checked,
        year: $event.checked
      });
      this.educationDisplayAdded = $event.checked;
    }
    if (display === 'immigration') {
      this.immigrationDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        comments: $event.checked,
        documentType: $event.checked,
        eligibilityReviewDate: $event.checked,
        eligibilityStatus: $event.checked,
        expiryDate: $event.checked,
        issuedBy: $event.checked,
        issuedDate: $event.checked,
        number: $event.checked
      });
      this.immigrationDisplayAdded = $event.checked;
    }
    if (display === 'directDeposit') {
      this.directDepositeDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        accountNumber: $event.checked,
        accountType: $event.checked,
        branchLocation: $event.checked,
        directDepositAmount: $event.checked,
        nameOfFinancialInstitute: $event.checked,
        routingNumber: $event.checked
      });
      this.directDepositeAdded = $event.checked;
    }
    if (display === 'license') {
      this.licensesDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        expiryDate: $event.checked,
        issuedDate: $event.checked,
        licenseNumber: $event.checked,
        licenseType: $event.checked
      });
      this.licensesDisplayAdded = $event.checked;
    }
    if (display === 'membership') {
      this.membershipDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        currency: $event.checked,
        membership: $event.checked,
        subscriptionAmount: $event.checked,
        subscriptionCommenceDate: $event.checked,
        subscriptionPaidBy: $event.checked,
        subscriptionRenewalDate: $event.checked
      });
      this.membershipsDisplayAdded = $event.checked;
    }
    if (display === 'workWeek') {
      this.workWeekDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        day: $event.checked,
        dayType: $event.checked,
        duration: $event.checked,
        lunchIn: $event.checked,
        lunchOut: $event.checked,
        shiftIn: $event.checked,
        shiftOut: $event.checked
      });
      this.workWeekAdded = $event.checked;
    }
    if (display === 'supervisor') {
      this.supervisorsDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        firstName: $event.checked,
        lastName: $event.checked,
        reportingMethod: $event.checked,
        supervisorsEmployeeId: $event.checked
      });
      this.supervisorsDisplayAdded = $event.checked;
    }

    if (display === 'language') {
      this.languageDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        comments: $event.checked,
        competency: $event.checked,
        fluency: $event.checked,
        language: $event.checked
      });
      this.laguageDisplayAdded = $event.checked;
    }
    if (display === 'skills') {
      this.skillsDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        comments: $event.checked,
        skill: $event.checked,
        yearsOfExperience: $event.checked
      });
      this.skillsDisplayAdded = $event.checked;
    }
    if (display === 'workExperience') {
      this.workExperienceDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        comment: $event.checked,
        company: $event.checked,
        creditable: $event.checked,
        duration: $event.checked,
        from: $event.checked,
        jobTitle: $event.checked,
        to: $event.checked
      });
      this.workExperienceDisplayAdded = $event.checked;
    }
    if (display === 'subordinates') {
      this.subordinatesDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        firstName: $event.checked,
        lastName: $event.checked,
        reportingMethod: $event.checked,
        subordinatesEmployeeId: $event.checked
      });
      this.subordinatesDisplayAdded = $event.checked;
    }
    if (display === 'subUnitFull') {
      this.fullSubUnitHierarchyLevelsDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        fullSubUnitHierarchyLevels: $event.checked
      });
      this.fullSubUnitHierarchyLevelsDisplayAdded = $event.checked;
    }
    if (display === 'emergencyContact') {
      this.emergencyContactsDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        homeTelephone: $event.checked,
        mobile: $event.checked,
        name: $event.checked,
        relationship: $event.checked,
        workTelephone: $event.checked
      });
      this.emergencyContactsDisplayAdded = $event.checked;
    }
    if (display === 'contactDetails') {
      this.contactDetailsDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        address: $event.checked,
        city: $event.checked,
        country: $event.checked,
        homeTelephone: $event.checked,
        mobile: $event.checked,
        otherEmail: $event.checked,
        state_province: $event.checked,
        street1: $event.checked,
        street2: $event.checked,
        workEmail: $event.checked,
        workTelephone: $event.checked,
        zip_postalCode: $event.checked
      });
      this.contactDetailsDisplayAdded = $event.checked;
    }
    if (display === 'dependents') {
      this.dependentsDisplayFormGroup.patchValue({
        includeHeader: $event.checked,
        dateOfBirth: $event.checked,
        name: $event.checked,
        relationship: $event.checked,
        nationality: $event.checked
      });
      this.dependentsDisplayAdded = $event.checked;
    }
    if (display === 'awards') {
      this.awardsDisplayFormGroup.patchValue({
        awards: $event.checked,
        date: $event.checked,
        donor: $event.checked,
        includeHeader: $event.checked,
        sector: $event.checked
      });

      this.awardsDisplayAdded = $event.checked;

    }
    if (display === 'publication') {
      this.publicationsDisplayFormGroup.patchValue({
        date: $event.checked,
        includeHeader: $event.checked,
        sector: $event.checked,
        publication: $event.checked,
        journal: $event.checked
      });
      this.publicationsDisplayAdded = $event.checked;
    }
    if (display === 'training') {
      this.trainingDisplayFormGroup.patchValue({
        from: $event.checked,
        includeHeader: $event.checked,
        sector: $event.checked,
        to: $event.checked,
        provider: $event.checked,
        training: $event.checked
      });
      this.trainingDisplayAdded = $event.checked;
    }
  }

  removeFromDrop(sectionId): void {

    let sectionsList = [];
    for (let section of this.customSelectionDropDown) {
      if (section.sectionId === sectionId) {
        section.sectionSelected = true;
        this.customSelectionList.push(section);
      }
      else {

        sectionsList.push(section);
      }

    }
    this.customSelectionDropDown = sectionsList;


  }

  removeFromDisplayDrop(sectionId): void {

    let sectionsList = [];
    for (let section of this.customDisplayDropDown) {
      if (section.sectionId === sectionId) {
        section.sectionSelected = true;
        this.customDisplayList.push(section);
      }
      else {

        sectionsList.push(section);
      }

    }
    this.customDisplayDropDown = sectionsList;


  }

  addToDisplayDrop(sectionId): void {

    let sectionsList = [];
    for (let section of this.customDisplayList) {
      if (section.sectionId === sectionId) {
        section.sectionSelected = false;
        this.customDisplayDropDown.push(section);
      }
      else {

        sectionsList.push(section);
      }

    }
    this.customDisplayList = sectionsList;


  }

  addToDrop(sectionId): void {

    let sectionsList = [];
    for (let section of this.customSelectionList) {
      if (section.sectionId === sectionId) {
        section.sectionSelected = false;
        this.customSelectionDropDown.push(section);
      }
      else {

        sectionsList.push(section);
      }

    }
    this.customSelectionList = sectionsList;


  }

  removeFromCustomChipList(value: string, obj: any): void {
    let tempList = [];
    for (let custom of this.customSelectionList) {
      for (let cu of custom.customFields) {
        if (cu.selected) {
          if (cu.field != undefined) {
            if (cu.field.id === obj.field.id) {
              if (cu.selectedOptions != undefined && cu.selectedOptions.length != 0) {
                for (let option of cu.selectedOptions) {
                  if (option != value) {
                    tempList.push(option);
                  }
                }

                cu.selectedOptions = tempList;
              }
            }
          }
        }
      }
    }
  }


  changeStatusInSelection(): void {
    for (let custom of this.customSelectionList) {
      if (custom.customFields != undefined) {
        if (custom.customFields.length != 0) {
          for (let cu of custom.customFields) {
            if (cu.selected) {
              custom.marked = true;
              break;
            }
            else {
              custom.marked = false;
            }
          }
        }
      }
    }
  }


  changeStatusInDisplay(): void {
    for (let custom of this.customDisplayList) {
      if (custom.selectAll) {
        custom.marked = true;
        return;
      }
      else if (custom.includeHeader) {
        custom.marked = true;
        return;
      }
      if (custom.customFields != undefined) {
        if (custom.customFields.length != 0) {
          for (let cu of custom.customFields) {
            if (cu.includeHeader) {
              custom.marked = true;
              break;
            }
            if (cu.selected) {
              custom.marked = true;
              break;
            }
            else {
              custom.marked = false;
            }
          }
        }
      }
    }
  }



  checkSelectAll(): void {

    for (let section of this.customDisplayList) {
      if (section.selectAll) {
        section.marked = true;
        section.includeHeader = true;
        for (let custom of section.customFields) {
          custom.selected = true;

        }
      }
      else {
        section.marked = false;
        section.includeHeader = false;
        for (let custom of section.customFields) {
          custom.selected = false;
          custom.includeHeader = false;
        }

      }
    }

  }

  removeEmployee(id): void {
    let res = this.employees?.filter(item => item.id !== id);
    this.employees = res;
    let res2 = []
    if (this.tempEmployees.length != 0) {
      for (let emp of this.tempEmployees) {
        if (emp.id === id) {
          this.employeeList.push(emp);
        }
        else {
          res2.push(emp);
        }

      }
      this.tempEmployees = res2;
    }



  }

  selectedEmployee(event: MatAutocompleteSelectedEvent): void {
    this.employees.push(event.option.value);
    this.tempEmployees.push(event.option.value);
    this.empInput.nativeElement.value = "";
    this.empCtrl.setValue(null);
    let res = this.employeeList?.filter(item => item.id !== event.option.value.id);
    this.employeeList = res;

    this.empInput.nativeElement.blur();
  }

}

<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Payroll Report</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
        <div class="dt-responsive form-material full-width-inputs">
          <form class="full-width-inputs form-material" action="javascript:">
            <div class="row form-group">
              <div class="col-md-3">
                <mat-form-field class="form-element screen-date-picker">
                  <mat-label>Payroll</mat-label>
                  <mat-select [ngModelOptions]="{ standalone: true }" disableOptionCentering
                    [(ngModel)]="selectedPayroll" class="form-control" required="true">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let p of payrolls" [value]="p">
                      {{ p.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="form-element screen-date-picker">
                  <mat-label>Month</mat-label>
                  <mat-select [ngModelOptions]="{ standalone: true }" disableOptionCentering [(ngModel)]="selectedMonth"
                    class="form-control" required="true">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let m of months" [value]="m">
                      {{ m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="form-element screen-date-picker">
                  <mat-label>Year</mat-label>
                  <mat-select [ngModelOptions]="{ standalone: true }" disableOptionCentering [(ngModel)]="selectedYear"
                    class="form-control" required="true">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let y of years" [value]="y">
                      {{ y }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-12 text-right action-buttons multi-btns">
                <button (click)="generate()" class="btn-primary" color="primary" mat-raised-button [disabled]="
                    isLoading || this.selectedMonth == '' || this.selectedYear == '' || this.selectedPayroll == ''
                  " [class.spinner]="generating">
                  <i class="flaticon-diskette"></i>Generate
                </button>
                <button mat-raised-button color="primary" class="btn-primary" (click)="getPayrollReport()"
                  [class.spinner]="isLoading" [disabled]="
                    isLoading || this.selectedMonth == '' || this.selectedYear == '' || this.selectedPayroll == ''
                  ">
                  <i class="flaticon-search"></i>Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </app-card>

      <div class="example-container mat-elevation-z8" *ngIf="hasResult">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <app-card>
          <div class="row top-tools">
            <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
              <span class="export-icon-btn csv" (click)="exportToCSV()"><i class="material-icons">get_app</i><span>{{
                  lang.get("csv") }}</span>
              </span>
              <span class="export-icon-btn pdf" (click)="saveAsPDF()"><i class="material-icons">get_app</i><span>{{
                  lang.get("pdf") }}</span>
              </span>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
              <button class="btn-primary" color="primary" mat-raised-button>
                <i class="flaticon-salary-1"></i>Publish
              </button>
            </div>
          </div>
          <div #payrollContent style="overflow: auto;">
            <mat-table [dataSource]="dataSource" matSort class="payroll">
              <!-- Define dynamic columns -->
              <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef [ngClass]="getHeaderClass(column)"> {{ column }} </th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="column == 'Employee Id' || column == 'Employee Name'">{{ element[column] }}</span>
                  <span *ngIf="column != 'Employee Id' && column != 'Employee Name'">{{
                    element[column] | number : "1.2-2"
                    }}</span>
                </td>
              </ng-container>

              <ng-container *ngFor="let col of footerColumns" [matColumnDef]="col">
                <mat-footer-cell *matFooterCellDef="let row" style="font-weight: bold;">
                  <span *ngIf="col == 'T_Employee Id'">{{ getTotal(col) }}</span>
                  <span *ngIf="col != 'T_Employee Id'">{{ getTotal(col) | number : "1.2-2" }}</span>
                </mat-footer-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
              <mat-footer-row *matFooterRowDef="footerColumns"></mat-footer-row>
            </mat-table>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmDialog let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">Generate Payroll</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">Are you sure you want to replace the existing payroll/payslip?</div>
      <div class="row">
        <div class="action-buttons multi-btns col-md-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()"><i class="flaticon-cancel"></i>Cancel</button>
          <button mat-raised-button class="btn-primary" (click)="confirm()" color="primary">
            <i class="flaticon-trash"></i>Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
import { Injectable } from "@angular/core";
import { elementAt } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";

export interface BadgeItem {
  type: string;
  value: string;
  role: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  role?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  role: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  shortcuts: string;
  label: string;
  infoLabel: string;
  main: MainMenuItems[];
}

let MENUITEMS = [];

@Injectable()
export class MenuItems {
  public permissions: any;
  constructor(
    // private modalService: NgbModal,

    private authService: AuthService
  ) {
    this.permissions = JSON.parse(this.authService.getPermissions());
  }
  public read = false;
  public write = false;
  public update = false;
  public delete = false;

  initMenu(): void {
    MENUITEMS = [];
    MENUITEMS = [
      {
        shortcuts: "My Favorite",
        infoLabel: "My Info",
        label: "Dashboard",
        main: [
          {
            state: "info",
            short_label: "M",
            name: "My Card",
            icon: "flaticon-diskette",
            type: "link",
            role: "Admin,Supervisor,ESS",
          },
          {
            state: "admin",
            short_label: "A",
            name: "Admin",
            type: "sub",
            icon: "flaticon-profile",
            role: "Admin",
            children: [
              {
                state: "user-management",
                name: "User Management",
                type: "sub",
                // role: 'admin',
                children: [
                  {
                    state: "users",
                    name: "Users",
                    // role: 'admin'
                  },
                  {
                    state: "user-roles",
                    name: "User Roles",
                    type: "sub",
                  },
                ],
              },
              {
                state: "job",
                name: "Job",
                type: "sub",
                children: [
                  {
                    state: "job-titles",
                    name: "Job Titles",
                  },
                  {
                    state: "employment-status",
                    name: "Employment Status",
                  },
                  {
                    state: "job-categories",
                    name: "Job Catagories",
                  },
                  {
                    state: "work-shifts",
                    name: "Work Shifts",
                  },
                  {
                    state: "termination-reasons",
                    name: "Termination",
                  },
                  {
                    state: "reporting-methods",
                    name: "Reporting Methods",
                  },
                  {
                    state: "document-template",
                    name: "Template",
                  },
                  {
                    state: "competency-list",
                    name: "Competency List",
                  },
                ],
              },
              {
                state: "organization",
                name: "Organization",
                type: "sub",
                children: [
                  {
                    state: "general-information",
                    name: "General Information",
                  },
                  {
                    state: "locations",
                    name: "Locations",
                  },
                  {
                    state: "region",
                    name: "Region",
                  },
                  {
                    state: "work-week",
                    name: "Work Week",
                  },
                  {
                    state: "holidays",
                    name: "Holidays",
                  },
                  {
                    state: "structure",
                    name: "Structure",
                  },
                  {
                    state: "cost-centers",
                    name: "Cost Centers",
                  },
                  // {
                  //     state: 'eeo-filling',
                  //     name: 'Process EEO Filling',
                  // }
                ],
              },
              // {
              //     state: 'competency',
              //     name: 'Competencies',
              //     type: 'sub',
              //     children: [
              //         {
              //             state: 'competency-list',
              //             name: 'Competency List',
              //         }
              //     ]
              // },
              {
                state: "qualification",
                name: "Qualification",
                type: "sub",
                children: [
                  {
                    state: "skills",
                    name: "Skills",
                  },
                  // {
                  //     state: 'language',
                  //     name: 'Language',
                  // },
                  {
                    state: "education",
                    name: "Education",
                  },
                  {
                    state: "membership",
                    name: "Membership",
                  },
                  {
                    state: "licenses",
                    name: "Licenses",
                  },
                ],
              },
              // {
              //     state: 'nationalities',
              //     name: 'Nationalities'
              // },
              {
                state: "announcement",
                name: "Announcements",
                type: "sub",
                children: [
                  {
                    state: "news",
                    name: "News",
                  },
                  {
                    state: "documents",
                    name: "Documents",
                  },
                  {
                    state: "document_categories",
                    name: "Document Categories",
                  },
                ],
              },
              {
                state: "configurations",
                name: "Configuration",
                type: "sub",
                children: [
                  {
                    state: "directory-configurations",
                    name: "Directory",
                  },
                  // {
                  //     state: 'email-setting',
                  //     name: 'Email Settings'
                  // },
                  {
                    state: "email-notifications",
                    name: "Email Notifications",
                  },
                  {
                    state: "localization",
                    name: "Localization",
                  },
                  {
                    state: "authentication-setting",
                    name: "Authentication",
                  },
                  // {
                  //     state: 'canned-reports',
                  //     name: 'Canned Reports'
                  // }
                ],
              },
              // {
              //     state: 'company',
              //     name: 'Company'
              // },
              {
                state: "audit-trial",
                name: "Audit Trail",
              },
            ],
          },
          {
            state: "pim",
            short_label: "P",
            name: "PDM",
            type: "sub",
            icon: "flaticon-users",
            role: "Admin,ESS",
            children: [
              {
                state: "employees",
                name: "Employee list",
              },
              {
                state: "add-employee",
                name: "Add Employee",
                role: "Admin",
              },
              {
                state: "reports",
                name: "Reports",
                role: "Admin",
              },
              {
                state: "bulk-update",
                name: "Bulk Management",
                role: "Admin",
              },
              // {
              //   state: 'manage-data',
              //   name: 'Manage Data',
              //   type: 'sub',
              //   children: [
              //     {
              //       state: 'bulk-update',
              //       name: 'Bulk Update',
              //     },
              //     // {
              //     //   state: 'employee-data-export',
              //     //   name: 'Employee Data Export',
              //     // }
              //   ]
              // },
              {
                state: "configuration",
                name: "Configuration",
                type: "sub",
                role: "Admin",
                children: [
                  {
                    state: "optional-fields",
                    name: "Optional Fields",
                  },
                  {
                    state: "custom-fields",
                    name: "Custom Fields",
                  },
                  // {
                  //     state: 'reporting-methods',
                  //     name: 'Reporting Methods',
                  // },
                  {
                    state: "add-employee-wizard",
                    name: "Required Fields",
                  },
                  // {
                  //     state: 'directory-configurations',
                  //     name: 'Directory Configurations'
                  // }
                  // {
                  //     state: 'termination-reasons',
                  //     name: 'Termination Reasons',
                  // },
                  // {
                  //     state: 'document-template',
                  //     name: 'Document Template',
                  // }
                ],
              },
            ],
          },
          {
            state: "leave",
            short_label: "L",
            name: "Leave",
            type: "sub",
            icon: "flaticon-calendar",
            role: "Admin,Supervisor,ESS",
            children: [
              {
                state: "apply-leave",
                name: "Apply Leave",
              },
              {
                state: "my-leave",
                name: "My Leave",
              },
              {
                state: "leave-list",
                name: "Leave List",
                // role: 'Admin'
              },
              {
                state: "leave-calendar",
                name: "Leave Calendar",
              },
              {
                state: "assign-leave",
                name: "Assign Leave",
                role: "Admin",
              },
              {
                state: "bulk-assign",
                name: "Bulk Assign",
                role: "Admin",
              },
              {
                state: "entitlement",
                name: "Entitlement",
                type: "sub",
                children: [
                  {
                    state: "add-entitlement",
                    name: "Add Entitlement",
                    role: "Admin"
                  },
                  {
                    state: "entitlement-list",
                    name: "Employees Entitlement",
                  },
                  {
                    state: "my-entitlements",
                    name: "My Entitlement",
                  },
                ],
              },
              {
                state: "reports",
                name: "Reports",
                type: "sub",
                children: [
                  // {
                  //     state: 'bradfactor-threshold',
                  //     name: 'Bradford factor threshold',
                  // },
                  {
                    state: "leave-usage",
                    name: "Leave Usage",
                    role: "Admin",
                  },
                  // {
                  //     state: 'leave-percentage',
                  //     name: 'Leave Percentage',
                  // },
                  {
                    state: "my-leave-usage",
                    name: "My Leave Usage",
                  },
                ],
              },
              {
                state: "configure",
                name: "Configure",
                type: "sub",
                role: "Admin",
                children: [
                  {
                    state: "leave-type",
                    name: "Leave Type",
                  },
                  {
                    state: "leave-period",
                    name: "Leave Period",
                  },
                  // {
                  //   state: "working-weekend",
                  //   name: "Working Weekend",
                  // },
                  // {
                  //     state: 'bft',
                  //     name: 'Brand Factor Threshold'
                  // },
                  // {
                  //   state: "notifications",
                  //   name: "Notifications",
                  // },
                  {
                    state: "calendar-config",
                    name: "Leave Calendar",
                  },
                ],
              },
            ],
          },
          {
            state: "attendance",
            short_label: "AT",
            name: "Attendance",
            type: "sub",
            icon: "flaticon-clock-time",
            role: "Admin,Supervisor,ESS",
            children: [
              {
                state: "my-records",
                name: "My Records",
              },
              {
                state: "punch-in",
                name: "Punch In/Punch Out",
              },
              {
                state: "employee-records",
                name: "Employee Records",
              },
              {
                state: "configuration",
                name: "Configuration",
                role: "Admin",
              },

              // {
              //     state: 'data-upload',
              //     name: 'Data Upload',
              // },
              {
                state: "reports",
                name: "Reports",
                type: "sub",
                children: [
                  {
                    state: "attendance-summary",
                    name: "Summary",
                  },
                  {
                    state: "export",
                    name: "Daily/Detailed",
                  },
                  // {
                  //     state: 'monthly-attendance',
                  //     name: 'Monthly Attendance',
                  //     role:'Admin'
                  // },
                  // {
                  //     state: 'my-monthly-attendance',
                  //     name: 'My Monthly Attendance'
                  // }
                ],
              },
            ],
          },
          {
            state: "time",
            short_label: "T",
            name: "Project Management",
            type: "sub",
            icon: "flaticon-calendar-1",
            role: "Admin,Supervisor,ESS",
            children: [
              {
                state: "time-sheets",
                name: "Timesheets",
                type: "sub",
                children: [
                  {
                    state: "my-timesheets",
                    name: "My Timesheets",
                  },
                  {
                    state: "employee-sheet",
                    name: "Employee Timesheets",
                  },
                  {
                    state: "export-to-csv",
                    name: "Export Timesheets",
                  },
                ],
              },
              {
                state: "project-time",
                name: "Report",
                // state: 'reports',
                // name: 'Reports',
                // type: 'sub',
                // children: [
                //     {
                //         state: 'project-time',
                //         name: 'Project Time',
                //     },
                //     {
                //         state: 'employee-report',
                //         name: 'Employee Project Time',
                //     },
                //     {
                //         state: 'pay-hours-report',
                //         name: 'Pay Hours Report',
                //     }
                // ]
              },
              {
                state: "configuration",
                name: "Configuration",
                type: "sub",
                role: "Admin",
                children: [
                  {
                    state: "customers",
                    name: "Customers",
                  },
                  {
                    state: "projects",
                    name: "Projects",
                  },
                  {
                    state: "common-activities",
                    name: "Tasks",
                  },
                ],
              },
              // {
              //     state: 'configuration',
              //     name: 'Configuration',
              //     type: 'sub',
              //     children: [
              //         {
              //             state: 'timesheet-periods',
              //             name: 'Timesheet Periods',
              //         }
              //     ]
              // }
            ],
          },
          {
            state: "discipline",
            short_label: "D",
            name: "Discipline",
            type: "sub",
            icon: "flaticon-folder",
            role: "Admin",
            children: [
              {
                state: "disciplinary-cases",
                name: "Disciplinary Cases",
              },
              {
                state: "my-actions",
                name: "Disciplinary actions",
              },
              //     {
              //       state: 'configurations',
              //     name: 'Configurations',
              //     type: 'sub',
              //     children: [
              //       {
              //         state: 'document-template',
              //         name: 'Document Template',
              //       }
              //     ]
              //   }
            ],
          },
          {
            state: "training",
            short_label: "T",
            name: "Training",
            type: "sub",
            icon: "flaticon-presentation-2",
            role: "Admin,Supervisor,ESS",
            children: [
              {
                state: "courses",
                name: "Courses List",
              },
              {
                state: "my-courses",
                name: "My Courses",
              },
              {
                state: "training-courses",
                name: "My Training Courses",
              },
              // {
              //     state: 'my-courses',
              //     name: 'My Courses'
              // },
              // {
              //     state: 'training-courses',
              //     name: 'My Training Courses'
              // },
              // {
              //     state: 's',
              //     name : 'Sessions',
              //     type: 'sub',
              //     children: [
              //         {
              //             state: 'sessions',
              //             name : 'Session List',
              //         },
              //         {
              //             state: 'participating-session',
              //             name: 'My Session'
              //         },
              //         {
              //             state: 'participating-courses',
              //             name: 'My Training Session'
              //         },
              //     ]
              // },
              // {
              //     state: 'participating-session',
              //     name: 'My Session'
              // },
              // {
              //     state: 'participating-courses',
              //     name: 'My Training Session'
              // },
              // {
              //     state: 'report',
              //     name: 'Report',
              //     type: 'sub',
              //     children: [
              //         {
              //             state: 'participating-session',
              //             name: 'Employees Report'
              //         },
              //         {
              //             state: 'participating-courses',
              //             name: 'Trainers Report'
              //         }
              //     ]
              // }
              {
                state: "training-report",
                name: "Report",
              },
            ],
          },
          {
              state: 'payroll',
              short_label: 'P',
              name: 'Payroll',
              type: 'sub',
              icon: 'flaticon-wallet',
              role: 'Admin',
              children: [
                  {
                      state: 'payslip',
                      name: 'Payslip'
                  },
                  {
                    state: 'payrollReport',
                    name: 'Payroll Report'
                  },
                  {
                    state: "configuration",
                    name: "Configuration",
                    type: "sub",
                    role: "Admin",
                    children: [
                      {
                        state: "payroll",
                        name: "Taxation",
                      },
                      {
                        state: "salary-component",
                        name: "Salary Components",
                      },
                      {
                        state: "pay-grades",
                        name: "Pay Grades",
                      },
                      // {
                      //   state: "attendance-policy",
                      //   name: "Attendance Policy",
                      // }
                    ],
                  },
              ]
          },
          // {
          //   state: "recruitment",
          //   short_label: "O",
          //   name: "Recruitment",
          //   type: "sub",
          //   icon: "flaticon-research",
          //   role: "Admin",
          //   children: [
          //     {
          //       state: "vacancies",
          //       name: "Vacancies",
          //     },
          //     {
          //       state: "candidates",
          //       name: "Candidates",
          //     },
          //     {
          //       state: "recruitment-configuration",
          //       name: "Configuration",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "vacancy-template",
          //           name: "Vacancy Template",
          //         },
          //         {
          //           state: "question-pool",
          //           name: "Question Pool",
          //         },
          //         {
          //           state: "interview-templates",
          //           name: "Interview Templates",
          //         },
          //         {
          //           state: "standard-tests",
          //           name: "Standard Tests",
          //         },
          //         {
          //           state: "analytics",
          //           name: "Analytics",
          //         },
          //       ],
          //     },
          //     {
          //       state: "vacancy-succession",
          //       name: "Vacancy Successions",
          //     },
          //   ],
          // },
          // {
          //   state: "performance",
          //   short_label: "O",
          //   name: "Performance",
          //   type: "sub",
          //   icon: "flaticon-speed",
          //   role: "Admin,Supervisor,ESS",
          //   children: [
          //     {
          //       state: "tracker",
          //       name: "Employee Trackers",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "tracker-list",
          //           name: "Tracker List",
          //         },
          //         {
          //           state: "my_tracker",
          //           name: "My Trackers",
          //         },
          //         {
          //           state: "manage_tracker",
          //           name: "Manage Trackers",
          //         },
          //       ],
          //     },
          //     {
          //       state: "goal",
          //       name: "Goals",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "my_goal",
          //           name: "My Goals",
          //         },
          //         {
          //           state: "goal_list",
          //           name: "Gaol List",
          //         },
          //         {
          //           state: "goal_library",
          //           name: "Goal Library",
          //         },
          //       ],
          //     },
          //     {
          //       state: "appraisal",
          //       name: "Appraisals",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "appraisal-list",
          //           name: "Appraisal List",
          //         },
          //         {
          //           state: "my-appraisal",
          //           name: "My Appraisal",
          //         },
          //         {
          //           state: "appraisal-cycle",
          //           name: "Appraisal Cycle",
          //         },
          //       ],
          //     },
          //     {
          //       state: "cfg",
          //       name: "Configuration",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "appraisal-cfg",
          //           name: "Appraisal",
          //         },
          //       ],
          //     },
          //     {
          //       state: "competency",
          //       name: "Competencies",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "competency_mapping",
          //           name: "Competency Mapping",
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //     state: 'succession-and-development',
          //     short_label: 'S',
          //     name: 'Development',
          //     type: 'sub',
          //     icon: 'flaticon-bar-chart',
          //     role: 'Admin,Supervisor,ESS',
          //     children: [
          //         {
          //             state: 'individuals-development-plans',
          //             name: 'Individuals Development Plans'
          //         },
          //         {
          //             state: 'my-idp',
          //             name: 'My IDP'
          //         },
          //         {
          //             state: 'box-matrix',
          //             name: '9 Box Matrix'
          //         },
          //         {
          //             state: 'configuration',
          //             name: 'Configuration',
          //             type: 'sub',
          //             children: [
          //                 {
          //                     state: 'box-matrix-configuration',
          //                     name: '9 Box Matrix'
          //                 }
          //             ]
          //         }
          //     ]
          // },
          {
            state: "onboarding",
            short_label: "O",
            name: "Onboarding",
            type: "sub",
            icon: "flaticon-onboarding",
            role: "Admin",
            children: [
              {
                state: "task-type",
                name: "Task Types",
              },
              {
                state: "occasions",
                name: "Occasions",
              },
              {
                state: "my-occasions",
                name: "My Occasions",
              },

              {
                state: "my-tasks",
                name: "My Tasks",
              },
              {
                state: "employee-tasks",
                name: "Employee Task",
              },
              {
                state: "reports",
                name: "Reports",
                type: "sub",
                children: [
                  {
                    state: "event-progress",
                    name: "Occasion Progress",
                  },
                  {
                    state: "participant-progress",
                    name: "Participant Progress",
                  },
                ],
              },
            ],
          },
          // {
          //   state: "expense",
          //   short_label: "E",
          //   name: "Expense",
          //   type: "sub",
          //   icon: "flaticon-cost",
          //   role: "Admin,Supervisor,ESS",
          //   children: [
          //     {
          //       state: "configuration",
          //       name: "Configuration",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "currency-exchange-rates",
          //           name: "Currency Exchange Rates",
          //         },
          //         {
          //           state: "eligibility-groups",
          //           name: "Eligibility Groups",
          //         },
          //         {
          //           state: "expense-types",
          //           name: "Expense Types",
          //         },
          //       ],
          //     },
          //     {
          //       state: "travel-requests",
          //       name: "Travel Requests",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "employee-travel-request",
          //           name: "Employee Travel Request",
          //         },
          //         {
          //           state: "my-travel-request",
          //           name: "My Travel Request",
          //         },
          //       ],
          //     },
          //     {
          //       state: "claims",
          //       name: "Claims",
          //       type: "sub",
          //       children: [
          //         {
          //           state: "employee-claims",
          //           name: "Employee Claims",
          //         },
          //         {
          //           state: "my-claims",
          //           name: "My Claims",
          //         },
          //       ],
          //     },
          //     {
          //       state: "expense-reports",
          //       name: "Reports",
          //     },
          //   ],
          // },
          // {
          //   state: 'report',
          //   short_label: 'R',
          //   name: 'Report Catalog',
          //   icon: 'icon-home',
          //   type: 'link'
          // },
          {
            state: "assets",
            name: "Asset Management",
            icon: "flaticon-portfolio",
            role: "Admin,Supervisor,ESS",
            type: "sub",
            children: [
              {
                state: "assets",
                name: "View assets",
              },
              {
                state: "brands",
                name: "Brands",
              },
              {
                state: "categories",
                name: "Categories",
              },
              {
                state: "vendors",
                name: "Vendors",
              },
            ],
          },
          {
            state: "more",
            short_label: "O",
            name: "More",
            type: "sub",
            icon: "flaticon-ellipsis",
            role: "Admin,Supervisor,ESS",
            children: [
              {
                state: "announcement",
                name: "Announcement",
                type: "sub",
                children: [
                  {
                    state: "publish-news",
                    name: "News",
                  },
                  {
                    state: "publish-documents",
                    name: "Documents",
                  },
                ],
              },
              {
                state: "dashboard",
                name: "Dashboard",
              },
              {
                state: "directory",
                name: "Directory",
              },
              {
                state: "preferences",
                name: "Preferences",
              },
              {
                state: "view-org-chart",
                name: "Organization Chart",
              },
              // {
              //     state: 'buzz',
              //     name: 'Buzz'
              // },
              // {
              //     state: 'report',
              //     name: 'Report Catalog',
              // }
            ],
          },
          // {
          //     state: 'maintenance',
          //     short_label: 'M',
          //     name: 'Maintenance',
          //     type: 'sub',
          //     icon: 'flaticon-settings',
          //     role: 'Admin',
          //     children: [
          //         {
          //             state: 'maintenance',
          //             name: 'Purge Records',
          //             type: 'sub',
          //             children: [
          //                 {
          //                     state: 'purge_employee_records',
          //                     name: 'Employee Records',
          //                 },
          //                 {
          //                     state: 'purge_candidate_records',
          //                     name: 'Candidate Records',
          //                 }
          //             ]
          //         }
          //     ]
          // },
        ],
      },
    ];
  }

  checkModule(module) {
    try {
      this.permissions = JSON.parse(this.authService.getPermissions());

      let temp: boolean = false;
      if (this.permissions != undefined && this.permissions != null) {
        for (let p of this.permissions) {
          if (p.id === module) {
            temp = true;
          }
        }
      }

      return temp;
    } catch (err) {
      return undefined;
    }
  }

  excuteSecurityInAdmin(): void {
    if (!this.checkModule("admin")) {
      let temp = [];
      for (let menu of MENUITEMS[0].main) {
        if (menu.state != "admin") {
          temp.push(menu);
        }
      }
      MENUITEMS[0].main = temp;
    } else {
      let job: boolean = this.checkPermission("admin", "ad_jb");
      let userMangment: boolean = this.checkPermission("admin", "ad_um");
      let org: boolean = this.checkPermission("admin", "ad_og");
      let qula: boolean = this.checkPermission("admin", "ad_ql");
      let audit: boolean = this.checkPermission("admin", "ad_at");
      let annouce: boolean = this.checkPermission("admin", "ad_an");
      if (job) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "admin") {
            for (let child of menu.children) {
              if (child.state != "job") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (userMangment) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "admin") {
            for (let child of menu.children) {
              if (child.state != "user-management") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (org) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "admin") {
            for (let child of menu.children) {
              if (child.state != "organization") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (qula) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "admin") {
            for (let child of menu.children) {
              if (child.state != "qualification") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (audit) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "admin") {
            for (let child of menu.children) {
              if (child.state != "audit-trial") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (annouce) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "admin") {
            for (let child of menu.children) {
              if (child.state != "announcement") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
    }
  }

  excuteSecurityInPdm(): void {
    if (!this.checkModule("pdm")) {
      let temp = [];
      for (let menu of MENUITEMS[0].main) {
        if (menu.state != "pim") {
          temp.push(menu);
        }
      }
      MENUITEMS[0].main = temp;
    } else {
      let pdm_addEmployee: boolean = this.checkPermission("pdm", "pd_ae");
      let pdm_reports: boolean = this.checkPermission("pdm", "pd_rp");
      let pdm_bulk: boolean = this.checkPermission("pdm", "pd_bm");
      let pdm_config: boolean = this.checkPermission("pdm", "pd_cf");
      let pdm_employeeList: boolean = this.checkPermission("pdm", "pd_el");

      if (pdm_addEmployee) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "pim") {
            for (let child of menu.children) {
              if (child.state != "add-employee") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (pdm_reports) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "pim") {
            for (let child of menu.children) {
              if (child.state != "reports") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (pdm_bulk) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "pim") {
            for (let child of menu.children) {
              if (child.state != "bulk-update") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (pdm_config) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "pim") {
            for (let child of menu.children) {
              if (child.state != "configuration") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (pdm_employeeList) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "pim") {
            for (let child of menu.children) {
              if (child.state != "employees") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
    }
  }

  excuteSecurityInLeave(): void {
    if (!this.checkModule("leave")) {
      let temp = [];
      for (let menu of MENUITEMS[0].main) {
        if (menu.state != "leave") {
          temp.push(menu);
        }
      }
      MENUITEMS[0].main = temp;
    } else {
      let leave_applyLeave: boolean = this.checkPermission("leave", "le_apl");
      let leave_assignLeave: boolean = this.checkPermission("leave", "le_asl");
      let leave_bulkAssign: boolean = this.checkPermission("leave", "le_bas");
      let leave_Entitlments: boolean = this.checkPermission("leave", "le_ets");
      let leave_Calendar: boolean = this.checkPermission("leave", "le_lc");
      let leave_reports: boolean = this.checkPermission("leave", "le_rpt");
      let leave_List: boolean = this.checkPermission("leave", "le_ll");
      let leave_myList: boolean = this.checkPermission("leave", "le_ml");
      let leave_Config: boolean = this.checkPermission("leave", "le_ccnf");

      if (leave_applyLeave) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "apply-leave") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (leave_assignLeave) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "assign-leave") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (leave_bulkAssign) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "bulk-assign") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (leave_Entitlments) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "entitlement") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (leave_Calendar) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "leave-calendar") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (leave_reports) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "reports") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (leave_List) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "leave-list") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (leave_myList) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "my-leave") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (leave_Config) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "leave") {
            for (let child of menu.children) {
              if (child.state != "configure") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
    }
  }

  excuteSecurityInMore(): void {
    if (!this.checkModule("more")) {
      let temp = [];
      for (let menu of MENUITEMS[0].main) {
        if (menu.state != "more") {
          temp.push(menu);
        }
      }
      MENUITEMS[0].main = temp;
    } else {
      let more_dashBoard: boolean = this.checkPermission("more", "mr_dsh");
      let more_Directory: boolean = this.checkPermission("more", "mr_dir");
      let more_pref: boolean = this.checkPermission("more", "mr_pre");
      let more_aasets: boolean = this.checkPermission("more", "mr_ast");
      let more_org: boolean = this.checkPermission("more", "mr_oc");
      let more_annoc: boolean = this.checkPermission("more", "mr_an");
      if (more_org) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "more") {
            for (let child of menu.children) {
              if (child.state != "org") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (more_annoc) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "more") {
            for (let child of menu.children) {
              if (child.state != "announcement") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (more_dashBoard) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "more") {
            for (let child of menu.children) {
              if (child.state != "dashboard") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (more_aasets) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "more") {
            for (let child of menu.children) {
              if (child.state != "assets") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
      if (more_Directory) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "more") {
            for (let child of menu.children) {
              if (child.state != "directory") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (more_pref) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "more") {
            for (let child of menu.children) {
              if (child.state != "preferences") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
    }
  }

  excuteSecurityInDiscipline(): void {
    if (!this.checkModule("discipline")) {
      let temp = [];
      for (let menu of MENUITEMS[0].main) {
        if (menu.state != "discipline") {
          temp.push(menu);
        }
      }
      MENUITEMS[0].main = temp;
    } else {
      let discipline_cases: boolean = this.checkPermission("discipline", "ds_dc");
      let discipline_actions: boolean = this.checkPermission("discipline", "ds_da");

      if (discipline_cases) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "discipline") {
            for (let child of menu.children) {
              if (child.state != "disciplinary-cases") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }

      if (discipline_actions) {
        let temp = [];
        for (let menu of MENUITEMS[0].main) {
          if (menu.state === "discipline") {
            for (let child of menu.children) {
              if (child.state != "my-actions") {
                temp.push(child);
              }
            }
            menu.children = temp;
          }
        }
      }
    }
  }
  checkPermission(module, tabs): boolean | undefined {
    try {
      this.permissions = JSON.parse(this.authService.getPermissions());

      console.log(this.permissions);
      this.read = false;
      this.write = false;
      this.delete = false;
      this.update = false;
      if (this.permissions != undefined && this.permissions != null) {
        for (let p of this.permissions) {
          if (p.id === module) {
            if (p.modules != undefined) {
              for (let tab of p.modules) {
                if (tab.id === tabs) {
                  for (let move of tab.permits) {
                    if (move.id === "u") {
                      this.update = true;
                    } else if (move.id === "r") {
                      this.read = true;
                    } else if (move.id === "c") {
                      this.write = true;
                    } else if (move.id === "d") {
                      this.delete = true;
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (!this.read) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return undefined;
    }
  }
  getAll(): Menu[] {
    this.initMenu();
    this.excuteSecurityInAdmin();
    this.excuteSecurityInPdm();
    this.excuteSecurityInLeave();
    this.excuteSecurityInMore();
    this.excuteSecurityInDiscipline();
    return MENUITEMS;
  }

  public getControlMenu(): Menu[] {
    
    const controlMenu = [
      {
        shortcuts: "",
        infoLabel: "",
        label: "Client",
        main: [
          {
            state: "list",
            short_label: "L",
            name: "List of clients",
            icon: "flaticon-user-1",
            type: "link",
            role: "Admin"
          },
          {
            state: "add",
            short_label: "A",
            name: "Add Client",
            icon: "flaticon-diskette",
            type: "link",
            role: "Admin"
          }
        ]
      }
    ];

    return controlMenu;
  }
}

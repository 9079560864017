<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">{{ lang.get("payroll") }}</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body no-title">
  <div class="row">
    <div class="col-sm-12">
      <div class="row top-tools">
        <div class="col-xs-12 col-sm-12 col-sm-12 col-md-6 left-tools">
          <span class="export-icon-btn csv" (click)="exportToCsv()"><i class="material-icons">get_app</i><span>{{
              lang.get("csv") }}</span>
          </span>
          <span class="export-icon-btn pdf" (click)="saveAsPdf()"><i class="material-icons">get_app</i><span>{{
              lang.get("pdf") }}</span>
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
          <div class="relative-position search-filter-con">
            <span class="icon material-icons ng-tns-c114-0"> search </span>
            <mat-form-field>
              <input matInput #inputSearch type="search" [(ngModel)]="filterQuery" maxlength="40"
                (ngModelChange)="onModelChange(filterQuery)" class="form-control input-sm full-data-search" />
            </mat-form-field>
            <span class="mytooltip cus-left tooltip-effect-4" [hidden]="!write">
              <span class="tooltip-item">
                <button mat-mini-fab class="btn btn-primary light add-rounded margin-button-05" (click)="addNew()">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">{{ lang.get("new") }}</span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <app-card [title]="'Job Title'">
        <div class="dt-responsive" *ngIf="dataSource">
          <div class="table-responsive table hrm-table-style-1 full-data-table table-cols-4"
            style="overflow: visible !important">
            <mat-card-content>
              <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef>
                    <a [matMenuTriggerFor]="menu" class="check-all">
                      <span class="material-icons"> more_horiz </span>
                    </a>

                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onSelectAllClicked()">{{ lang.get("select all") }}</button>
                      <button (click)="onDeSelectAllClicked()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        {{ lang.get("deselect all") }}
                      </button>
                      <button (click)="onDeleteSelected()" *ngIf="selectedCheckBoxList.length > 0" mat-menu-item>
                        {{ lang.get("delete selected") }}
                      </button>
                    </mat-menu>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <mat-checkbox #matCheckbox (change)="onCheckBoxChange($event, row.id)"
                      value="{{ row.id }}"></mat-checkbox>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("name") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="country">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("country") }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{ row.countryName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <mat-header-cell [hidden]="!update" *matHeaderCellDef>{{ lang.get("edit") }}</mat-header-cell>
                  <mat-cell [hidden]="!update" *matCellDef="let row">
                    <span class="mytooltip tooltip-effect-4">
                      <span class="tooltip-item"><i class="single-icon-click fa fa-pencil"
                          (click)="edit(row.id)"></i></span>
                      <span class="tooltip-content clearfix">
                        <span class="tooltip-text">{{ lang.get("edit") }}</span>
                      </span>
                    </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"> </mat-row>
              </mat-table>
              <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
          </div>
          <div class="error-msg" *ngIf="!isLoading && err">{{ err }}</div>
        </div>
      </app-card>
    </div>
  </div>
</div>

<ng-template #add let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">
        <span *ngIf="!isEdit">{{ lang.get("add") }}</span>
        <span *ngIf="isEdit">{{ lang.get("edit") }}</span>
        {{ lang.get("payroll") }}
      </h4>
    </div>

    <div class="mat-modal-body">
      <app-loading *ngIf="submitLoading" [isLoading]="submitLoading"></app-loading>

      <form class="form-material full-width-inputs" action="javascript:" [formGroup]="crudForm" *ngIf="!submitLoading">
        <div class="row form-group">
          <div class="col-sm-6">
            <mat-form-field class="form-element">
              <mat-label>{{ lang.get("name") }}</mat-label>
              <input pattern="*" type="text" matInput class="form-control" maxlength="40" required="" id="name"
                formControlName="name" name="footer-email" />
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <app-country-dropdown [title]="lang.get('country')" required="true"
              [(selectedCountry)]="selectedCountry"></app-country-dropdown>
          </div>
        </div>
      </form>

      <fieldset [hidden]="!showAddTax">
        <legend>Add Tax</legend>

        <form class="form-material full-width-inputs" action="javascript:" [formGroup]="addTaxForm"
          #taxAddForm="ngForm">
          <div class="row form-group">
            <div class="col-sm-3">
              <mat-form-field class="form-element">
                <mat-label>{{ lang.get("name") }}</mat-label>
                <input type="text" matInput class="form-control" maxlength="40" required="true" id="name"
                  formControlName="name" />
              </mat-form-field>
            </div>

            <div class="col-sm-3">
              <mat-form-field class="form-element">
                <mat-label>{{ lang.get("from") }}</mat-label>
                <input type="number" matInput class="form-control" required="true" id="from" formControlName="from" />
              </mat-form-field>
            </div>

            <div class="col-sm-3">
              <mat-form-field class="form-element">
                <mat-label>{{ lang.get("to") }}</mat-label>
                <input type="number" matInput class="form-control" id="to" formControlName="to" />
              </mat-form-field>
            </div>

            <div class="col-sm-3">
              <mat-form-field class="form-element">
                <mat-label>{{ lang.get("value") }}</mat-label>
                <input type="number" matInput class="form-control" required="true" id="to" formControlName="value" />
              </mat-form-field>
            </div>

            <div class="col-sm-6">
              <mat-checkbox class="form-control" id="Percentage" formControlName="percentage">{{
                lang.get("percentage")
                }}</mat-checkbox>
            </div>

            <div class="action-buttons multi-btns col-sm-6 text-right">
              <button (click)="addTax()" class="btn-danger" mat-raised-button>Add</button>
            </div>
          </div>
        </form>
      </fieldset>

      <fieldset [hidden]="submitLoading">
        <legend>Tax Segments</legend>
        <mat-table [dataSource]="taxDataSource" *ngIf="taxList.length > 0">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>{{ lang.get("name") }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="from">
            <mat-header-cell *matHeaderCellDef>{{ lang.get("from") }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.from }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="to">
            <mat-header-cell *matHeaderCellDef>{{ lang.get("to") }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.to }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef>{{ lang.get("value") }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.value }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="percentage">
            <mat-header-cell *matHeaderCellDef>{{ lang.get("percentage") }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{ row.percentage ? "YES" : "NO" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef>{{ lang.get("delete") }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mytooltip tooltip-effect-4">
                <span class="tooltip-item"><i class="flaticon-cancel-1" (click)="deleteTax(row)"></i></span>
                <span class="tooltip-content clearfix">
                  <span class="tooltip-text">{{ lang.get("delete") }}</span>
                </span>
              </span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="taxColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: taxColumns"> </mat-row>
        </mat-table>

        <div class="error-msg" *ngIf="taxList.length == 0">No Tax configuration</div>
      </fieldset>

      <div class="row my-2 mx-2">
        <div class="action-buttons multi-btns col-sm-4">
          <button (click)="showAddTax = true" class="btn-primary" color="primary" mat-raised-button
            [disabled]="submitLoading">
            Add Tax
          </button>
        </div>
        <div class="action-buttons multi-btns col-sm-8 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()" [disabled]="submitLoading">
            <i class="flaticon-cancel"></i>Cancel
          </button>
          <button (click)="save()" class="btn-primary" color="primary" mat-raised-button [disabled]="submitLoading">
            <i class="flaticon-diskette"></i>Save
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #delete let-modal>
  <div mat-dialog-actions>
    <div class="mat-modal-header">
      <h4 class="mat-title-modal">{{ lang.get("delete") }} {{ lang.get("payroll") }}</h4>
    </div>
    <div class="mat-modal-body">
      <div class="text-left are-u">{{ lang.get("areyousure") }}</div>
      <div class="row">
        <div class="multi-btns col-sm-12 text-right">
          <button mat-raised-button class="btn-danger" (click)="close()">
            <i class="flaticon-cancel"></i>{{ lang.get("cancel") }}
          </button>
          <button mat-raised-button class="btn-primary" color="primary" (click)="deleteObjects()">
            <i class="flaticon-trash"></i>{{ lang.get("delete") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div class="ng-star-inserted header-page" id="header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">
                                <span *ngIf="this.reportId === ''">Add Report</span>
                                <span *ngIf="this.reportId != ''">
                                    {{this.addReportNameFormGroup.value.reportName}}</span>
                            </h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>
<app-loading [isLoading]="this.isLoadingReport"></app-loading>
<mat-card style="margin: 0px" [hidden]="isLoadingReport" class="reports-layouts">
    <mat-card-title>
        <span *ngIf="this.reportId === ''">Add Report</span>
        <span *ngIf="this.reportId != ''">Edit Report</span>
    </mat-card-title>
    <mat-card-content>
        <mat-horizontal-stepper [linear]="isLinear" #stepper>
            <mat-step [stepControl]="addReportNameFormGroup" #step1>
                <form class="form-material full-width-inputs" [formGroup]="addReportNameFormGroup">
                    <ng-template matStepLabel>Report Name</ng-template>
                    <mat-form-field style="width: 40%;" class="form-element">
                        <mat-label>Report Name</mat-label>
                        <input class="form-control" maxlength="40" matInput formControlName="reportName" required>
                    </mat-form-field>
                    <div class="row">
                        <div class="action-buttons multi-btns col-sm-12 text-right">
                            <button mat-raised-button class="btn-danger reset-btn" (click)="onCancelClicked()"><i
                                    class="flaticon-cancel-1"></i>Cancel
                            </button>
                            <button mat-raised-button class="btn-danger" color="primary"
                                [disabled]="this.addReportNameFormGroup.invalid" matStepperNext><i
                                    class="flaticon-right-arrow"></i> Next
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="personalSelectionFormGroup" label="Selection Criteria">
                <mat-card>
                    <div class="row top-tools" *ngIf="this.customSelectionDropDown.length > 0">
                        <div class="col-xs-12 col-sm-12 col-md-6 left-tools">

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                            <div class="relative-position search-filter-con">

                                <span class="mytooltip cus-left tooltip-effect-4">
                                    <span class="tooltip-item">



                                        <button class="btn-primary" color="primary" mat-raised-button
                                            [matMenuTriggerFor]="menu">
                                            Add Additional Criterias<mat-icon>arrow_drop_down</mat-icon>
                                        </button>
                                        <!-- #enddocregion mat-menu-trigger-for -->
                                        <mat-menu #menu="matMenu">
                                            <button (click)="removeFromDrop(customField.sectionId)"
                                                *ngFor="let customField of this.customSelectionDropDown" mat-menu-item>
                                                {{customField.fullName}}
                                            </button>
                                        </mat-menu>

                                    </span>

                                </span>

                            </div>
                        </div>
                    </div>
                    <br><br>
                    <mat-card-content>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.personalSelectionAdded" class="accordion-head"><i
                                            class="flaticon-profile-1"></i> Personal Information</span>
                                    <span *ngIf="this.personalSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-profile-1"></i> Personal Information</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="personalSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Personal Information</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #employeeNameCheck class="form-control"
                                                        color="primary" formControlName="employeeNameCheckBox"
                                                        (change)="onValueChange()">
                                                        Employee Name
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!personalDetailFromGroup.dateOfBirthAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #ageCheck class="form-control" color="primary"
                                                        formControlName="ageCheckBox" (change)="onValueChange()">
                                                        Age
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!personalDetailFromGroup.genderAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #genderCheck class="form-control" color="primary"
                                                        formControlName="genderCheckBox" (change)="onValueChange()">
                                                        Gender
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!personalDetailFromGroup.licenseExpiryDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #licenseExpiryDateCheck class="form-control"
                                                        color="primary" formControlName="licenseExpiryDateCheckBox"
                                                        (change)="onValueChange()">
                                                        License Expiry Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!personalDetailFromGroup.martialStatusAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #martialStatusCheck class="form-control"
                                                        color="primary" formControlName="martialStatusCheckBox"
                                                        (change)="onValueChange()">
                                                        Martial Status
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #nationalityCheck class="form-control" color="primary"
                                                        formControlName="nationalityCheckBox"
                                                        (change)="onValueChange()">
                                                        Nationality
                                                    </mat-checkbox>
                                                </div>

                                            </div>











                                        </section>
                                    </section>
                                    <ng-container *ngIf="employeeNameCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Employees</h2>
                                            <div class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <!-- <mat-form-field class="form-element">
                                                            <mat-label for="des">Employee</mat-label>
                                                            <mat-select style="height: min-content"
                                                                (selectionChange)="change($event , 'employees')"
                                                                disableOptionCentering class="form-control"
                                                                formControlName="employeeNamesList" multiple>
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.employees"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'employees')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container>
                                                                    <mat-option *ngFor="let employee of employeeList"
                                                                        [value]="employee.id">{{employee.name}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                        </mat-form-field> -->


                                                        <mat-form-field class="form-element">
                                                            <mat-label for="des">Employee</mat-label>
                                                            <mat-chip-list #chipList6 formArrayName="employeeNamesList">
                                                                <mat-chip *ngFor="let emp of employees"
                                                                    [selectable]="true" [removable]="true"
                                                                    (removed)="removeEmployee(emp.id)">
                                                                    {{ emp?.name }}
                                                                    <mat-icon matChipRemove>cancel</mat-icon>
                                                                </mat-chip>
                                                                <input placeholder="type for hints..."
                                                                    formContorlName="employeeNamesList" #empInput
                                                                    [formControl]="empCtrl" [matAutocomplete]="auto1"
                                                                    [matChipInputFor]="chipList6"
                                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                    [matChipInputAddOnBlur]="addOnBlur">
                                                            </mat-chip-list>
                                                            <mat-autocomplete #auto1="matAutocomplete"
                                                                (optionSelected)="selectedEmployee($event)">
                                                                <mat-option *ngFor="let emp of filteredemployees1 
                                                                | async" [value]="emp">
                                                                    {{emp.name}}
                                                                </mat-option>
                                                            </mat-autocomplete>

                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="ageCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Age</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field>
                                                        <mat-label>Min Age</mat-label>
                                                        <input formControlName="minAge" maxlength="40" matInput required
                                                            type="number">
                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>Max Age</mat-label>
                                                        <input formControlName="maxAge" maxlength="40" matInput required
                                                            type="number">
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>

                                    <ng-container *ngIf="genderCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Gender</h2>
                                            <div class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select style="height: min-content"
                                                                disableOptionCentering class="form-control"
                                                                formControlName="genderList" multiple>
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of personalSelectionFormGroup?.value?.genderList"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping , 'genderList')">
                                                                            {{ topping }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <mat-option value="Male">Male</mat-option>
                                                                <mat-option value="Female">Female</mat-option>
                                                                <mat-option value="Other">Other</mat-option>
                                                            </mat-select>
                                                            <mat-label for="des">Gender</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </ng-container>

                                    <ng-container *ngIf="licenseExpiryDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">License Expiry Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker1" class="form-control"
                                                            formControlName="licenseExpiryDateFromDate" matInput>
                                                        <mat-datepicker-toggle [for]="picker1" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker1></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker" class="form-control"
                                                            formControlName="licenseExpiryDateEndDate" matInput>
                                                        <mat-datepicker-toggle [for]="picker" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>

                                    <ng-container *ngIf="martialStatusCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Martial Status</h2>
                                            <div class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-label>Maritial Status</mat-label>
                                                            <mat-select style="height: min-content"
                                                                disableOptionCentering class="form-control"
                                                                formControlName="martialStatusList" multiple>
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of personalSelectionFormGroup?.value?.martialStatusList"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping , 'martialStatusList')">
                                                                            {{ topping }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <mat-option value="Single">Single</mat-option>
                                                                <mat-option value="Married">Married</mat-option>
                                                                <mat-option value="Other">Other</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </ng-container>

                                    <ng-container *ngIf="nationalityCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Nationality</h2>
                                            <div class="checkboxes">

                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'nationalityList')"
                                                                class="form-control" formControlName="nationalityList"
                                                                multiple>
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.nationalityList"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'nationalityList')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of nationalityList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Nationality</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.jobSelectionAdded" class="accordion-head"><i
                                            class="flaticon-briefcase"></i> Job</span>
                                    <span *ngIf="this.jobSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-briefcase"></i> Job</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="jobSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Job</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #joinedDateCheck class="form-control" color="primary"
                                                        formControlName="joinedDateCheckBox" (change)="onValueChange()">
                                                        Effective Date
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!jobFromGroup.employmentStatusAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #employmentStatusCheck class="form-control"
                                                        color="primary" formControlName="employmentStatusCheckBox"
                                                        (change)="onValueChange()">
                                                        Employment Status
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!jobFromGroup.jobTitleAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #jobTitleCheck class="form-control" color="primary"
                                                        formControlName="jobTitleCheckBox" (change)="onValueChange()">
                                                        Job Title
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!jobFromGroup.jobCategoryAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #jobCategoryCheck class="form-control" color="primary"
                                                        formControlName="jobCategoryCheckBox"
                                                        (change)="onValueChange()">
                                                        Job Category
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!jobFromGroup.costCenterAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #CostCenterCheck class="form-control" color="primary"
                                                        formControlName="CostCenterCheckBox"
                                                        (change)="onValueChange()">
                                                        Cost Center
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #subUnitCheck class="form-control" color="primary"
                                                        formControlName="subUnitCheckBox" (change)="onValueChange()">
                                                        Sub Unit
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #locationCheck class="form-control" color="primary"
                                                        formControlName="locationCheckBox" (change)="onValueChange()">
                                                        Location
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #countryCheck class="form-control" color="primary"
                                                        formControlName="countryCheckBox" (change)="onValueChange()">
                                                        Country
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!jobFromGroup.workShiftAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #workShiftCheck class="form-control" color="primary"
                                                        formControlName="workShiftCheckBox" (change)="onValueChange()">
                                                        Work Shift
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #servicePeriodCheck class="form-control"
                                                        color="primary" formControlName="servicePeriodCheckBox"
                                                        (change)="onValueChange()">
                                                        Service Periods(year)
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #terminationDateCheck class="form-control"
                                                        color="primary" formControlName="terminationDateCheckBox"
                                                        (change)="onValueChange()">
                                                        Termination Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #terminationReasonCheck class="form-control"
                                                        color="primary" formControlName="terminationReasonCheckBox"
                                                        (change)="onValueChange()">
                                                        Termination Reason
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!jobFromGroup.probationEndDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #probationDateCheck class="form-control"
                                                        color="primary" formControlName="probationDateCheckBox"
                                                        (change)="onValueChange()">
                                                        Probation End Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #regionJobCheckbox class="form-control"
                                                        color="primary" formControlName="regionCheckBox"
                                                        (change)="onValueChange()">
                                                        Region
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                    <ng-container *ngIf="joinedDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Effective Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker3" class="form-control"
                                                            formControlName="joinedDateStart" matInput>
                                                        <mat-datepicker-toggle [for]="picker3" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker3></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker4" class="form-control"
                                                            formControlName="joinedDateEnd" matInput>
                                                        <mat-datepicker-toggle [for]="picker4" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker4></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="employmentStatusCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Employment Status</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="employmentStatus" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'employmentStatus')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.employmentStatus"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'employmentStatus')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of employeeStatusList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Employment Status</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="jobTitleCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">job Title</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="jobTitle" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'jobTitle')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.jobTitle"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'jobTitle')">
                                                                            {{ topping?.title }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of jobTitleList">
                                                                    <mat-option value="{{n.id}}">{{n.title}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">job Title</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="jobCategoryCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">job Category</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="jobCategory" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'jobCategory')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.jobCategory"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'jobCategory')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of jobCategoryList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">job Category</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="CostCenterCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Cost Center</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="costCenter" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'costCenter')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.costCenter"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'costCenter')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of costCenterList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Cost Center</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="subUnitCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Sub Unit</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="subUnit" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'subUnit')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.subUnit"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'subUnit')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of subUnitList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Sub Unit</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="locationCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Location</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="location" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'location')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.location"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'location')">
                                                                            {{ topping?.location }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of locationList">
                                                                    <mat-option value="{{n.id}}">{{n.location}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Location</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="countryCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Country</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="country" multiple
                                                                style="height: min-content">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of jobSelectionFormGroup?.value?.country"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping , 'country')">
                                                                            {{ topping }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of countryList">
                                                                    <mat-option value="{{n.name}}">{{n.name}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Country</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="workShiftCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Work Shift</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="workShift" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'workShift')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.workShift"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'workShift')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of workShiftList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Work Shift</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="servicePeriodCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Service Periods</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field>
                                                        <mat-label>Min</mat-label>
                                                        <input formControlName="servicePeriodMin" matInput required
                                                            maxlength="40" type="number">
                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>Max</mat-label>
                                                        <input formControlName="servicePeriodMax" maxlength="40"
                                                            matInput required type="number">
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="terminationDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Termination Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker5" class="form-control"
                                                            formControlName="terminationDateStart" matInput>
                                                        <mat-datepicker-toggle [for]="picker5" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker5></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker6" class="form-control"
                                                            formControlName="terminationDateEnd" matInput>
                                                        <mat-datepicker-toggle [for]="picker6" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker6></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="terminationReasonCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Termination Reason</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="terminationReason" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'terminationReason')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.terminationReason"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'terminationReason')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of terminationList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Termination Reason</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="probationDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Probition Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-label for="dob">From</mat-label>
                                                            <input [matDatepicker]="picker7" class="form-control"
                                                                formControlName="probationDateStart" matInput>
                                                            <mat-datepicker-toggle [for]="picker7" matSuffix>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker7></mat-datepicker>
                                                        </mat-form-field>

                                                        <mat-form-field class="form-element">
                                                            <mat-label for="dob">To</mat-label>
                                                            <input [matDatepicker]="picker8" class="form-control"
                                                                formControlName="probationDateEnd" matInput>
                                                            <mat-datepicker-toggle [for]="picker8" matSuffix>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker8></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>

                                    <ng-container *ngIf="regionJobCheckbox.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Region</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="region" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="changeJob($event , 'region')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.region"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="removeJob(topping.id , 'region')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of regionList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Region</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.salarySelectionAdded" class="accordion-head"><i
                                            class="flaticon-salary-1"></i> Salary</span>
                                    <span *ngIf="this.salarySelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-salary-1"></i> Salary</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="salarySelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Salary</h2>
                                        <section class="checkboxes">


                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #payGradeCheck class="form-control" color="primary"
                                                        formControlName="payGradeCheckBox" (change)="onValueChange()">
                                                        Pay Grade
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #currencyCheck class="form-control" color="primary"
                                                        formControlName="currencyCheckBox" (change)="onValueChange()">
                                                        Currency
                                                    </mat-checkbox>
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #annualBasicPaymentCheck class="form-control"
                                                        color="primary" formControlName="annualBasicPaymentCheckBox"
                                                        (change)="onValueChange()">
                                                        Annual Basic Payment
                                                    </mat-checkbox>
                                                </div>
                                            </div>



                                        </section>
                                    </section>
                                    <ng-container *ngIf="payGradeCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Pay Grade</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="payGrade" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'payGrade')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.payGrade"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'payGrade')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of payGradeList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Pay Grade</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="currencyCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Currency</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="currency" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'currency')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.currency"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'currency')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of currencyList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Currency</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="annualBasicPaymentCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Annual Basic Payment</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field>
                                                        <mat-label>Min</mat-label>
                                                        <input formControlName="annualBasicPaymentMin" maxlength="40"
                                                            matInput required type="number">
                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>Max</mat-label>
                                                        <input formControlName="annualBasicPaymentMax" maxlength="40"
                                                            matInput required type="number">
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.educationSelectionAdded" class="accordion-head"><i
                                            class="flaticon-diploma-1"></i> Education</span>
                                    <span *ngIf="this.educationSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-diploma-1"></i> Education</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="educationSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Education</h2>
                                        <section class="checkboxes">

                                            <div class="row" [hidden]="!this.educationFromGroup.levelAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #educationCheck class="form-control" color="primary"
                                                        formControlName="educationCheckBox" (change)="onValueChange()">
                                                        Education
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #yearCheck class="form-control" color="primary"
                                                        formControlName="yearCheckBox" (change)="onValueChange()">
                                                        Year
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.educationFromGroup.startDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #startDateCheck class="form-control" color="primary"
                                                        formControlName="startDateCheckBox" (change)="onValueChange()">
                                                        Start Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.educationFromGroup.endDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #endDateCheck class="form-control" color="primary"
                                                        formControlName="endDateCheckBox" (change)="onValueChange()">
                                                        End Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                    <ng-container *ngIf="educationCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Education</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="education" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'education')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.education"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'education')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of educationList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Education</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="yearCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Year</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field>
                                                        <mat-label>From</mat-label>
                                                        <input formControlName="yearFrom" matInput required
                                                            maxlength="40" type="number">
                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>To</mat-label>
                                                        <input formControlName="yearTo" matInput required maxlength="40"
                                                            type="number">
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="startDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Start Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker12" class="form-control"
                                                            formControlName="startDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker12" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker12></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker13" class="form-control"
                                                            formControlName="startDateTo" matInput>
                                                        <mat-datepicker-toggle [for]="picker13" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker13></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="endDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">End Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker14" class="form-control"
                                                            formControlName="endDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker14" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker14></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker15" class="form-control"
                                                            formControlName="endDateTo" matInput>
                                                        <mat-datepicker-toggle [for]="picker15" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker15></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.immigrationSelectionAdded" class="accordion-head"><i
                                            class="flaticon-destination"></i> Immigration</span>
                                    <span *ngIf="this.immigrationSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-destination"></i> Immigration</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="immigrationSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Immigration</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #documentCheck class="form-control" color="primary"
                                                        formControlName="documentCheckBox" (change)="onValueChange()">
                                                        Document
                                                    </mat-checkbox>

                                                </div>
                                            </div>

                                            <div class="row" [hidden]="!this.immigrationFromGroup.issuedByAvailable">
                                                <div class="col-6">

                                                    <mat-checkbox #issuedByCheck class="form-control" color="primary"
                                                        formControlName="issuedByCheckBox" (change)="onValueChange()">
                                                        Issued By
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                            <div class="row" [hidden]="!this.immigrationFromGroup.expiryDateAvailable">
                                                <div class="col-6">

                                                    <mat-checkbox #expiryDateCheck class="form-control" color="primary"
                                                        formControlName="expiryDateCheckBox" (change)="onValueChange()">
                                                        Expiry Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                            <div class="row" [hidden]="!this.immigrationFromGroup.issuedDateAvailable">
                                                <div class="col-6">

                                                    <mat-checkbox #issuedDateCheck class="form-control" color="primary"
                                                        formControlName="issuedDateCheckBox" (change)="onValueChange()">
                                                        Issued Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                            <div class="row"
                                                [hidden]="!this.immigrationFromGroup.eligibleReviewDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #eligibilityReviewDateCheck class="form-control"
                                                        color="primary" formControlName="eligibilityReviewDateCheckBox"
                                                        (change)="onValueChange()">
                                                        Eligibility Review Date
                                                    </mat-checkbox>

                                                </div>
                                            </div>


                                        </section>
                                    </section>
                                    <ng-container *ngIf="documentCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Document</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="document" multiple
                                                                style="height: min-content">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of immigrationSelectionFormGroup?.value?.document"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping , 'document')">
                                                                            {{ topping }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <mat-option value="Visa">Visa</mat-option>
                                                                <mat-option value="Passport">Passport</mat-option>
                                                            </mat-select>
                                                            <mat-label for="des">Document</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="issuedByCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Issued By</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="issuedBy" multiple
                                                                style="height: min-content;">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of immigrationSelectionFormGroup?.value?.issuedBy"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping , 'issuedBy')">
                                                                            {{ topping }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of countryList">
                                                                    <mat-option value="{{n.name}}">{{n.name}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Issued By</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="issuedDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Issued Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker15" class="form-control"
                                                            formControlName="issuedDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker15" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker15></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker16" class="form-control"
                                                            formControlName="issuedDateto" matInput>
                                                        <mat-datepicker-toggle [for]="picker16" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker16></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="expiryDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Expiry Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker17" class="form-control"
                                                            formControlName="expiryDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker17" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker17></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker18" class="form-control"
                                                            formControlName="expiryDateto" matInput>
                                                        <mat-datepicker-toggle [for]="picker18" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker18></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="eligibilityReviewDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Eligibility Review Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker19" class="form-control"
                                                            formControlName="eligibilityReviewDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker19" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker19></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker20" class="form-control"
                                                            formControlName="eligibilityReviewDateto" matInput>
                                                        <mat-datepicker-toggle [for]="picker20" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker20></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.directDepositeSelectionAdded" class="accordion-head"><i
                                            class="flaticon-salary-1"></i> Bank Info</span>
                                    <span *ngIf="this.directDepositeSelectionAdded"
                                        class="accordion-head inner-checked"><i class="flaticon-salary-1"></i> Bank
                                        Info</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="directDepositeSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Bank Info</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6" [hidden]="!directDepositFromGroup.amountAvailable">

                                                    <mat-checkbox #amountCheck class="form-control" color="primary"
                                                        formControlName="amountCheckBox" (change)="onValueChange()">
                                                        Amount
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                    <ng-container *ngIf="amountCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Amount</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field>
                                                        <mat-label>Min</mat-label>
                                                        <input formControlName="amountMin" matInput maxlength="40"
                                                            required type="number">
                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>Max</mat-label>
                                                        <input formControlName="amountMax" matInput maxlength="40"
                                                            required type="number">
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.licensesSelectionAdded" class="accordion-head"><i
                                            class="flaticon-driver-license-1"></i> License</span>
                                    <span *ngIf="this.licensesSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-driver-license-1"></i> License</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="licensesSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">License</h2>
                                        <section class="checkboxes">
                                            <div class="row" [hidden]="!licenseFromGroup.licenseTypeAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #licenseTypeCheck class="form-control" color="primary"
                                                        formControlName="licenseTypeCheckBox"
                                                        (change)="onValueChange()">
                                                        License Type
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!licenseFromGroup.issuedDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #licenseIssuedDateCheck class="form-control"
                                                        color="primary" formControlName="issuedDateCheckBox"
                                                        (change)="onValueChange()">
                                                        Issued Date
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!licenseFromGroup.expiryDateAvailable">
                                                <div class="col-6">

                                                    <mat-checkbox #licenseExpiryDateCheckbox class="form-control"
                                                        color="primary" formControlName="expiryDateCheckBox"
                                                        (change)="onValueChange()">
                                                        Expiry Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                    <ng-container *ngIf="licenseTypeCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">License Type</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="licenseType" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'licenseType')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.licenseType"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'licenseType')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of licenseList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">License Type</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="licenseIssuedDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Issued Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker22" class="form-control"
                                                            formControlName="issuedDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker22" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker22></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker23" class="form-control"
                                                            formControlName="issuedDateTo" matInput>
                                                        <mat-datepicker-toggle [for]="picker23" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker23></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="licenseExpiryDateCheckbox.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Expiry Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker24" class="form-control"
                                                            formControlName="expiryDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker24" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker24></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker25" class="form-control"
                                                            formControlName="expiryDateTo" matInput>
                                                        <mat-datepicker-toggle [for]="picker25" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker25></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.membershipsSelectionAdded" class="accordion-head"><i
                                            class="flaticon-id-card-1"></i> Membership</span>
                                    <span *ngIf="this.membershipsSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-id-card-1"></i> Membership</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="membershipSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Membership</h2>
                                        <section class="checkboxes">

                                            <div class="row" [hidden]="!this.membershipsFromGroup.currencyAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #memberShipCurrencyCheck class="form-control"
                                                        color="primary" formControlName="currencyCheckBox"
                                                        (change)="onValueChange()">
                                                        Currency
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.membershipsFromGroup.memberShipAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #membershipCheck class="form-control" color="primary"
                                                        formControlName="membershipCheckBox" (change)="onValueChange()">
                                                        Membership
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.membershipsFromGroup.subscriptionAmountAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #subscriptionAmountCheck class="form-control"
                                                        color="primary" formControlName="subscriptionAmountCheckBox"
                                                        (change)="onValueChange()">
                                                        Subscription Amount
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.membershipsFromGroup.subscriptionCommenceDateAvailable">
                                                <div class="col-6">

                                                    <mat-checkbox #subscriptionCommenceDateCheck class="form-control"
                                                        color="primary"
                                                        formControlName="subscriptionCommenceDateCheckBox"
                                                        (change)="onValueChange()">
                                                        Subscription Commence Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.membershipsFromGroup.subscriptionPaidByAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #subscriptionPaidByCheck class="form-control"
                                                        color="primary" formControlName="subscriptionPaidByCheckBox"
                                                        (change)="onValueChange()">
                                                        Subscription Paid By
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.membershipsFromGroup.subscriptionRenewalDateAvailable">
                                                <div class="col-6">

                                                    <mat-checkbox #subscriptionRenewalDateCheck class="form-control"
                                                        color="primary"
                                                        formControlName="subscriptionRenewalDateCheckBox"
                                                        (change)="onValueChange()">
                                                        Subscription Renewal Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                    <ng-container *ngIf="memberShipCurrencyCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Currency</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="currency" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'currencyM')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.currencyM"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'currencyM')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of currencyList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Currency</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="membershipCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Membership</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="membership" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'membership')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.membership"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'membership')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of memberShipList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Membership</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="subscriptionAmountCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Subscription Amount</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field>
                                                        <mat-label>Min</mat-label>
                                                        <input formControlName="subscriptionAmountMin" maxlength="40"
                                                            matInput required type="number">
                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>Max</mat-label>
                                                        <input formControlName="subscriptionAmountMax" maxlength="40"
                                                            matInput required type="number">
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="subscriptionCommenceDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Subscription Commence Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker27" class="form-control"
                                                            formControlName="subscriptionCommenceDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker27" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker27></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker28" class="form-control"
                                                            formControlName="subscriptionCommenceDateTo" matInput>
                                                        <mat-datepicker-toggle [for]="picker28" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker28></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="subscriptionPaidByCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Subscription Paid By</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="subscriptionPaidBy" multiple
                                                                style="height: min-content">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of membershipSelectionFormGroup?.value?.subscriptionPaidBy"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping , 'subscriptionPaidBy')">
                                                                            {{ topping }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container>
                                                                    <mat-option value="company">Company</mat-option>
                                                                    <mat-option value="individual">Individual
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Subscription Paid By</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="subscriptionRenewalDateCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Subscription Renewal Date</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">From</mat-label>
                                                        <input [matDatepicker]="picker29" class="form-control"
                                                            formControlName="subscriptionRenewalDateFrom" matInput>
                                                        <mat-datepicker-toggle [for]="picker29" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker29></mat-datepicker>
                                                    </mat-form-field>

                                                    <mat-form-field class="form-element">
                                                        <mat-label for="dob">To</mat-label>
                                                        <input [matDatepicker]="picker30" class="form-control"
                                                            formControlName="subscriptionRenewalDateTo" matInput>
                                                        <mat-datepicker-toggle [for]="picker30" matSuffix>
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker30></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.workWeekSelectionAdded" class="accordion-head"><i
                                            class="flaticon-calendar"></i> Work Week</span>
                                    <span *ngIf="this.workWeekSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-calendar"></i> Work Week</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="workWeekSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Work Week</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <mat-checkbox #workWeekCheck class="form-control" color="primary"
                                                    formControlName="workWeekCheckBox" (change)="onValueChange()">
                                                    Work Week
                                                </mat-checkbox>
                                            </div>
                                        </section>
                                    </section>
                                    <ng-container *ngIf="workWeekCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Work Week</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="workWeek" multiple
                                                                style="height: min-content">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of workWeekSelectionFormGroup?.value?.workWeek"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping , 'workWeek')">
                                                                            {{ topping }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container>
                                                                    <mat-option value="enabled">Enabled</mat-option>
                                                                    <mat-option value="disabled">Disabled</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Work Week</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.supervisorsSelectionAdded" class="accordion-head"><i
                                            class="flaticon-hierarchy"></i> Supervisor</span>
                                    <span *ngIf="this.supervisorsSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-hierarchy"></i> Supervisor</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="supervisorsSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Supervisor</h2>
                                        <section class="checkboxes">

                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #reportingMethodCheck class="form-control"
                                                        color="primary" formControlName="reportingMethodCheckbox"
                                                        (change)="onValueChange()">
                                                        Reporting Method
                                                    </mat-checkbox>

                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #supervisorNameCheck class="form-control"
                                                        color="primary" formControlName="supervisorNameCheckBox"
                                                        (change)="onValueChange()">
                                                        Supervisor Name
                                                    </mat-checkbox>

                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                    <ng-container *ngIf="reportingMethodCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Reporting Method</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="reportingMethod" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'reportingMethod')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.reportingMethod"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.value , 'reportingMethod')">
                                                                            {{ topping?.label }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of reportingTypes">
                                                                    <mat-option value="{{n.value}}">{{n.label}}
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Reporting Method</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="supervisorNameCheck.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Supervisor Name</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="supervisorName" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'supervisorName')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.supervisorName"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'supervisorName')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of employeeList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Supervisor Name</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.skillsSelectionAdded" class="accordion-head"><i
                                            class="flaticon-skills"></i> Skills</span>
                                    <span *ngIf="this.skillsSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-skills"></i> Skills</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="skillsSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Skills</h2>
                                        <section class="checkboxes">


                                            <div class="row" [hidden]="!this.skillsFromGroup.skillsAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #skillCheckBox class="form-control" color="primary"
                                                        formControlName="skillCheckBox" (change)="onValueChange()">
                                                        Skill
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.skillsFromGroup.yearOfExperienceAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #yearsOfExperienceCheckBox class="form-control"
                                                        color="primary" formControlName="yearsOfExperienceCheckBox"
                                                        (change)="onValueChange()">
                                                        Years of Experience
                                                    </mat-checkbox>
                                                </div>
                                            </div>



                                        </section>
                                    </section>
                                    <ng-container *ngIf="skillCheckBox.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Skill</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select disableOptionCentering class="form-control"
                                                                formControlName="skill" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'skill')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.skill"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'skill')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of skillList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Skill</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="yearsOfExperienceCheckBox.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Years of Experience</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <mat-form-field>
                                                        <mat-label>Min</mat-label>
                                                        <input formControlName="yearsOfExperienceMin" maxlength="40"
                                                            matInput required type="number">
                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>Max</mat-label>
                                                        <input formControlName="yearsOfExperienceMax" matInput
                                                            maxlength="40" required type="number">
                                                    </mat-form-field>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.laguageSelectionAdded" class="accordion-head"><i
                                            class="flaticon-language"></i> Language</span>
                                    <span *ngIf="this.laguageSelectionAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-language"></i> Language</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="languagesSelectionFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Language</h2>
                                        <section class="checkboxes">

                                            <div class="row" [hidden]="!this.languagesFromGroup.languagesAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #languageCheckBox (change)="onValueChange()"
                                                        class="form-control" color="primary"
                                                        formControlName="languageCheckBox">
                                                        Language
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.languagesFromGroup.fluencyLevelAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #fluencyLevelCheckBox (change)="onValueChange()"
                                                        class="form-control" color="primary"
                                                        formControlName="fluencyLevelChcekBox">
                                                        Fluency Level
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.languagesFromGroup.skillAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox #skillLanguageCheckBox (change)="onValueChange()"
                                                        class="form-control" color="primary"
                                                        formControlName="skillCheckBox">
                                                        Competency (Skill)
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                    <ng-container *ngIf="languageCheckBox.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Language</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select class="form-control" disableOptionCentering
                                                                formControlName="language" multiple
                                                                style="height: min-content"
                                                                (selectionChange)="change($event , 'language')">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of chipList?.language"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping.id , 'language')">
                                                                            {{ topping?.name }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let n of languageList">
                                                                    <mat-option value="{{n.id}}">{{n.name}}</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Language</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="fluencyLevelCheckBox.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Fluency Level</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select class="form-control" disableOptionCentering
                                                                formControlName="fluencyLevel" multiple
                                                                style="height: min-content">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of languagesSelectionFormGroup?.value?.fluencyLevel"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping , 'fluencyLevel')">
                                                                            {{ topping === "b" ? 'Basic' : topping ===
                                                                            'p' ? 'Poor' : 'Good' }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container>
                                                                    <mat-option value="b">Basic</mat-option>
                                                                    <mat-option value="p">Poor</mat-option>
                                                                    <mat-option value="g">Good</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Fluency Level</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                    <ng-container *ngIf="skillLanguageCheckBox.checked">
                                        <section class="example-section">
                                            <h2 class="example-h3">Skill</h2>
                                            <section class="checkboxes">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <mat-form-field class="form-element">
                                                            <mat-select class="form-control" disableOptionCentering
                                                                formControlName="skill" multiple
                                                                style="height: min-content">
                                                                <mat-select-trigger>
                                                                    <mat-chip-list>
                                                                        <mat-chip
                                                                            *ngFor="let topping of languagesSelectionFormGroup?.value?.skill"
                                                                            [selectable]="true" [removable]="true"
                                                                            (removed)="remove(topping , 'skillL')">
                                                                            {{ topping === "r" ? 'Reading' : topping ===
                                                                            'w' ? 'Writing' : 'Speaking' }}
                                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                                        </mat-chip>
                                                                    </mat-chip-list>
                                                                </mat-select-trigger>
                                                                <ng-container>
                                                                    <mat-option value="r">Reading</mat-option>
                                                                    <mat-option value="w">Writing</mat-option>
                                                                    <mat-option value="s">Speaking</mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                            <mat-label for="des">Skill</mat-label>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </section>
                                        </section>
                                    </ng-container>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <div [hidden]="this.customSelectionList.length === 0"
                            *ngFor="let custom of customSelectionList; let k = index">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>

                                        <div *ngIf="custom.marked">
                                            <span class="accordion-head inner-checked"><i class="flaticon-file"></i>
                                                {{custom.fullName}}</span>
                                        </div>

                                        <div *ngIf="!custom.marked">
                                            <span class="accordion-head"><i class="flaticon-file"></i>
                                                {{custom.fullName}}</span>
                                        </div>





                                    </mat-panel-title>
                                    <md-panel-description>
                                        <!-- <div  (click)="$event.stopPropagation(); addToDrop(custom.sectionId)">
                                        <span matTooltip="Remove criteria" class="mytooltip tooltip-effect-4" >
                                            <span class="tooltip-item accordion-head"><i
                                                class="flaticon-trash"></i></span>
                                            <span class="tooltip-content clearfix">
                                            <span class="tooltip-text">Remove </span>
                                            </span>
                                        </span>
                                        &nbsp;
                                    </div> -->

                                        <button mat-raised-button class="hide btn-expand">Expand</button>
                                        <button mat-raised-button class="hide btn-collapse">Collapse</button>
                                    </md-panel-description>
                                </mat-expansion-panel-header>
                                <div class="overflowX">
                                    <form class="form-material full-width-inputs">
                                        <section class="example-section">
                                            <h2 class="example-h3">{{custom.fullName}}</h2>

                                            <section class="checkboxes">
                                                <div class="row" *ngFor="let cu of custom.customFields; let j = index">
                                                    <div class="col-6">
                                                        <mat-checkbox (change)="this.changeStatusInSelection()"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="cu.selected" class="form-control"
                                                            color="primary">
                                                            {{cu.field.fieldName}}
                                                        </mat-checkbox>
                                                    </div>

                                                </div>

                                            </section>


                                        </section>

                                        <div *ngFor="let cu of custom.customFields">
                                            <ng-container *ngIf="cu.selected">
                                                <section class="example-section">
                                                    <h2 class="example-h3">{{cu.field.fieldName}}</h2>
                                                    <section class="checkboxes">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <mat-form-field class="form-element">
                                                                    <mat-select [ngModelOptions]="{standalone: true}"
                                                                        disableOptionCentering class="form-control"
                                                                        multiple style="height: min-content"
                                                                        [(ngModel)]="cu.selectedOptions">
                                                                        <mat-select-trigger>
                                                                            <mat-chip-list>
                                                                                <mat-chip
                                                                                    *ngFor="let topping of cu.selectedOptions"
                                                                                    [selectable]="true"
                                                                                    [removable]="true"
                                                                                    (removed)="removeFromCustomChipList(topping , cu)">
                                                                                    {{ topping }}
                                                                                    <mat-icon matChipRemove>cancel
                                                                                    </mat-icon>
                                                                                </mat-chip>
                                                                            </mat-chip-list>
                                                                        </mat-select-trigger>
                                                                        <ng-container
                                                                            *ngFor="let n of cu.field.options">
                                                                            <mat-option value="{{n}}">{{n}}</mat-option>
                                                                        </ng-container>
                                                                    </mat-select>
                                                                    <mat-label for="des">{{cu.field.fieldName}}
                                                                    </mat-label>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </section>
                                            </ng-container>
                                        </div>

                                        <div class="action-buttons multi-btns col-sm-12 text-right">
                                            <button mat-raised-button class="btn-danger reset-btn"
                                                (click)="addToDrop(custom.sectionId)"><i class="flaticon-trash"></i>
                                                Remove Cretiria
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-card-content>


                </mat-card>
                <br>
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger reset-btn" (click)="onCancelClicked()"><i
                            class="flaticon-cancel-1"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn-danger" color="primary" matStepperPrevious><i
                            class="flaticon-left-arrow"></i> Back
                    </button>
                    <button mat-raised-button class="btn-primary" color="primary" matStepperNext><i
                            class="flaticon-right-arrow"></i> Next
                    </button>
                </div>
            </mat-step>
            <mat-step [stepControl]="personalDisplayFormGroup" label="Display Criteria">
                <mat-card>

                    <div class="row top-tools" *ngIf="this.customDisplayDropDown.length > 0">
                        <div class="col-xs-12 col-sm-12 col-md-6 left-tools">

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 right-tools">
                            <div class="relative-position search-filter-con">

                                <span class="mytooltip cus-left tooltip-effect-4">
                                    <span class="tooltip-item">



                                        <button class="btn-primary" color="primary" mat-raised-button
                                            [matMenuTriggerFor]="menu2">
                                            Add Additional Criterias<mat-icon>arrow_drop_down</mat-icon>
                                        </button>
                                        <!-- #enddocregion mat-menu-trigger-for -->
                                        <mat-menu #menu2="matMenu">
                                            <button (click)="removeFromDisplayDrop(customField.sectionId)"
                                                *ngFor="let customField of this.customDisplayDropDown" mat-menu-item>
                                                {{customField.fullName}}
                                            </button>
                                        </mat-menu>

                                    </span>

                                </span>

                            </div>
                        </div>
                    </div>
                    <br><br>
                    <mat-card-content>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.personalDisplayAdded" class="accordion-head"><i
                                            class="flaticon-profile-1"></i> Personal Information</span>
                                    <span *ngIf="this.personalDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-profile-1"></i> Personal Information</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="personalDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Personal Information</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #PersonalSelectAll
                                                        (change)="SelectALlChanged($event, 'personal')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="includeHeader">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="employeeId">
                                                        Employee Id
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="employeeFirstName">
                                                        Employee First Name
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="employeeMiddleName">
                                                        Employee Middle Name
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!personalDetailFromGroup.dateOfBirthAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="dateOfBirth">
                                                        Date of Birth
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="nationality">
                                                        Nationality
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!personalDetailFromGroup.genderAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="gender">
                                                        Gender
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!personalDetailFromGroup.martialStatusAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="martialStatus">
                                                        Martial Status
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="employeeLastName">
                                                        Employee Last Name
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!personalDetailFromGroup.driverLicenseNumberAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="driverLicenseNumber">
                                                        Driver License Number
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!personalDetailFromGroup.licenseExpiryDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="licenseExpiryDate">
                                                        License Expiry Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="PersonalSelectAll.checked"
                                                        formControlName="employeeName">
                                                        Employee Name
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                            <!--                      <div class="row">-->
                                            <!--                        <mat-checkbox class="form-control" color="primary"-->
                                            <!--                                      formControlName="mainId">-->
                                            <!--                          Main Id-->
                                            <!--                        </mat-checkbox>-->
                                            <!--                      </div>-->
                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.jobDisplayAdded" class="accordion-head"><i
                                            class="flaticon-briefcase"></i> Job</span>
                                    <span *ngIf="this.jobDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-briefcase"></i> Job</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="jobDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Job</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #jobSelectAll
                                                        (change)="SelectALlChanged($event, 'job')" class="form-control"
                                                        color="primary" formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="includeHeader">
                                                        Include Header
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="contractStartDate">
                                                        Contract Start Date
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="contractEndDate">
                                                        Contract End Date
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.jobFromGroup.employmentStatusAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="employmentStatus">
                                                        Employment Status
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.jobFromGroup.jobCategoryAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="jobCategory">
                                                        Job Category
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.jobFromGroup.jobTitleAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="jobTitle">
                                                        Job Title
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="region">
                                                        Region
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="subUnit">
                                                        Sub Unit
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="fullSubUnitHierarchy">
                                                        Full Sub Unit Hierarchy
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="location">
                                                        Location
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.jobFromGroup.workShiftAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="workShift">
                                                        Work Shift
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="terminationDate">
                                                        Termination Date
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="terminationReason">
                                                        Termination Reason
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="terminationNote">
                                                        Termination Note
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.jobFromGroup.probationEndDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="probationEndDate">
                                                        Probation End Date
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.jobFromGroup.commentAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="comment">
                                                        Comment
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="effictiveDate">
                                                        Effective Date
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        (change)="onValueChange()" [checked]="jobSelectAll.checked"
                                                        formControlName="creditableService">
                                                        Creditable Service
                                                    </mat-checkbox>
                                                </div>
                                            </div>







                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.salaryDisplayAdded" class="accordion-head"><i
                                            class="flaticon-salary-1"></i> Salary</span>
                                    <span *ngIf="this.salaryDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-salary-1"></i> Salary</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="salaryDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Salary</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #salarySelectAll
                                                        (change)="SelectALlChanged($event, 'salary')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="salarySelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="salarySelectAll.checked"
                                                        formControlName="annualBasicPayment" (change)="onValueChange()">
                                                        Annual Basic Payment
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="salarySelectAll.checked"
                                                        formControlName="effictiveDate" (change)="onValueChange()">
                                                        Effective Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="salarySelectAll.checked" formControlName="payGrade"
                                                        (change)="onValueChange()">
                                                        Pay Grade
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="salarySelectAll.checked" formControlName="currency"
                                                        (change)="onValueChange()">
                                                        Currency
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.educationDisplayAdded" class="accordion-head"><i
                                            class="flaticon-diploma-1"></i> Education</span>
                                    <span *ngIf="this.educationDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-diploma-1"></i> Education</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="educationDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Education</h2>
                                        <section class="checkboxes">

                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #educationSelectAll
                                                        (change)="SelectALlChanged($event, 'education')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="educationSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.educationFromGroup.levelAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="educationSelectAll.checked" formControlName="level"
                                                        (change)="onValueChange()">
                                                        Level
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="educationSelectAll.checked" formControlName="year"
                                                        (change)="onValueChange()">
                                                        Year
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="educationSelectAll.checked"
                                                        formControlName="institute" (change)="onValueChange()">
                                                        Institute
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="educationSelectAll.checked"
                                                        formControlName="major_specialization"
                                                        (change)="onValueChange()">
                                                        Major Specialization
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.educationFromGroup.startDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="educationSelectAll.checked"
                                                        formControlName="startDate" (change)="onValueChange()">
                                                        Start Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.educationFromGroup.endDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="educationSelectAll.checked" formControlName="endDate"
                                                        (change)="onValueChange()">
                                                        End Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="educationSelectAll.checked" formControlName="score"
                                                        (change)="onValueChange()">
                                                        Score
                                                    </mat-checkbox>
                                                </div>
                                            </div>


                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.immigrationDisplayAdded" class="accordion-head"><i
                                            class="flaticon-destination"></i> Immigration</span>
                                    <span *ngIf="this.immigrationDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-destination"></i> Immigration</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="immigrationDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Immigration</h2>
                                        <section class="checkboxes">

                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #immigrationSelectAll
                                                        (change)="SelectALlChanged($event, 'immigration')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="documentType" (change)="onValueChange()">
                                                        Document Type
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.immigrationFromGroup.eligibleReviewDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="eligibilityReviewDate"
                                                        (change)="onValueChange()">
                                                        Eligibility Review Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="number" (change)="onValueChange()">
                                                        Number
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.immigrationFromGroup.eligibleStatusAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="eligibilityStatus" (change)="onValueChange()">
                                                        Eligibility Status
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.immigrationFromGroup.expiryDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="expiryDate" (change)="onValueChange()">
                                                        Expiry Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.immigrationFromGroup.issuedByAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="issuedBy" (change)="onValueChange()">
                                                        Issued By
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.immigrationFromGroup.issuedDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="issuedDate" (change)="onValueChange()">
                                                        Issued Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.immigrationFromGroup.commentAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="immigrationSelectAll.checked"
                                                        formControlName="comments" (change)="onValueChange()">
                                                        Comments
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.directDepositeAdded" class="accordion-head"><i
                                            class="flaticon-salary-1"></i> Bank Info</span>
                                    <span *ngIf="this.directDepositeAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-salary-1"></i> Bank Info</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="directDepositeDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Bank Info</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #directDepositSelectAll
                                                        (change)="SelectALlChanged($event, 'directDeposit')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">

                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="directDepositSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">


                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="directDepositSelectAll.checked"
                                                        formControlName="accountNumber" (change)="onValueChange()">
                                                        Account Number
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">

                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="directDepositSelectAll.checked"
                                                        formControlName="accountType" (change)="onValueChange()">
                                                        Account Type
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">

                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="directDepositSelectAll.checked"
                                                        formControlName="directDepositAmount"
                                                        (change)="onValueChange()">
                                                        Amount
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">

                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="directDepositSelectAll.checked"
                                                        formControlName="nameOfFinancialInstitute"
                                                        (change)="onValueChange()">
                                                        Name of Financial Institute
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="directDepositSelectAll.checked"
                                                        formControlName="routingNumber" (change)="onValueChange()"
                                                        (change)="onValueChange()">
                                                        Sort Code
                                                    </mat-checkbox>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="directDepositSelectAll.checked"
                                                        formControlName="branchLocation" (change)="onValueChange()">
                                                        Branch Location
                                                    </mat-checkbox>

                                                </div>
                                            </div>



                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.licensesDisplayAdded" class="accordion-head"><i
                                            class="flaticon-driver-license-1"></i> License</span>
                                    <span *ngIf="this.licensesDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-driver-license-1"></i> License</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="licensesDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">License</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #licenseSelectAll
                                                        (change)="SelectALlChanged($event, 'license')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="licenseSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.licenseFromGroup.expiryDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="licenseSelectAll.checked"
                                                        formControlName="expiryDate" (change)="onValueChange()">
                                                        Expiry Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.licenseFromGroup.licenseTypeAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="licenseSelectAll.checked"
                                                        formControlName="licenseType" (change)="onValueChange()">
                                                        License Type
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.licenseFromGroup.licenseNumberAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="licenseSelectAll.checked"
                                                        formControlName="licenseNumber" (change)="onValueChange()">
                                                        License Number
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.licenseFromGroup.issuedDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="licenseSelectAll.checked"
                                                        formControlName="issuedDate" (change)="onValueChange()">
                                                        Issued Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.membershipsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-profile-1"></i> Membership</span>
                                    <span *ngIf="this.membershipsDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-profile-1"></i> Membership</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="membershipDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Membership</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #membershipSelectAll
                                                        (change)="SelectALlChanged($event, 'membership')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="membershipSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6"
                                                    [hidden]="!this.membershipsFromGroup.currencyAvailable">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="membershipSelectAll.checked"
                                                        formControlName="currency" (change)="onValueChange()">
                                                        Currency
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.membershipsFromGroup.memberShipAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="membershipSelectAll.checked"
                                                        formControlName="membership" (change)="onValueChange()">
                                                        Membership
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.membershipsFromGroup.subscriptionCommenceDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="membershipSelectAll.checked"
                                                        formControlName="subscriptionCommenceDate"
                                                        (change)="onValueChange()">
                                                        Subscription Commence Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.membershipsFromGroup.subscriptionPaidByAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="membershipSelectAll.checked"
                                                        formControlName="subscriptionPaidBy" (change)="onValueChange()">
                                                        Subscription Paid By
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.membershipsFromGroup.subscriptionRenewalDateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="membershipSelectAll.checked"
                                                        formControlName="subscriptionRenewalDate"
                                                        (change)="onValueChange()">
                                                        Subscription Renewal Date
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.membershipsFromGroup.subscriptionAmountAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="membershipSelectAll.checked"
                                                        formControlName="subscriptionAmount" (change)="onValueChange()">
                                                        Subscription Amount
                                                    </mat-checkbox>
                                                </div>
                                            </div>


                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.workWeekAdded" class="accordion-head"><i
                                            class="flaticon-calendar"></i> Work Week</span>
                                    <span *ngIf="this.workWeekAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-calendar"></i> Work Week</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="workWeekDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Work Week</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #workWeekSelectAll
                                                        (change)="SelectALlChanged($event, 'workWeek')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workWeekSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workWeekSelectAll.checked" formControlName="day"
                                                        (change)="onValueChange()">
                                                        Day
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workWeekSelectAll.checked" formControlName="dayType"
                                                        (change)="onValueChange()">
                                                        Day Type
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workWeekSelectAll.checked" formControlName="lunchIn"
                                                        (change)="onValueChange()">
                                                        Lunch In
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workWeekSelectAll.checked" formControlName="lunchOut"
                                                        (change)="onValueChange()">
                                                        Lunch Out
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workWeekSelectAll.checked" formControlName="shiftIn"
                                                        (change)="onValueChange()">
                                                        Shift In
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workWeekSelectAll.checked" formControlName="duration"
                                                        (change)="onValueChange()">
                                                        Duration
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workWeekSelectAll.checked" formControlName="shiftOut"
                                                        (change)="onValueChange()">
                                                        Shift Out
                                                    </mat-checkbox>

                                                </div>

                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.supervisorsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-hierarchy"></i> Supervisor</span>
                                    <span *ngIf="this.supervisorsDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-hierarchy"></i> Supervisor</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="supervisorsDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Supervisor</h2>
                                        <section class="checkboxes">
                                            <div class="row">

                                                <div class="col-6">

                                                    <mat-checkbox #supervisorSelectAll
                                                        (change)="SelectALlChanged($event, 'supervisor')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>

                                            </div>
                                            <div class="row">

                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="supervisorSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>

                                                </div>

                                            </div>
                                            <div class="row">

                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="supervisorSelectAll.checked"
                                                        formControlName="firstName" (change)="onValueChange()">
                                                        First Name
                                                    </mat-checkbox>

                                                </div>

                                            </div>
                                            <div class="row">

                                                <div class="col-6">

                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="supervisorSelectAll.checked"
                                                        formControlName="supervisorsEmployeeId"
                                                        (change)="onValueChange()">
                                                        Supervisors Employee Id
                                                    </mat-checkbox>
                                                </div>

                                            </div>

                                            <div class="row">

                                                <div class="col-6">

                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="supervisorSelectAll.checked"
                                                        formControlName="reportingMethod" (change)="onValueChange()">
                                                        Reporting Method
                                                    </mat-checkbox>
                                                </div>

                                            </div>

                                            <div class="row">

                                                <div class="col-6">

                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="supervisorSelectAll.checked"
                                                        formControlName="lastName" (change)="onValueChange()">
                                                        Last Name
                                                    </mat-checkbox>
                                                </div>

                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.laguageDisplayAdded" class="accordion-head"><i
                                            class="flaticon-language"></i> Language</span>
                                    <span *ngIf="this.laguageDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-language"></i> Language</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="languageDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Language</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #languageSelectAll
                                                        (change)="SelectALlChanged($event, 'language')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="languageSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.languagesFromGroup.commentsAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="languageSelectAll.checked" formControlName="comments"
                                                        (change)="onValueChange()">
                                                        Comments
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.languagesFromGroup.languagesAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="languageSelectAll.checked" formControlName="language"
                                                        (change)="onValueChange()">
                                                        Language
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.languagesFromGroup.fluencyLevelAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="languageSelectAll.checked" formControlName="fluency"
                                                        (change)="onValueChange()">
                                                        Fluency
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.languagesFromGroup.skillAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="languageSelectAll.checked"
                                                        formControlName="competency" (change)="onValueChange()">
                                                        Competency (Skill)
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.skillsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-skills"></i> Skills</span>
                                    <span *ngIf="this.skillsDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-skills"></i> Skills</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="skillsDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Skills</h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #skillsSelectAll
                                                        (change)="SelectALlChanged($event, 'skills')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="skillsSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.skillsFromGroup.commentsAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="skillsSelectAll.checked" formControlName="comments"
                                                        (change)="onValueChange()">
                                                        Comments
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.skillsFromGroup.yearOfExperienceAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="skillsSelectAll.checked"
                                                        formControlName="yearsOfExperience" (change)="onValueChange()">
                                                        Years of Experience
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.skillsFromGroup.skillsAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="skillsSelectAll.checked" formControlName="skill"
                                                        (change)="onValueChange()">
                                                        Skill
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.workExperienceDisplayAdded" class="accordion-head"><i
                                            class="flaticon-portfolio"></i> Work Experience</span>
                                    <span *ngIf="this.workExperienceDisplayAdded"
                                        class="accordion-head inner-checked"><i class="flaticon-portfolio"></i> Work
                                        Experience</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="workExperienceDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Work Experience</h2>
                                        <section class="checkboxes">

                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #workExperienceSelectAll
                                                        (change)="SelectALlChanged($event, 'workExperience')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workExperienceSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.workExperienceFromGroup.comment">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workExperienceSelectAll.checked"
                                                        formControlName="comment" (change)="onValueChange()">
                                                        Comment
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workExperienceSelectAll.checked"
                                                        formControlName="company" (change)="onValueChange()">
                                                        Company
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workExperienceSelectAll.checked"
                                                        formControlName="duration" (change)="onValueChange()">
                                                        duration
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workExperienceSelectAll.checked"
                                                        formControlName="from" (change)="onValueChange()">
                                                        From
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workExperienceSelectAll.checked" formControlName="to"
                                                        (change)="onValueChange()">
                                                        To
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.workExperienceFromGroup.creditableAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workExperienceSelectAll.checked"
                                                        formControlName="creditable" (change)="onValueChange()">
                                                        Creditable
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="workExperienceSelectAll.checked"
                                                        formControlName="jobTitle" (change)="onValueChange()">
                                                        Job Title
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.subordinatesDisplayAdded" class="accordion-head"><i
                                            class="flaticon-leader"></i> Subordinate</span>
                                    <span *ngIf="this.subordinatesDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-leader"></i> Subordinate</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="subordinatesDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Subordinate
                                        </h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #subordinateSelectAll
                                                        (change)="SelectALlChanged($event, 'subordinates')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="subordinateSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="subordinateSelectAll.checked"
                                                        formControlName="firstName" (change)="onValueChange()">
                                                        First Name
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="subordinateSelectAll.checked"
                                                        formControlName="lastName" (change)="onValueChange()">
                                                        Last Name
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="subordinateSelectAll.checked"
                                                        formControlName="subordinatesEmployeeId"
                                                        (change)="onValueChange()">
                                                        Sub Ordinates Employee Id
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="subordinateSelectAll.checked"
                                                        formControlName="reportingMethod" (change)="onValueChange()">
                                                        Reporting Method
                                                    </mat-checkbox>
                                                </div>
                                            </div>


                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.fullSubUnitHierarchyLevelsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-team-management"></i> Hierarchy Levels</span>
                                    <span *ngIf="this.fullSubUnitHierarchyLevelsDisplayAdded"
                                        class="accordion-head inner-checked"><i class="flaticon-team-management"></i>
                                        Hierarchy Levels</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="fullSubUnitHierarchyLevelsDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Full SubUnit Hierarchy Levels
                                        </h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #subUnitFullSelectAll
                                                        (change)="SelectALlChanged($event, 'subUnitFull')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="subUnitFullSelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="subUnitFullSelectAll.checked"
                                                        formControlName="fullSubUnitHierarchyLevels"
                                                        (change)="onValueChange()">
                                                        Full SubUnit Hierarchy Levels
                                                    </mat-checkbox>
                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.emergencyContactsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-contact"></i> Emergencey Contacts</span>
                                    <span *ngIf="this.emergencyContactsDisplayAdded"
                                        class="accordion-head inner-checked"><i class="flaticon-contact"></i> Emergencey
                                        Contacts</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="emergencyContactsDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Emergencey Contacts
                                        </h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <mat-checkbox #emergencyContactSelectAll
                                                    (change)="SelectALlChanged($event, 'emergencyContact')"
                                                    class="form-control" color="primary" formControlName="selectAll">
                                                    Select All
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="emergencyContactSelectAll.checked"
                                                    formControlName="includeHeader" (change)="onValueChange()">
                                                    Include Header
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="emergencyContactSelectAll.checked"
                                                    formControlName="homeTelephone" (change)="onValueChange()">
                                                    Home Telephone
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="emergencyContactSelectAll.checked"
                                                    formControlName="mobile" (change)="onValueChange()">
                                                    Mobile
                                                </mat-checkbox>
                                            </div>
                                            <div class="row">
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="emergencyContactSelectAll.checked"
                                                    formControlName="relationship" (change)="onValueChange()">
                                                    Relationship
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="emergencyContactSelectAll.checked"
                                                    formControlName="workTelephone" (change)="onValueChange()">
                                                    Work Telephone
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="emergencyContactSelectAll.checked" formControlName="name"
                                                    (change)="onValueChange()">
                                                    Name
                                                </mat-checkbox>
                                            </div>
                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.contactDetailsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-communicate"></i> Contacts Details</span>
                                    <span *ngIf="this.contactDetailsDisplayAdded"
                                        class="accordion-head inner-checked"><i class="flaticon-communicate"></i>
                                        Contacts Details</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="contactDetailsDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Contact Details
                                        </h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox #contactDisplaySelectAll
                                                        (change)="SelectALlChanged($event, 'contactDetails')"
                                                        class="form-control" color="primary"
                                                        formControlName="selectAll">
                                                        Select All
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="includeHeader" (change)="onValueChange()">
                                                        Include Header
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <!-- <div class="row" [hidden]="!this.contactDetailsFromGroup.">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                    [checked]="contactDisplaySelectAll.checked"
                                                    formControlName="address" (change)="onValueChange()">
                                          Address
                                      </mat-checkbox>
                                                </div>
                                            </div> -->
                                            <div class="row" [hidden]="!this.contactDetailsFromGroup.cityAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="city" (change)="onValueChange()">
                                                        City
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.contactDetailsFromGroup.homeTelephoneAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="homeTelephone" (change)="onValueChange()">
                                                        Home Telephone
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.contactDetailsFromGroup.mobileAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="mobile" (change)="onValueChange()">
                                                        Mobile
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.contactDetailsFromGroup.otherEmailAvailabl">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="otherEmail" (change)="onValueChange()">
                                                        Other Email
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.contactDetailsFromGroup.countryAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="country" (change)="onValueChange()">
                                                        Country
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!this.contactDetailsFromGroup.stateAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="state_province" (change)="onValueChange()">
                                                        StateProvince
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.contactDetailsFromGroup.addressStreet1Available">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="street1" (change)="onValueChange()">
                                                        Street1
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.contactDetailsFromGroup.addressStreet2Available">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="street2" (change)="onValueChange()">
                                                        Street2
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.contactDetailsFromGroup.workEmailAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="workEmail" (change)="onValueChange()">
                                                        Work Email
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.contactDetailsFromGroup.workTelephoneAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="workTelephone" (change)="onValueChange()">
                                                        Work Telephone
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="row"
                                                [hidden]="!this.contactDetailsFromGroup.postalCodeAvailable">
                                                <div class="col-6">
                                                    <mat-checkbox class="form-control" color="primary"
                                                        [checked]="contactDisplaySelectAll.checked"
                                                        formControlName="zip_postalCode" (change)="onValueChange()">
                                                        Zip PostalCode
                                                    </mat-checkbox>
                                                </div>
                                            </div>


                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.dependentsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-family "></i> Dependents</span>
                                    <span *ngIf="this.dependentsDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-family "></i> Dependents</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="dependentsDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Dependents
                                        </h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <mat-checkbox #dependentsSelectAll
                                                    (change)="SelectALlChanged($event, 'dependents')"
                                                    class="form-control" color="primary" formControlName="selectAll">
                                                    Select All
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="dependentsSelectAll.checked"
                                                    formControlName="includeHeader" (change)="onValueChange()">
                                                    Include Header
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="dependentsSelectAll.checked"
                                                    formControlName="dateOfBirth" (change)="onValueChange()">
                                                    Date Of Birth
                                                </mat-checkbox>

                                            </div>
                                            <div class="row">
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="dependentsSelectAll.checked"
                                                    formControlName="relationship" (change)="onValueChange()">
                                                    Relationship
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="dependentsSelectAll.checked" formControlName="name"
                                                    (change)="onValueChange()">
                                                    Name
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="dependentsSelectAll.checked"
                                                    formControlName="nationality" (change)="onValueChange()">
                                                    Nationality
                                                </mat-checkbox>
                                            </div>
                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.awardsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-medal"></i> Awards</span>
                                    <span *ngIf="this.awardsDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-medal"></i> Awards</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="awardsDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Awards
                                        </h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <mat-checkbox #awardsSelectAll
                                                    (change)="SelectALlChanged($event, 'awards')" class="form-control"
                                                    color="primary" formControlName="selectAll">
                                                    Select All
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="awardsSelectAll.checked" formControlName="includeHeader"
                                                    (change)="onValueChange()">
                                                    Include Header
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="awardsSelectAll.checked" formControlName="awards"
                                                    (change)="onValueChange()">
                                                    Awards
                                                </mat-checkbox>

                                            </div>
                                            <div class="row">
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="awardsSelectAll.checked" formControlName="donor"
                                                    (change)="onValueChange()">
                                                    Donor
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="awardsSelectAll.checked" formControlName="sector"
                                                    (change)="onValueChange()">
                                                    Sector
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="awardsSelectAll.checked" formControlName="date"
                                                    (change)="onValueChange()">
                                                    Date
                                                </mat-checkbox>
                                            </div>
                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.publicationsDisplayAdded" class="accordion-head"><i
                                            class="flaticon-public-relation"></i> Publication</span>
                                    <span *ngIf="this.publicationsDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-public-relation"></i> Publication</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs"
                                    [formGroup]="publicationsDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Publication
                                        </h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <mat-checkbox #publicationsSelectAll
                                                    (change)="SelectALlChanged($event, 'publication')"
                                                    class="form-control" color="primary" formControlName="selectAll">
                                                    Select All
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="publicationsSelectAll.checked"
                                                    formControlName="includeHeader" (change)="onValueChange()">
                                                    Include Header
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="publicationsSelectAll.checked"
                                                    formControlName="publication" (change)="onValueChange()">
                                                    Publication
                                                </mat-checkbox>

                                            </div>
                                            <div class="row">
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="publicationsSelectAll.checked" formControlName="journal"
                                                    (change)="onValueChange()">
                                                    Journal
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="publicationsSelectAll.checked" formControlName="sector"
                                                    (change)="onValueChange()">
                                                    Sector
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="publicationsSelectAll.checked" formControlName="date"
                                                    (change)="onValueChange()">
                                                    Date
                                                </mat-checkbox>
                                            </div>
                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span *ngIf="!this.trainingDisplayAdded" class="accordion-head"><i
                                            class="flaticon-presentation-2"></i> Training</span>
                                    <span *ngIf="this.trainingDisplayAdded" class="accordion-head inner-checked"><i
                                            class="flaticon-presentation-2"></i> Training</span>
                                </mat-panel-title>
                                <button mat-raised-button class="hide btn-expand">Expand</button>
                                <button mat-raised-button class="hide btn-collapse">Collapse</button>
                            </mat-expansion-panel-header>
                            <div class="overflowX">
                                <form class="form-material full-width-inputs" [formGroup]="trainingDisplayFormGroup">
                                    <section class="example-section">
                                        <h2 class="example-h3">Training
                                        </h2>
                                        <section class="checkboxes">
                                            <div class="row">
                                                <mat-checkbox #trainingSelectAll
                                                    (change)="SelectALlChanged($event, 'training')" class="form-control"
                                                    color="primary" formControlName="selectAll">
                                                    Select All
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="trainingSelectAll.checked"
                                                    formControlName="includeHeader" (change)="onValueChange()">
                                                    Include Header
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="trainingSelectAll.checked" formControlName="from"
                                                    (change)="onValueChange()">
                                                    From
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="trainingSelectAll.checked" formControlName="training"
                                                    (change)="onValueChange()">
                                                    Training
                                                </mat-checkbox>
                                            </div>
                                            <div class="row">
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="trainingSelectAll.checked" formControlName="provider"
                                                    (change)="onValueChange()">
                                                    Provider
                                                </mat-checkbox>
                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="trainingSelectAll.checked" formControlName="sector"
                                                    (change)="onValueChange()">
                                                    Sector
                                                </mat-checkbox>

                                                <mat-checkbox class="form-control" color="primary"
                                                    [checked]="trainingSelectAll.checked" formControlName="to"
                                                    (change)="onValueChange()">
                                                    to
                                                </mat-checkbox>
                                            </div>
                                        </section>
                                    </section>
                                </form>
                            </div>
                        </mat-expansion-panel>

                        <div [hidden]="this.customDisplayList.length === 0"
                            *ngFor="let custom of customDisplayList; let k = index">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>





                                        <div *ngIf="custom.marked">
                                            <span class="accordion-head inner-checked"><i class="flaticon-file"></i>
                                                {{custom.fullName}}</span>



                                        </div>

                                        <div *ngIf="!custom.marked">

                                            <span class="accordion-head"><i class="flaticon-file"></i>
                                                {{custom.fullName}}</span>
                                        </div>





                                    </mat-panel-title>
                                    <md-panel-description>
                                        <!-- <div  (click)="$event.stopPropagation(); addToDisplayDrop(custom.sectionId)">
                                            <span matTooltip="Remove criteria" class="mytooltip tooltip-effect-4" >
                                                <span class="tooltip-item accordion-head"><i
                                                    class="flaticon-trash"></i></span>
                                                <span class="tooltip-content clearfix">
                                                <span class="tooltip-text">Remove </span>
                                                </span>
                                            </span>
                                            &nbsp;
                                        </div> -->
                                        <button mat-raised-button class="hide btn-expand">Expand</button>
                                        <button mat-raised-button class="hide btn-collapse">Collapse</button>
                                    </md-panel-description>


                                </mat-expansion-panel-header>
                                <div class="overflowX">
                                    <form class="form-material full-width-inputs">
                                        <section class="example-section">
                                            <h2 class="example-h3">{{custom.fullName}}</h2>

                                            <section class="checkboxes">

                                                <div class="row">
                                                    <div class="col-6">
                                                        <mat-checkbox (change)="this.checkSelectAll()"
                                                            [(ngModel)]="custom.selectAll"
                                                            [ngModelOptions]="{standalone: true}" class="form-control"
                                                            color="primary">
                                                            Select All
                                                        </mat-checkbox>
                                                    </div>



                                                </div>

                                                <div class="row">
                                                    <div class="col-6">
                                                        <mat-checkbox (change)="this.changeStatusInDisplay()"
                                                            [(ngModel)]="custom.includeHeader"
                                                            [ngModelOptions]="{standalone: true}" class="form-control"
                                                            color="primary">
                                                            Include Header
                                                        </mat-checkbox>
                                                    </div>



                                                </div>
                                                <div class="row" *ngFor="let cu of custom.customFields; let j = index">
                                                    <div class="col-6">
                                                        <mat-checkbox (change)="this.changeStatusInDisplay()"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="cu.selected" class="form-control"
                                                            color="primary">
                                                            {{cu.field.fieldName}}
                                                        </mat-checkbox>
                                                    </div>

                                                </div>

                                            </section>


                                        </section>
                                        <br>
                                        <div class="action-buttons multi-btns col-sm-12 text-right">
                                            <button mat-raised-button class="btn-danger reset-btn"
                                                (click)="addToDisplayDrop(custom.sectionId)"><i
                                                    class="flaticon-trash"></i> Remove Cretiria
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-card-content>
                </mat-card>
                <br>
                <div class="action-buttons multi-btns col-sm-12 text-right">
                    <button mat-raised-button class="btn-danger reset-btn" (click)="onCancelClicked()"><i
                            class="flaticon-cancel-1"></i>Cancel
                    </button>
                    <button mat-raised-button class="btn-danger" color="primary" matStepperPrevious><i
                            class="flaticon-left-arrow"></i> Previous
                    </button>
                    <button mat-raised-button class="btn-danger" (click)="createForm();stepper.reset()"><i
                            class="flaticon-refresh"></i> Reset
                    </button>
                    <button mat-raised-button class="btn-primary" color="primary" (click)="Save()"><i
                            class="flaticon-diskette"></i> Save
                    </button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card-content>

</mat-card>
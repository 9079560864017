<div id="header-page" class="ng-star-inserted header-page">
    <div class="page-header ng-star-inserted">
        <div class="page-block ng-star-inserted">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="page-header-title">
                        <span class="ng-star-inserted"></span><span class="ng-star-inserted">
                            <h4 class="m-b-10 ng-star-inserted">Clients</h4>
                        </span>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="page-body">
    <div class="row">
        <div class="col-sm-12">
            <app-card>
                <div class="dt-responsive form-material full-width-inputs">
                    <form class="full-width-inputs form-material" action="javascript:" [formGroup]="requestForm">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Security Code </mat-label>
                                    <input matInput [type]="currentHide ? 'password' : 'text'"
                                        autocomplete="new-password" required formControlName="securityCode"
                                        class="form-control" />
                                    <mat-icon matSuffix (click)="currentHide = !currentHide">{{ currentHide ?
                                        "visibility_off" : "visibility" }}</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="form-element">
                                    <mat-label>Client Name </mat-label>
                                    <input matInput class="form-control" formControlName="clientQuery" />
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <div class="form-element">
                                    <mat-checkbox class="form-control" formControlName="active">Active
                                        Only</mat-checkbox>
                                </div>
                            </div>

                            <div class="col-sm-12 text-right action-buttons multi-btns">
                                <button mat-raised-button color="primary" class="btn-primary" (click)="listClients()"
                                    [class.spinner]="isLoading" [disabled]="isLoading">
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </app-card>
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

            <div class="example-container mat-elevation-z8" [hidden]="!hasResult">
                <app-card>
                    <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                        <ng-container matColumnDef="id">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Client Id</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.id }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="orgName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Client name</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.orgName }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.createdDate | date :'dd/MM/yyyy'}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Client Email</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.email }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="createdDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Country Code</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.country }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="totalEmployees">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Total Employees</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.totalEmployees }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="totalActiveEmployees">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Total Active Employees</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.totalActiveEmployees }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="totalUsers">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Total Users</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.totalUsers }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="totalActiveUsers">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Total Active Users</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.totalActiveUsers }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lastActivity">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Last Activity Date</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.lastActivity?.timeStamp | date :'dd/MM/yyyy HH:mm'
                                }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{ row.active? 'Active':'Inactive' }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <span class="mytooltip tooltip-effect-4" style="padding-right: 0.8em;"
                                    [hidden]="!row.active">
                                    <span class="tooltip-item"><i class="single-icon-click fa fa-trash fa-lg"
                                            (click)="deleteClient(row.id)"></i></span>
                                    <span class="tooltip-content clearfix">
                                        <span class="tooltip-text">Delete</span>
                                    </span>
                                </span>
                                <!-- <span class="mytooltip tooltip-effect-4" style="padding-right: 0.8em;">
                                    <span class="tooltip-item"><i class="single-icon-click fa fa-list fa-lg"
                                            (click)="showLastActivities(row.id)"></i></span>
                                    <span class="tooltip-content clearfix">
                                        <span class="tooltip-text">Show last activity logs</span>
                                    </span>
                                </span> -->
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns">
                        </mat-row>
                    </mat-table>
                    <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
                    <div class="overlay" *ngIf="isLoading">
                        <mat-spinner></mat-spinner>
                    </div>
                </app-card>
            </div>
        </div>
    </div>
</div>

<ng-template #viewPayslipModal let-modal>
    <div style=" max-height: 83vh; overflow-y: auto;">
    <div class="mat-modal-header" style="text-align: center">
        <h2>{{ org.name }}</h2>
        <h5>{{ lang.get("payslip") }}</h5>
    </div>

    <div class="mat-modal-body">
        <div #payslipContent>
            <div class="row form-group">
                <div class="col-sm-3">
                    <span class="headers">Employee Id</span>
                </div>
                <div class="col-sm-3">
                    {{ payslip.empId }}
                </div>
                <div class="col-sm-3">
                    <span class="headers">Payroll</span>
                </div>
                <div class="col-sm-3">
                    {{ payslip.payrollName }}
                </div>
            </div>

            <div class="row form-group">
                <div class="col-sm-3">
                    <span class="headers">Employee Name</span>
                </div>
                <div class="col-sm-3">
                    {{ payslip.employeeName }}
                </div>
                <div class="col-sm-3">
                    <span class="headers">Payslip Date</span>
                </div>
                <div class="col-sm-3">
                    {{ payslip.forDate }}
                </div>
            </div>

            <div class="row form-group">
                <div class="col-sm-3">
                    <span class="headers">Department</span>
                </div>
                <div class="col-sm-3">--</div>
                <div class="col-sm-3">
                    <span class="headers">Generated Date</span>
                </div>
                <div class="col-sm-3">
                    {{ payslip.generatedDate | date : "dd/MM/yyyy HH:mm" }}
                </div>
            </div>

            <div class="row form-group">
                <div class="col-sm-12">
                    <table mat-table [dataSource]="payslipLines" class="payslip">
                        <ng-container matColumnDef="earningDesc">
                            <th mat-header-cell *matHeaderCellDef>Description</th>
                            <td mat-cell *matCellDef="let line">{{ line.earningDesc }}</td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: 500">Gross Salary</td>
                        </ng-container>

                        <ng-container matColumnDef="earningAmount">
                            <th mat-header-cell *matHeaderCellDef>Amount</th>
                            <td mat-cell *matCellDef="let line">
                                <span *ngIf="line.earningDesc">{{ line.earningAmount | number : "2.2-2" }}</span>
                            </td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: 500">
                                {{ payslip.grossSalary | number : "2.2-2" }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="deductionDesc">
                            <th mat-header-cell *matHeaderCellDef
                                style="padding-left: 10px; border-left: double 2px lightgray">
                                Description
                            </th>
                            <td mat-cell *matCellDef="let line"
                                style="padding-left: 10px; border-left: double 2px lightgray">
                                {{ line.deductionDesc }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef
                                style="padding-left: 10px; border-left: double 2px lightgray; font-weight: 500">
                                Total Deductions
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="deductionAmount">
                            <th mat-header-cell *matHeaderCellDef>Amount</th>
                            <td mat-cell *matCellDef="let line">
                                <span *ngIf="line.earningDesc">{{ line.deductionAmount | number : "2.2-2" }}</span>
                            </td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: 500">
                                {{ payslip.totalDeductions | number : "2.2-2" }}
                            </td>
                        </ng-container>

                        <!-- header-->
                        <ng-container matColumnDef="earning">
                            <th mat-header-cell *matHeaderCellDef colspan="2">Earning</th>
                        </ng-container>
                        <ng-container matColumnDef="deductions">
                            <th mat-header-cell *matHeaderCellDef colspan="2"
                                style="padding-left: 10px; border-left: double 2px lightgray">
                                Deductions
                            </th>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['earning', 'deductions']"></tr>

                        <!--footer -->
                        <ng-container matColumnDef="netPay">
                            <td mat-footer-cell *matFooterCellDef
                                style="font-weight: bold; background-color: lightgray">Net Pay</td>
                        </ng-container>
                        <ng-container matColumnDef="netPayAmount">
                            <td mat-footer-cell *matFooterCellDef colspan="3"
                                style="font-weight: bold; background-color: lightgray">
                                {{ payslip.netPay | number : "2.2-2" }}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="payslipDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: payslipDisplayedColumns"></tr>

                        <tr mat-footer-row *matFooterRowDef="payslipDisplayedColumns"></tr>
                        <tr mat-footer-row *matFooterRowDef="['netPay', 'netPayAmount']"></tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="row form-group">
            <div class="action-buttons multi-btns col-sm-12 text-right">
                <button mat-raised-button class="btn-danger" (click)="close()">
                    <i class="flaticon-cancel"></i> {{ lang.get("close") }}
                </button>
                <button mat-raised-button class="btn btn-primary" color="primary" (click)="download()"
                    [class.spinner]="isLoading" [disabled]="isLoading">
                    <i class="flaticon-file"></i> {{ lang.get("download") }}
                </button>
            </div>
        </div>
    </div>
    <!-- </div> -->
</div>
</ng-template>
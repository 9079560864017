<div id="header-page" class="ng-star-inserted header-page">
  <div class="page-header ng-star-inserted">
    <div class="page-block ng-star-inserted">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="page-header-title">
            <span class="ng-star-inserted"></span><span class="ng-star-inserted">
              <h4 class="m-b-10 ng-star-inserted">Employee Payslip</h4>
            </span>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card>
        <div class="dt-responsive form-material full-width-inputs">
          <form class="full-width-inputs form-material" action="javascript:">
            <div class="row form-group">
              <div class="col-md-4">
                <app-lazy-employee-dropdown title="Employee" required="false" [selectedEmployee]="selectedEmpId"
                  (employeeSelected)="updateSelectedEmployee($event)"></app-lazy-employee-dropdown>
              </div>
              <div class="col-md-2">
                <mat-form-field class="form-element screen-date-picker">
                  <mat-label>Month</mat-label>
                  <mat-select [ngModelOptions]="{ standalone: true }" disableOptionCentering [(ngModel)]="selectedMonth"
                    class="form-control">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let m of months" [value]="m">
                      {{ m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="form-element screen-date-picker">
                  <mat-label>Year</mat-label>
                  <mat-select [ngModelOptions]="{ standalone: true }" disableOptionCentering [(ngModel)]="selectedYear"
                    class="form-control">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let y of years" [value]="y">
                      {{ y }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-12 text-right action-buttons multi-btns">
                <button mat-raised-button color="primary" class="btn-primary" (click)="getEmployeePayslips()"
                  [class.spinner]="isLoading" [disabled]="isLoading">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </app-card>

      <div class="example-container mat-elevation-z8" *ngIf="hasResult">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <app-card>
          <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="employeeId">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("empId") }}</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.empId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="employeeName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{ lang.get("empName") }}</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.employeeName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.forDate }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="grossSalary">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Total Earnings</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.grossSalary | number : "1.2-2" }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalDeductions">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Total Deductions</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.totalDeductions | number : "2.2-2" }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="netPay">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Net Pay</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.netPay | number : "1.2-2" }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row (click)="viewPayslip(row)" *matRowDef="let row; columns: displayedColumns" class="cursor-pointer">
            </mat-row>
          </mat-table>

          <mat-paginator [pageSize]="pageSize" [pageIndex]="activePage" [pageSizeOptions]="pageSizeOptions"
            showFirstLastButtons [length]="totalRecords" (page)="handlePageEvent($event)" [disabled]="isLoading">
          </mat-paginator>
        </app-card>
      </div>
    </div>
  </div>
</div>

<ng-template #viewPayslipModal let-modal>
  <div style=" max-height: 83vh; overflow-y: auto; overflow-x: hidden;">
  <div class="mat-modal-header" style="text-align: center">
    <h2>{{ org.name }}</h2>
    <h5>{{ lang.get("payslip") }}</h5>
  </div>

  <div class="mat-modal-body">
    <div #payslipContent>
      <div class="row form-group">
        <div class="col-sm-3">
          <span class="headers">Employee Id</span>
        </div>
        <div class="col-sm-3">
          {{ payslip.empId }}
        </div>
        <div class="col-sm-3">
          <span class="headers">Payroll</span>
        </div>
        <div class="col-sm-3">
          {{ payslip.payrollName }}
        </div>
      </div>

      <div class="row form-group">
        <div class="col-sm-3">
          <span class="headers">Employee Name</span>
        </div>
        <div class="col-sm-3">
          {{ payslip.employeeName }}
        </div>
        <div class="col-sm-3">
          <span class="headers">Payslip Date</span>
        </div>
        <div class="col-sm-3">
          {{ payslip.forDate }}
        </div>
      </div>

      <div class="row form-group">
        <div class="col-sm-3">
          <span class="headers">Department</span>
        </div>
        <div class="col-sm-3">--</div>
        <div class="col-sm-3">
          <span class="headers">Generated Date</span>
        </div>
        <div class="col-sm-3">
          {{ payslip.generatedDate | date : "dd/MM/yyyy HH:mm" }}
        </div>
      </div>

      <div class="row form-group">
        <div class="col-sm-12">
          <table mat-table [dataSource]="payslipLines" class="payslip">
            <ng-container matColumnDef="earningDesc">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let line">{{ line.earningDesc }}</td>
              <td mat-footer-cell *matFooterCellDef style="font-weight: 500">Total Earnings</td>
            </ng-container>

            <ng-container matColumnDef="earningAmount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let line">
                <span *ngIf="line.earningDesc">{{ line.earningAmount | number : "2.2-2" }}</span>
              </td>
              <td mat-footer-cell *matFooterCellDef style="font-weight: 500">
                {{ payslip.grossSalary | number : "2.2-2" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="deductionDesc">
              <th mat-header-cell *matHeaderCellDef style="padding-left: 10px; border-left: double 2px lightgray">
                Description
              </th>
              <td mat-cell *matCellDef="let line" style="padding-left: 10px; border-left: double 2px lightgray">
                {{ line.deductionDesc }}
              </td>
              <td mat-footer-cell *matFooterCellDef
                style="padding-left: 10px; border-left: double 2px lightgray; font-weight: 500">
                Total Deductions
              </td>
            </ng-container>

            <ng-container matColumnDef="deductionAmount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let line">
                <span *ngIf="line.earningDesc">{{ line.deductionAmount | number : "2.2-2" }}</span>
              </td>
              <td mat-footer-cell *matFooterCellDef style="font-weight: 500">
                {{ payslip.totalDeductions | number : "2.2-2" }}
              </td>
            </ng-container>

            <!-- header-->
            <ng-container matColumnDef="earning">
              <th mat-header-cell *matHeaderCellDef colspan="2">Earning</th>
            </ng-container>
            <ng-container matColumnDef="deductions">
              <th mat-header-cell *matHeaderCellDef colspan="2"
                style="padding-left: 10px; border-left: double 2px lightgray">
                Deductions
              </th>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['earning', 'deductions']"></tr>

            <!--footer -->
            <ng-container matColumnDef="netPay">
              <td mat-footer-cell *matFooterCellDef style="font-weight: bold; background-color: lightgray">Net Pay</td>
            </ng-container>
            <ng-container matColumnDef="netPayAmount">
              <td mat-footer-cell *matFooterCellDef colspan="3" style="font-weight: bold; background-color: lightgray">
                {{ payslip.netPay | number : "2.2-2" }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="payslipDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: payslipDisplayedColumns"></tr>

            <tr mat-footer-row *matFooterRowDef="payslipDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['netPay', 'netPayAmount']"></tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="action-buttons multi-btns col-sm-12 text-right">
        <button mat-raised-button class="btn-danger" (click)="close()">
          <i class="flaticon-cancel"></i> {{ lang.get("close") }}
        </button>
        <button mat-raised-button class="btn btn-primary" color="primary" (click)="download()"
          [class.spinner]="isLoading" [disabled]="isLoading">
          <i class="flaticon-file"></i> {{ lang.get("download") }}
        </button>
      </div>
    </div>
  </div>
</div>
  <!-- </div> -->
</ng-template>